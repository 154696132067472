import {
  getData,
  KEY_PERMISSION,
  KEY_USER,
  KEY_WORKING_DAY,
} from "./preferences";
import moment from "moment";
import { goBack } from "connected-react-router";
import { ENVIRONMENT } from "../api/constants";

export const PRINT = (title, data) => {
  if (ENVIRONMENT === "development") {
    if (data !== null || data !== undefined) {
      console.log(title + " : " + JSON.stringify(data));
    } else {
      console.log(title);
    }
  }
};

export const PRICE_FORMAT = (price) => {
  return Number(price).toLocaleString();
};

export const getStatusString = (status) => {
  switch (status) {
    case "A":
      return "Active";
    case "I":
      return "Inactive";
    default:
      return "";
  }
};

export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  color += "22";
  return color;
};

/**
 * Check Two Array OR Objects are equal or not
 * @param {*} value
 * @param {*} other
 */
export const isEqual = (value, other) => {
  // Get the value type
  var type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  var valueLen =
    type === "[object Array]" ? value.length : Object.keys(value).length;
  var otherLen =
    type === "[object Array]" ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  var compare = function (item1, item2) {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };

  // Compare properties
  if (type === "[object Array]") {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    var fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (err) => {
      reject(err);
    };
  });
};

export const getAccompainedByIds = (arrobjAccompainedBy) => {
  console.log("ACCBY");
  console.log(arrobjAccompainedBy);
  var arrAccompainedBy = [];
  if (arrobjAccompainedBy != null && arrobjAccompainedBy.length > 0) {
    arrobjAccompainedBy.forEach((objAccompainedBy, index) => {
      arrAccompainedBy.push(objAccompainedBy.user_id);
    });
  }
  return arrAccompainedBy;
};

export const verifyPermission = (strPermission) => {
  var permission = getData(KEY_PERMISSION);
  if (permission != null) {
    var objPermission = [];
    objPermission = JSON.parse(permission);
    return objPermission.includes(strPermission);
  } else {
    return false;
  }
};

export const generateInitialLettersString = (strInput) => {
  var letters = "";
  if (strInput !== null) {
    if (String(strInput).includes(" ")) {
      var arrString = String(strInput).split(" ");
      letters = letters.concat(arrString[0].charAt(0).toUpperCase());
      letters = letters.concat(arrString[1].charAt(0).toUpperCase());
    } else if (String(strInput).length >= 2) {
      letters = letters.concat(strInput.charAt(0).toUpperCase());
      letters = letters.concat(strInput.charAt(1).toUpperCase());
    } else {
      letters = letters.concat(strInput.charAt(0).toUpperCase());
    }
  } else {
    return "NA";
  }

  return letters;
};

export const checkFileExtension = (ext, validExts) => {
  return validExts.includes(ext);
};

export const timeSince = (timeStamp) => {
  if (!(timeStamp instanceof Date)) {
    timeStamp = new Date(timeStamp);
  }

  if (isNaN(timeStamp.getDate())) {
    return "Invalid date";
  }

  var now = new Date(),
    secondsPast = (now.getTime() - timeStamp.getTime()) / 1000;

  var formatDate = function (date, format, utc) {
    var MMMM = [
      "\x00",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var MMM = [
      "\x01",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dddd = [
      "\x02",
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    function ii(i, len) {
      var s = i + "";
      len = len || 2;
      while (s.length < len) s = "0" + s;
      return s;
    }

    var y = utc ? date.getUTCFullYear() : date.getFullYear();
    format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
    format = format.replace(/(^|[^\\])yy/g, "$1" + y.toString().substr(2, 2));
    format = format.replace(/(^|[^\\])y/g, "$1" + y);

    var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
    format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
    format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
    format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M));
    format = format.replace(/(^|[^\\])M/g, "$1" + M);

    var d = utc ? date.getUTCDate() : date.getDate();
    format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
    format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
    format = format.replace(/(^|[^\\])dd/g, "$1" + ii(d));
    format = format.replace(/(^|[^\\])d/g, "$1" + d);

    var H = utc ? date.getUTCHours() : date.getHours();
    format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
    format = format.replace(/(^|[^\\])H/g, "$1" + H);

    var h = H > 12 ? H - 12 : H == 0 ? 12 : H;
    format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
    format = format.replace(/(^|[^\\])h/g, "$1" + h);

    var m = utc ? date.getUTCMinutes() : date.getMinutes();
    format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
    format = format.replace(/(^|[^\\])m/g, "$1" + m);

    var s = utc ? date.getUTCSeconds() : date.getSeconds();
    format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
    format = format.replace(/(^|[^\\])s/g, "$1" + s);

    var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
    format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
    f = Math.round(f / 10);
    format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f));
    f = Math.round(f / 10);
    format = format.replace(/(^|[^\\])f/g, "$1" + f);

    var T = H < 12 ? "AM" : "PM";
    format = format.replace(/(^|[^\\])TT+/g, "$1" + T);
    format = format.replace(/(^|[^\\])T/g, "$1" + T.charAt(0));

    var t = T.toLowerCase();
    format = format.replace(/(^|[^\\])tt+/g, "$1" + t);
    format = format.replace(/(^|[^\\])t/g, "$1" + t.charAt(0));

    var tz = -date.getTimezoneOffset();
    var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
    if (!utc) {
      tz = Math.abs(tz);
      var tzHrs = Math.floor(tz / 60);
      var tzMin = tz % 60;
      K += ii(tzHrs) + ":" + ii(tzMin);
    }
    format = format.replace(/(^|[^\\])K/g, "$1" + K);

    var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
    format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
    format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

    format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
    format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

    format = format.replace(/\\(.)/g, "$1");

    return format;
  };

  if (secondsPast < 0) {
    // Future date
    return timeStamp;
  }
  if (secondsPast < 60) {
    // Less than a minute
    return parseInt(secondsPast) + " secs";
  }
  if (secondsPast < 3600) {
    // Less than an hour
    return parseInt(secondsPast / 60) + " mins";
  }
  if (secondsPast <= 86400) {
    // Less than a day
    return parseInt(secondsPast / 3600) + " hrs";
  }
  if (secondsPast <= 172800) {
    // Less than 2 days
    return "Yesderday at " + formatDate(timeStamp, "h:mmtt");
  }
  if (secondsPast > 172800) {
    // After two days
    var timeString;

    if (secondsPast <= 604800)
      timeString =
        formatDate(timeStamp, "dddd") +
        " at " +
        formatDate(timeStamp, "h:mmtt"); // with in a week
    else if (now.getFullYear() > timeStamp.getFullYear())
      timeString = formatDate(timeStamp, "MMMM d, yyyy"); // a year ago
    else if (now.getMonth() > timeStamp.getMonth())
      timeString = formatDate(timeStamp, "MMMM d"); // months ago
    else
      timeString =
        formatDate(timeStamp, "MMMM d") +
        " at " +
        formatDate(timeStamp, "h:mmtt"); // with in a month

    return timeString;
  }
};

export const getHttpUrl = (url) => {
  var pattern = /^((http|https|ftp):\/\/)/;
  if (!pattern.test(url)) {
    url = "http://" + url;
  }
  return url;
};

export const linkify = (inputText) => {
  var replacedText,
    replacePattern1,
    replacePattern2,
    replacePattern3,
    replacePattern4;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(
    replacePattern1,
    '<a href="$1" target="_blank">$1</a>'
  );

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(
    replacePattern3,
    '<a href="mailto:$1">$1</a>'
  );

  // change user name
  replacePattern4 = /##\d+:(.*?)##/gim;
  replacedText = replacedText.replace(
    replacePattern4,
    '<a href=""><b>@$1</b></a>'
  );

  return replacedText;
};

export const timeStringConversion = (inputTime) => {
  // console.log("Date ======== " + inputTime);
  // var stringDate = dateFormat(new Date(), 'dd/mm/yyyy')
  // stringDate = stringDate.concat(' ' + inputTime);
  // console.log("Date ======== " + stringDate);
  // return dateFormat(stringDate, 'hh:MM TT')
  var timeString = inputTime;
  var H = +timeString.substr(0, 2);
  var h = H % 12 || 12;
  var ampm = H < 12 ? " AM" : " PM";
  timeString = h + timeString.substr(2, 3) + ampm;
  return timeString;
};

export const devXDisableWeekend = (args) => {
  var userWorkingDay = getData(KEY_WORKING_DAY);
  const dayOfWeek = args.date.getDay();
  var isWeekend = null;
  if (userWorkingDay != null) {
    var userObj = JSON.parse(userWorkingDay);
    if (userObj === 5) {
      isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
    } else {
      isWeekend = dayOfWeek === 0;
    }
  } else {
    isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
  }
  return isWeekend;
};

export const getDayDifference = (fromDate, toDate) => {
  var startDate = new moment(fromDate);
  var endDate = new moment(toDate);

  // Get Total Days Difference
  var duration = moment.duration(endDate.diff(startDate)).days();

  var start = new Date(startDate);
  var weekEndCount = 0;

  // Calcuate Weekend Days
  while (start <= endDate) {
    if (start.getDay() === 0 || start.getDay() === 6) {
      weekEndCount++;
    }

    var newDate = start.setDate(start.getDate() + 1);
    start = new Date(newDate);
  }

  if (duration < 0) {
    return 0;
  }
  return duration - weekEndCount + 1;
};

export const getLeaveType = (type) => {
  switch (type) {
    case "sick_leave":
      return "Sick Leave";
    case "standard_leave":
      return "Standard Leave";
    case "maternity_leave":
      return "Maternity Leave";
    case "paternity_leave":
      return "Paternity Leave";
    case "absent":
      return "Absent";
    case "present":
      return "Present";
  }
};

export const getLeaveStatus = (status) => {
  switch (status) {
    case "review_supervisor":
      return "Review Supervisor";
    case "review_hr":
      return "Review HR";
    case "asset_allocated":
      return "Asset Allocated";
    case "rejected_supervisor":
      return "Rejected Supervisor";
    case "return_resource_allocated":
      return "Return Resource Allocated";
    case "rejected_hr":
      return "Rejected HR";
    case "resource_allocated":
      return "Resource Allocated";
    case "request_accepted":
      return "Request Accepted";
    case "travel_allocated":
      return "Travel Allocated";
    case "approved":
      return "Approved";
    case "rejected":
      return "Rejected";
    case "cancel_by_employee":
      return "Cancel By Employee";
    case "in-progress":
      return "In Progress";
    case "completed":
      return "Completed";
    case "re-open":
      return "Re Open";
    case "assign-to-user":
      return "Assign To User";
  }
};

export const getMonthNumber = (month, type) => {
  switch (month) {
    case "January":
      if (type == "string") {
        return "01";
      } else {
        return 1;
      }
    case "February":
      if (type == "string") {
        return "02";
      } else {
        return 2;
      }
    case "March":
      if (type == "string") {
        return "03";
      } else {
        return 3;
      }
    case "April":
      if (type == "string") {
        return "04";
      } else {
        return 4;
      }
    case "May":
      if (type == "string") {
        return "05";
      } else {
        return 5;
      }
    case "June":
      if (type == "string") {
        return "06";
      } else {
        return 6;
      }
    case "July":
      if (type == "string") {
        return "07";
      } else {
        return 7;
      }
    case "August":
      if (type == "string") {
        return "08";
      } else {
        return 8;
      }
    case "September":
      if (type == "string") {
        return "09";
      } else {
        return 9;
      }
    case "October":
      if (type == "string") {
        return "10";
      } else {
        return 10;
      }
    case "November":
      if (type == "string") {
        return "11";
      } else {
        return 11;
      }
    case "December":
      if (type == "string") {
        return "12";
      } else {
        return 12;
      }
  }
};

export const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
};

export const convertAmount = (value) => {
  if (value >= 10000000) {
    return Number(value / 10000000).toFixed(2) + " Crore";
  } else if (value >= 100000) {
    return Number(value / 100000).toFixed(2) + " Lacs";
  } else {
    return Number(value).toFixed(2);
  }
};

export const getDateFilter = (type) => {
  switch (type) {
    case "DAY":
      let day = Object.assign({}, {});
      day.start = moment(new Date()).format("YYYY-MM-DD");
      day.end = moment(new Date()).format("YYYY-MM-DD");
      return day;
    case "WEEK":
      let week = Object.assign({}, {});
      week.start = moment().startOf("week").add(1, "day").format("YYYY-MM-DD");
      week.end = moment().endOf("week").add(1, "day").format("YYYY-MM-DD");
      return week;
    case "MONTH":
      let month = Object.assign({}, {});
      month.start = moment().startOf("month").format("YYYY-MM-DD");
      month.end = moment().endOf("month").format("YYYY-MM-DD");
      return month;
    case "YEAR":
      let year = Object.assign({}, {});
      year.start = moment()
        .month(3)
        .startOf("month")
        .subtract(1, "year")
        .format("YYYY-MM-DD");
      year.end = moment(new Date()).format("YYYY-MM-DD");
      return year;
    case "RESET":
      let reset = Object.assign({}, {});
      reset.start = moment(new Date())
        .subtract(90, "days")
        .format("YYYY-MM-DD");
      reset.end = moment(new Date()).format("YYYY-MM-DD");
      return reset;
    case "CURRENT_FY":
      {
        let currentMonth = moment().month();
        let currentYear = moment().year();


        let fyStartMonth = 3;
        let fyEndMonth = 2;

        let startYear =
          currentMonth < fyStartMonth ? currentYear - 1 : currentYear;
        let endYear = currentMonth > fyEndMonth ? currentYear + 1 : currentYear;

        let startOfMonth = moment()
          .month(fyStartMonth)
          .year(startYear)
          .startOf("month")
          .format("YYYY-MM-DD");
        let endOfMonth = moment()
          .month(fyEndMonth)
          .year(endYear)
          .endOf("month")
          .format("YYYY-MM-DD");

        let current_fy = { start: startOfMonth, end: endOfMonth };
        return current_fy;
      }
    case "PREVIOUS_FY": {
      let currentMonth = moment().month();
      let currentYear = moment().year();


      let fyStartMonth = 3;
      let fyEndMonth = 2;

      let startYear =
        currentMonth < fyStartMonth ? currentYear - 2 : currentYear - 1;
      let endYear = currentMonth < fyStartMonth ? currentYear - 1 : currentYear;

      let startOfMonth = moment()
        .month(fyStartMonth)
        .year(startYear)
        .startOf("month")
        .format("YYYY-MM-DD");
      let endOfMonth = moment()
        .month(fyEndMonth)
        .year(endYear)
        .endOf("month")
        .format("YYYY-MM-DD");

      let previous_fy = { start: startOfMonth, end: endOfMonth };
      return previous_fy;
    }


    case "RESET_OPPORTUNITY":
      let reset_opportunity = Object.assign({}, {});
      reset_opportunity.start =
        moment().month() < 3
          ? moment()
            .month(3)
            .startOf("month")
            .subtract(1, "years")
            .format("YYYY-MM-DD")
          : moment().month(3).startOf("month").format("YYYY-MM-DD");
      reset_opportunity.end = moment(new Date()).format("YYYY-MM-DD");
      return reset_opportunity;
  }
};

export const downloadFile = (fileUrl) => {
  const downloadLink = document.getElementById("downloadLink");
  downloadLink.href = fileUrl;
  downloadLink.download = "Document";
  downloadLink.click();
};

export const CalculateFinancialYear = () => {
  let lastYear = moment().subtract(1, "year").format("yyyy");
  let currentYear = moment().year();
  if (moment().month() >= 3) {
    lastYear = moment().year();
    currentYear = moment().add(1, "year").format("yyyy");
  }
  const financialYear = `${lastYear}-${currentYear.slice(-2)}`;
  return financialYear;
};

export const CalculateProposalMonth = (expiryDate) => {
  if (!expiryDate) return '';

  const parsedExpiryDate = moment(expiryDate, 'YYYY/MM/DD');

  if (!parsedExpiryDate.isValid()) {
    console.error("Invalid Expiry Date:", expiryDate);
    return '';
  }

  const month = parsedExpiryDate.format('MMM');
  const year = parsedExpiryDate.format('YY');

  const proposalMonth = `${month} ${year}`;

  return proposalMonth;
};
