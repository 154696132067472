import React, { useEffect, useState, useCallback } from 'react';
import { toast } from "react-toastify";
import { CLAIM_FILE_ERROR_MESSAGE, ERROR, PRIMARY_COLOR, UNAUTHORIZED } from "../../../../../../constants";
import { DateBox } from 'devextreme-react/date-box';
import { Controller, useForm } from "react-hook-form";
import dateFormat from "dateformat";
import ScreenTitle from '../../../../../../component/screen_title';
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { FiUser } from 'react-icons/fi';
import $ from 'jquery';
import { clearData, getData, KEY_TOAST_MESSAGE, KEY_USER, setData } from "../../../../../../utils/preferences";
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { actionViewWellnessApproval, ACTION_VIEW_WELLNESS_APPROVAL_SUCCESS, ACTION_EDIT_WELLNESS_APPROVAL_TYPE_SUCCESS, ACTION_EDIT_WELLNESS_APPROVAL_ASSIGN_RM_SUCCESS, actionWellnessManagementWellnessType, actionWellnessManagementAssignRm } from "./action";
import { actionEditWellnessApprovalClientType, ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_TYPE_SUCCESS } from "./action";
import { actionEditWellnessApprovalClientName, ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_NAME_SUCCESS } from "./action";
import { actionEditWellnessApproval, ACTION_WELLNESS_APPROVAL_EDIT_SUCCESS } from "./action";
import Swal from 'sweetalert2';
import { checkFileExtension, downloadFile } from '../../../../../../utils';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone'
import DocumentIframe from '../../../../../../component/popups/document_iframe';
import { ACTION_WELLNESS_DOCUMENT_DELETE_SUCCESS, actionWellnessDocumentDelete } from '../../wellness_pool/edit/action';


// Assume these are defined elsewhere in your application
const CLAIM_FILE_SIZE = 5 * 1024 * 1024 // 5MB example
const validExt = ['.doc', '.docx', '.xlsx', '.xls', '.pdf', '.zip', '.txt', '.jpg', '.jpeg', '.png']

const WellnessApprovalEdit = (props) => {

    const { register, handleSubmit, watch, control, formState: { errors }, setValue } = useForm({
        defaultValues: {

        }
    });
    const [clientType, setClientType] = useState([]);
    const [clientName, setClientName] = useState([]);
    const [wellnessApproval, setWellnessApproval] = useState(null);
    const [wellnessStatus, setWellnessStatus] = useState(null);
    const [wellnessRMStatus, setWellnessRMStatus] = useState("close");
    const [showExecutedBy, setShowExecutedBy] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [selectedExecutedBy, setSelectedExecutedBy] = useState("");
    const [assignRm, setAssignRm] = useState([]);
    const [wellnessType, setWellnessType] = useState([]);
    const [uploadDocument, setUploadDocument] = useState(null);
    const [files, setFiles] = useState([]);
    const [maxFileCount, setMaxFileCount] = useState(5)
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [selectType, setSelectType] = useState(null)
    const [extension, setExtension] = useState(null)
    const [wellnessDocument, setWellnessDocument] = useState(null);

    const watchRMStatus = watch('rm_status')

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        var userDetail = getData(KEY_USER);
        if (userDetail != null) {
            var userObj = JSON.parse(userDetail)
            setDepartments(userObj.departments)
        }
        props.actionViewWellnessApproval({ id: props.location.state.id });
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (watchRMStatus !== undefined && watchRMStatus !== null) {
            setWellnessStatus(watchRMStatus)
        }
    }, [watchRMStatus])

    useEffect(() => {
        if (wellnessApproval != null) {
            setValue('created_at', wellnessApproval?.created_at != null ? moment(wellnessApproval?.created_at).format("DD/MM/YYYY") : '');
            setValue('address', wellnessApproval?.address != null ? wellnessApproval?.address : '');
            setValue('activity_date', wellnessApproval?.activity_date != null ? wellnessApproval?.activity_date : '');
            setValue('actual_activity_date', wellnessApproval?.actual_activity_date != null ? wellnessApproval?.actual_activity_date : '');
            setValue('remarks', wellnessApproval?.remarks != null ? wellnessApproval?.remarks : '');
            setValue('reason', wellnessApproval?.reason != null ? wellnessApproval?.reason : '');
            setValue('status', wellnessApproval?.status != null ? wellnessApproval?.status : '');
            setValue('reason', wellnessApproval?.reason != null ? wellnessApproval?.reason : '');
            setValue('wellness_type_detail', wellnessApproval?.wellness_type_detail != null ? wellnessApproval?.wellness_type_detail : '');
            setValue('wellness_executed_by', wellnessApproval?.wellness_executed_by != null ? wellnessApproval?.wellness_executed_by : '');
            if (wellnessApproval?.wellness_executed_by != null) {
                setSelectedExecutedBy(wellnessApproval?.wellness_executed_by);
                setShowExecutedBy(true);
            }
            if (wellnessApproval?.activity_date != null) {
                const check = new Date(wellnessApproval?.activity_date) < new Date().setHours(0, 0, 0, 0);
                setShowExecutedBy(check);
            }
            // setWellnessStatus(wellnessApproval?.rm_status != null ? wellnessApproval?.rm_status : '')
            setValue('rm_status', wellnessApproval?.rm_status != null ? wellnessApproval?.rm_status : '');
            props.actionEditWellnessApprovalClientType();
            props.actionWellnessManagementWellnessType();
            props.actionWellnessManagementAssignRm();
        }
    }, [wellnessApproval])

    const onViewWellnessManagmentApprovalUpdate = () => {
        const { ViewWellnessManagmentApproval } = props;
        var ViewWellnessManagmentApprovalResponse = Object.assign({}, ViewWellnessManagmentApproval);
        if (ViewWellnessManagmentApprovalResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setWellnessApproval(ViewWellnessManagmentApprovalResponse.response);
            setWellnessDocument(ViewWellnessManagmentApprovalResponse.response.wellness_request_document);
            setClientName([{ name: ViewWellnessManagmentApprovalResponse?.response?.client_name, id: ViewWellnessManagmentApprovalResponse?.response?.client_id }])

            let docCount = ViewWellnessManagmentApprovalResponse?.response?.wellness_request_document?.length || 0
            setMaxFileCount(5 - docCount);

        } else {
            $("#progress-loader").addClass("hidden");
            switch (ViewWellnessManagmentApprovalResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onViewWellnessManagmentApprovalUpdate, [props.ViewWellnessManagmentApproval]);

    const onAssignRmUpdate = () => {
        const { AssignRm } = props;
        var AssignRmResponse = Object.assign({}, AssignRm);
        if (AssignRmResponse.result === true) {
            var arrAssignRM = Object.assign([], AssignRmResponse.response);
            var user = {
                user_id: 0,
                full_name: "None"
            };
            arrAssignRM.unshift(user);
            setAssignRm(arrAssignRM);
        } else {
            switch (AssignRmResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setAssignRm([]);
                    break;
                default:
            }
        }
    }
    useEffect(onAssignRmUpdate, [props.AssignRm]);

    useEffect(() => {
        if (assignRm != null && assignRm.length > 0 && wellnessApproval !== null) {
            if (wellnessApproval != null && wellnessApproval.assign_rm_id != null) {
                setValue('assign_rm', wellnessApproval?.assign_rm_id);
            } else {
                setValue('assign_rm', 0);
            }
        }
    }, [assignRm, wellnessApproval])

    useEffect(() => {
        if (clientType != null && clientType.length > 0) {
            if (wellnessApproval != null) {
                setValue('client_type', wellnessApproval.client_type);
            }
        }
    }, [clientType])

    const onWellnessTypeUpdate = () => {
        const { WellnessType } = props;
        var WellnessTypeResponse = Object.assign({}, WellnessType);
        if (WellnessTypeResponse.result === true) {
            setWellnessType(WellnessTypeResponse.response);
        } else {
            switch (WellnessTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setWellnessType([]);
                    break;
                default:
            }
        }
    }
    useEffect(onWellnessTypeUpdate, [props.WellnessType]);

    useEffect(() => {
        if (wellnessType != null && wellnessType.length > 0) {
            if (wellnessApproval != null && wellnessApproval.wellness_type_id != null) {
                setValue('wellness_type_id', wellnessApproval?.wellness_type_id != null ? wellnessApproval?.wellness_type_id : null);
            }
        }
    }, [wellnessType])

    const onwellnessApprovalClientTypeUpdate = () => {
        const { WellnessApprovalClientType } = props;
        var WellnessApprovalClientTypeResponse = Object.assign({}, WellnessApprovalClientType);
        if (WellnessApprovalClientTypeResponse.result === true) {
            setClientType(WellnessApprovalClientTypeResponse.response);
        } else {
            switch (WellnessApprovalClientTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onwellnessApprovalClientTypeUpdate, [props.WellnessApprovalClientType]);

    useEffect(() => {
        // if (clientName != null && clientName.length > 0) {
        if (wellnessApproval != null) {
            setValue('client_id', wellnessApproval.client_id);
        }
        // }
    }, [wellnessApproval])

    const onWellnessApprovalClientNameUpdate = () => {
        const { WellnessApprovalClientName } = props;
        var WellnessApprovalClientNameResponse = Object.assign({}, WellnessApprovalClientName);
        if (WellnessApprovalClientNameResponse.result === true) {
            // setClientName(WellnessApprovalClientNameResponse.response);
        } else {
            switch (WellnessApprovalClientNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onWellnessApprovalClientNameUpdate, [props.WellnessApprovalClientName]);

    const onEditWellnessApprovalUpdate = () => {
        const { EditWellnessApproval } = props;
        var EditWellnessApprovalResponse = Object.assign({}, EditWellnessApproval);
        $("#edit_wellness_approval").prop("disabled", false);
        if (EditWellnessApprovalResponse.result === true) {
            $("#wellness-approval-edit-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Wellness Updated Successfully");
            if (props?.location?.state?.type === 'ADD_WELLNESS_REQUEST') {
                props.history.push({
                    pathname: '/notification'
                })
            } else {
                props.history.goBack();
            }
        }
        else {
            $("#wellness-approval-edit-loader").addClass("hidden");
            switch (EditWellnessApprovalResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    toast.error(EditWellnessApprovalResponse.message);
                default:
            }
        }
    }
    useEffect(onEditWellnessApprovalUpdate, [props.EditWellnessApproval]);


    const onWellnessDocumentDeletUpdate = () => {
        const { wellnessDocumentDelete } = props;
        const wellnessDocumentDeleteResponse = Object.assign({}, wellnessDocumentDelete);
        if (wellnessDocumentDeleteResponse.result === true) {
            props.actionViewWellnessApproval({ id: props.location.state.id });
        } else {
            switch (wellnessDocumentDeleteResponse.status) {
                case UNAUTHORIZED:
                    Swal.fire({
                        title: 'Session',
                        text: 'Your session has been expired. Please Login to continue'
                    }).then(success => {
                        clearData()
                        setTimeout(() => {
                            window.location.replace('/login')
                        }, 200)
                        props.auth.logout();
                    })

                    break;
                default:
            }
        }
    }
    useEffect(onWellnessDocumentDeletUpdate, [props.wellnessDocumentDelete])


    const onSubmit = (data) => {
        if (files?.length === 0 && wellnessDocument?.length === 0 && wellnessStatus === 'close') {
            toast.error("Document is required");
            return;
        }

        // if (files != null && files.length > 0 && wellnessApproval?.wellness_request_document?.length >= 5) {
        //     toast.error("Maximum 5 documents can be uploaded");
        //     return;
        // }

        // if (files != null && wellnessApproval?.wellness_request_document?.length + files?.length > 5) {
        //     toast.error(`${wellnessApproval?.wellness_request_document?.length} documents uploaded already, only ${5 - wellnessApproval?.wellness_request_document?.length} more documents can be uploaded`);
        //     return;
        // }

        $("#wellness-approval-edit-loader").removeClass("hidden");
        $("#edit_wellness_approval").prop("disabled", true);
        data.id = wellnessApproval?.id;
        if (data.created_at === null || data.created_at === undefined) {
            delete data.created_at;
        }
        if (data.assign_rm == null || data.assign_rm == 0) { delete data.assign_rm; }
        data.rm_status = data.rm_status != null ? data.rm_status : null;
        var sendFiles = [];
        if (files != null && files.length > 0) {
            $('#error-doc-not-found').addClass('d-none')
            files.forEach((file, indexFile) => {
                sendFiles.push({ 'name': `file[${indexFile}]`, 'value': file });
            });
        } else if (wellnessApproval?.wellness_request_document?.length === 0) {
            if (data.rm_status === 'close') {
                $('#error-doc-not-found').removeClass('d-none')
                $("#wellness-pool-edit-loader").addClass("hidden");
                $("#edit_wellness_pool").prop("disabled", false);
                return
            }
        }
        delete data.file;
        if (data.rm_status === null || data.rm_status === "undefined" || data.rm_status === undefined) {
            delete data.rm_status;
        }
        if (data.wellness_executed_by === null || data.wellness_executed_by === "" || data.wellness_executed_by === undefined) {
            delete data.wellness_executed_by;
        }
        props.actionEditWellnessApproval(data, sendFiles);

    }


    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length + files.length > maxFileCount) {
            Swal.fire({
                title: 'Upload Error',
                text: 'Maximum 5 files are allowed.',
                icon: 'error'
            })
            return
        }

        const newFiles = acceptedFiles.filter(file => {
            const ext = file.name.split('.').pop().toLowerCase()
            if (!validExt.includes(`.${ext}`)) {
                Swal.fire({
                    title: 'Upload Error',
                    text: 'You can only upload Word, TXT, Excel, PDF, JPEG, JPG, PNG & ZIP files',
                    icon: 'error'
                })
                return false
            }
            if (file.size >= CLAIM_FILE_SIZE) {
                Swal.fire({
                    title: 'Error',
                    icon: 'error',
                    html: CLAIM_FILE_ERROR_MESSAGE,
                    confirmButtonColor: PRIMARY_COLOR
                })
                return false
            }
            return true
        })

        setFiles(prevFiles => {
            const updatedFiles = [...prevFiles, ...newFiles]
            return updatedFiles
        })

    }, [files, maxFileCount])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/pdf': ['.pdf'],
            'application/zip': ['.zip'],
            'text/plain': ['.txt'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png']
        },
        maxFiles: maxFileCount
    })

    const deleteFile = (index) => {
        setFiles(prevFiles => {
            const updatedFiles = prevFiles.filter((_, i) => i !== index)
            return updatedFiles
        })
    }

    return (
        <>
            <ScreenTitle title="Wellness Approval Edit" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Created Date</label>
                                                    <input
                                                        id="created_at"
                                                        name="created_at"
                                                        className="form-control"
                                                        type="text"
                                                        disabled={true}
                                                        {...register("created_at")}
                                                    />
                                                    {errors.created_at && <ErrorLabel message={errors.created_at?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Type</label>
                                                    <Controller
                                                        name="client_type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_type"
                                                                name="client_type"
                                                                dataSource={clientType}
                                                                allowFiltering={false}
                                                                enabled={false}
                                                                placeholder="Select Client Type"
                                                                value={field.value}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                        props.actionEditWellnessApprovalClientName({ client_type: event.itemData.value });
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />

                                                    {errors.client_type && <ErrorLabel message={errors.client_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Name</label>
                                                    <Controller
                                                        name="client_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_id"
                                                                name="client_id"
                                                                dataSource={clientName}
                                                                allowFiltering={false}
                                                                enabled={false}
                                                                placeholder="Select Client Name"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }

                                                    />

                                                    {errors.client_id && <ErrorLabel message={errors.client_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="wellness_type_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="wellness_type_id"
                                                                name="wellness_type_id"
                                                                fields={{ text: "name", value: "id" }}
                                                                dataSource={wellnessType}
                                                                allowFiltering={false}
                                                                placeholder="Select Type"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        setSelectType(event.itemData.name)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Type is required'
                                                        }}
                                                    />
                                                    {errors.wellness_type_id && <ErrorLabel message={errors.wellness_type_id?.message} />}
                                                </div>
                                            </div>
                                            {
                                                selectType === 'Others' ?
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">Description&nbsp;<span className="danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                id="wellness_type_detail"
                                                                name="wellness_type_detail"
                                                                type="text"
                                                                placeholder="Enter description"
                                                                {...register('wellness_type_detail', {
                                                                    required: selectType === "Others" ? "Description is required" : false
                                                                })}
                                                            />
                                                            {errors.wellness_type_detail?.type && <ErrorLabel message={errors.wellness_type_detail?.message} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                    </>
                                            }
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Location Address&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        type="text"
                                                        maxLength={250}
                                                        placeholder="Enter Location Address"
                                                        disabled={!['update', 'pending'].includes(wellnessApproval?.status) ? true : false}
                                                        {...register('address', {
                                                            required: 'Location Address is required',
                                                            maxLength:
                                                            {
                                                                value: 250,
                                                                message: "Please enter 250 charcter only"
                                                            }
                                                        })}
                                                    />
                                                    {errors.address?.type === 'required' && <ErrorLabel message={errors.address?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Date of Activity</label>
                                                    <Controller
                                                        name="activity_date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="activity_date"
                                                                name="activity_date"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                disabled={!['update', 'pending'].includes(wellnessApproval?.status) ? true : false}
                                                                value={field.value}
                                                                onValueChange={(value) => {
                                                                    const check = new Date(value) < new Date().setHours(0, 0, 0, 0);
                                                                    if (!check) {
                                                                        setSelectedExecutedBy(null);
                                                                        setValue("wellness_executed_by", null);
                                                                        setValue("assign_rm", null);
                                                                    }
                                                                    setShowExecutedBy(check);
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }

                                                    />
                                                    {errors.activity_date && <ErrorLabel message={errors.activity_date?.message} />}
                                                </div>
                                            </div>
                                            {showExecutedBy && <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Wellness Executed By&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="wellness_executed_by"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="wellness_executed_by"
                                                                name="wellness_executed_by"
                                                                dataSource={["Internal Team", "Direct Vendor / TPA"]}
                                                                allowFiltering={false}
                                                                placeholder="Select Wellness Executed By"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        if (event.itemData.value === "Direct Vendor / TPA") {
                                                                            setValue("rm_status", "close");
                                                                            setValue("assign_rm", null);
                                                                        } else {
                                                                            setValue("rm_status", null);
                                                                        }
                                                                        setSelectedExecutedBy(event.itemData.value)
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Wellness Executed By is required'
                                                        }}
                                                    />
                                                    {errors.wellness_executed_by && <ErrorLabel message={errors.wellness_executed_by?.message} />}
                                                </div>
                                            </div>
                                            }
                                            {(departments != null && departments.length > 0 && departments.includes('Wellness') && showExecutedBy === false) || (selectedExecutedBy === "Internal Team") ?
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">
                                                            {showExecutedBy === false && departments != null && departments.length > 0 && departments.includes('Wellness') ? 'Assigned RM' : 'Assigned RM'}
                                                        </label>
                                                        <Controller
                                                            name="assign_rm"
                                                            control={control}
                                                            render={({ field }) =>
                                                                <DropDownListComponent
                                                                    {...field}
                                                                    id="assign_rm"
                                                                    name="assign_rm"
                                                                    dataSource={assignRm}
                                                                    allowFiltering={false}
                                                                    placeholder="Select Assigned RM"
                                                                    value={field.value}
                                                                    fields={{ text: 'full_name', value: 'user_id' }}
                                                                    change={(event) => {
                                                                        if (event.itemData != null) {
                                                                            field.onChange(event.itemData.user_id)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        />

                                                        {errors.assign_rm && <ErrorLabel message={errors.assign_rm?.message} />}
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            }

                                            {showExecutedBy && selectedExecutedBy === "Direct Vendor / TPA" ?
                                                <>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">RM Status</label>
                                                            <Controller
                                                                name="rm_status"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        id="rm_status"
                                                                        name="rm_status"
                                                                        dataSource={[
                                                                            {
                                                                                text: 'Update',
                                                                                value: 'update'
                                                                            },
                                                                            {
                                                                                text: 'Cancel',
                                                                                value: 'cancel'
                                                                            },
                                                                            {
                                                                                text: 'Close',
                                                                                value: 'close'
                                                                            }
                                                                        ]}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Status"
                                                                        value={field.value != null ? field.value : 'close'}
                                                                        fields={{ text: 'text', value: 'value' }}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData.value);
                                                                                setWellnessRMStatus(event.itemData.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                }

                                                            />

                                                            {errors.rm_status && <ErrorLabel message={errors.rm_status?.message} />}
                                                        </div>
                                                    </div>


                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="file-upload">
                                                                Document
                                                                <span className={`danger ${wellnessStatus === 'close' ? '' : 'hidden'}`}>*</span>
                                                            </label>
                                                            <div
                                                                {...getRootProps()}
                                                                className={`dropzone ${isDragActive ? 'active' : ''}`}
                                                                style={{
                                                                    border: '2px dashed #cccccc',
                                                                    borderRadius: '4px',
                                                                    padding: '20px',
                                                                    textAlign: 'center',
                                                                    cursor: 'pointer'
                                                                }}
                                                            >
                                                                <input {...getInputProps()} id="file-upload" />
                                                                {isDragActive ? (
                                                                    <p>Drop the files here ...</p>
                                                                ) : (
                                                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                                                )}
                                                            </div>

                                                            {files.length > 0 && (
                                                                <div className="mt-1">
                                                                    <strong>Selected files:</strong>
                                                                    <ul className="list-unstyled">
                                                                        {files.map((file, index) => (
                                                                            <li key={index} className="d-flex justify-content-between align-items-center">
                                                                                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                                    {file.name}
                                                                                </span>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-danger btn-sm"
                                                                                    onClick={() => deleteFile(index)}>
                                                                                    Delete
                                                                                </button>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            )}


                                                            {wellnessDocument !== null && wellnessDocument.length > 0 && (
                                                                <div className="mt-1">
                                                                    <strong>Uploaded files:</strong>
                                                                    <ul className="list-unstyled">
                                                                        {/* Iterate over the array in pairs */}
                                                                        {wellnessDocument.map((file, index) => {
                                                                            if (index % 2 === 0) {
                                                                                return (
                                                                                    <li key={index} className="row mb-half">
                                                                                        {/* First file */}
                                                                                        <div className="col-6 d-flex align-items-center">
                                                                                            <div>
                                                                                                <a className="primary"
                                                                                                    onClick={() => {
                                                                                                        const extension = file.wellness_request_document?.split('.').pop().toLowerCase();
                                                                                                        if (['pdf', 'png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
                                                                                                            setSelectedDocumentIndex(file.wellness_request_document);
                                                                                                            setShowDocumentDetails(true);
                                                                                                            setExtension(extension);
                                                                                                        } else {
                                                                                                            downloadFile(file.wellness_request_document);
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    Document {index + 1}
                                                                                                </a>
                                                                                                {/* <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                                            Document {index + 1}
                                                                                        </span> */}
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-danger btn-sm ml-1"
                                                                                                    onClick={() => {
                                                                                                        Swal.fire({
                                                                                                            title: 'Delete Document',
                                                                                                            text: 'Are you sure, you want to delete document?',
                                                                                                            showCancelButton: true,
                                                                                                            cancelButtonText: 'No',
                                                                                                            showConfirmButton: true,
                                                                                                            confirmButtonText: 'Yes',
                                                                                                        }).then(result => {
                                                                                                            if (result.isConfirmed) {
                                                                                                                props.actionWellnessDocumentDelete({
                                                                                                                    wellness_id: file?.wellness_request_id,
                                                                                                                    wellness_dms_id: file?.document_dms_id
                                                                                                                })
                                                                                                            }
                                                                                                        })
                                                                                                    }}>
                                                                                                    Delete
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>

                                                                                        {/* Second file, if exists */}
                                                                                        {wellnessDocument[index + 1] && (
                                                                                            <div className="col-6 d-flex align-items-center">
                                                                                                <div>
                                                                                                    <a className="primary"
                                                                                                        onClick={() => {
                                                                                                            const extension = file.wellness_request_document?.split('.').pop().toLowerCase();
                                                                                                            if (['pdf', 'png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
                                                                                                                setSelectedDocumentIndex(file.wellness_request_document);
                                                                                                                setShowDocumentDetails(true);
                                                                                                                setExtension(extension);
                                                                                                            } else {
                                                                                                                downloadFile(file.wellness_request_document);
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        Document {index + 2}
                                                                                                    </a>
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-danger btn-sm ml-2"
                                                                                                        onClick={() => {
                                                                                                            Swal.fire({
                                                                                                                title: 'Delete Document',
                                                                                                                text: 'Are you sure, you want to delete document?',
                                                                                                                showCancelButton: true,
                                                                                                                cancelButtonText: 'No',
                                                                                                                showConfirmButton: true,
                                                                                                                confirmButtonText: 'Yes',
                                                                                                            }).then(result => {
                                                                                                                if (result.isConfirmed) {
                                                                                                                    props.actionWellnessDocumentDelete({
                                                                                                                        wellness_id: file?.wellness_request_id,
                                                                                                                        wellness_dms_id: file?.document_dms_id
                                                                                                                    })
                                                                                                                }
                                                                                                            })
                                                                                                        }}>
                                                                                                        Delete
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </li>
                                                                                );
                                                                            }
                                                                            return null;
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            )}



                                                            {files?.length === 0 && wellnessDocument?.length === 0 && wellnessStatus === 'close' && (
                                                                <small className="error-msg text-danger">Document is required</small>
                                                            )}
                                                        </div>


                                                    </div>
                                                </>
                                                :
                                                <></>
                                            }
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Remark</label>
                                                    <input
                                                        className="form-control"
                                                        id="remarks"
                                                        name="remarks"
                                                        type="text"
                                                        placeholder="Enter Remark"
                                                        disabled={!['update', 'pending'].includes(wellnessApproval?.status) ? true : false}
                                                        {...register('remarks')}
                                                    />
                                                    {errors.remarks && <ErrorLabel message={errors.remarks?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Approved By
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Actual Date of Activity&nbsp;<span className={`danger ${wellnessRMStatus === 'close' ? '' : 'hidden'}`}>*</span></label>
                                                    <Controller
                                                        name="actual_activity_date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="actual_activity_date"
                                                                name="actual_activity_date"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                value={field.value}

                                                                disabled={!['update', 'pending'].includes(wellnessApproval?.status) ? true : false}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: wellnessRMStatus === 'close' ? 'Please Select Actual Date of Activity' : false
                                                        }}
                                                    />
                                                    {errors.actual_activity_date && <ErrorLabel message={errors.actual_activity_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Status</label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="status"
                                                                name="status"
                                                                dataSource={[
                                                                    {
                                                                        text: 'Approve',
                                                                        value: 'approve'
                                                                    },
                                                                    {
                                                                        text: 'Reject',
                                                                        value: 'reject'
                                                                    }
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                enabled={['update', 'pending'].includes(wellnessApproval?.status) ? true : false}
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                        setWellnessStatus(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }

                                                    />

                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Reason&nbsp;<span className={`danger ${wellnessStatus != null && wellnessStatus === 'reject' ? '' : 'hidden'}`}>*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="reason"
                                                        name="reason"
                                                        type="text"
                                                        placeholder="Enter Reason"
                                                        disabled={!['update', 'pending'].includes(wellnessApproval?.status) ? true : false}
                                                        {...register('reason', {
                                                            required: wellnessStatus != null && wellnessStatus === 'reject' ? 'Please Enter Reason' : false
                                                        })}
                                                    />
                                                    {errors.reason && <ErrorLabel message={errors.reason?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        {
                                            wellnessApproval?.status === 'pending' || wellnessApproval?.status === 'update' ?
                                                <button
                                                    id="edit_wellness_approval"
                                                    className={`btn btn-primary mr-1 shadow round text-uppercase ${['update', 'pending'].includes(wellnessApproval?.status) ? '' : 'hidden'}`}
                                                    type="submit"
                                                >

                                                    <div className="d-flex align-items-center">
                                                        Submit
                                                        <span id="wellness-approval-edit-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                    </div>
                                                </button>
                                                :
                                                <></>
                                        }

                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (props?.location?.state?.type === 'ADD_WELLNESS_REQUEST') {
                                                    props.history.push({
                                                        pathname: '/notification'
                                                    })
                                                } else {
                                                    props.history.goBack();
                                                }
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                // detailsOfDocument={documentDetails}
                extension={extension}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />
        </>
    )

}
const mapStateToProps = (state) => {
    var ViewWellnessManagmentApproval = null;
    var WellnessApprovalClientType = null;
    var WellnessApprovalClientName = null;
    var EditWellnessApproval = null;
    var WellnessType = null;
    var AssignRm = null;
    var wellnessDocumentDelete = null
    if (
        state.EditWellnessApprovalReducer != null &&
        Object.keys(state.EditWellnessApprovalReducer).length > 0 &&
        state.EditWellnessApprovalReducer.constructor === Object
    ) {
        switch (state.EditWellnessApprovalReducer.tag) {
            case ACTION_VIEW_WELLNESS_APPROVAL_SUCCESS:
                ViewWellnessManagmentApproval = Object.assign({}, state.EditWellnessApprovalReducer.data);
                delete state.EditWellnessApprovalReducer;
                return { ViewWellnessManagmentApproval };
            case ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_TYPE_SUCCESS:
                WellnessApprovalClientType = Object.assign({}, state.EditWellnessApprovalReducer.data);
                delete state.EditWellnessApprovalReducer;
                return { WellnessApprovalClientType };
            case ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_NAME_SUCCESS:
                WellnessApprovalClientName = Object.assign({}, state.EditWellnessApprovalReducer.data);
                delete state.EditWellnessApprovalReducer;
                return { WellnessApprovalClientName };
            case ACTION_WELLNESS_APPROVAL_EDIT_SUCCESS:
                EditWellnessApproval = Object.assign({}, state.EditWellnessApprovalReducer.data);
                delete state.EditWellnessApprovalReducer;
                return { EditWellnessApproval };
            case ACTION_EDIT_WELLNESS_APPROVAL_TYPE_SUCCESS:
                WellnessType = Object.assign({}, state.EditWellnessApprovalReducer.data);
                delete state.EditWellnessApprovalReducer;
                return { WellnessType };
            case ACTION_EDIT_WELLNESS_APPROVAL_ASSIGN_RM_SUCCESS:
                AssignRm = Object.assign({}, state.EditWellnessApprovalReducer.data);
                delete state.EditWellnessApprovalReducer;
                return { AssignRm };

            case ACTION_WELLNESS_DOCUMENT_DELETE_SUCCESS:
                wellnessDocumentDelete = Object.assign({}, state.EditWellnessManagementPoolReducer.data);
                delete state.EditWellnessManagementPoolReducer;
                return { wellnessDocumentDelete }
            default:
        }
    }

    if (
        state.EditWellnessManagementPoolReducer != null &&
        Object.keys(state.EditWellnessManagementPoolReducer).length > 0 &&
        state.EditWellnessManagementPoolReducer.constructor === Object
    ) {
        switch (state.EditWellnessManagementPoolReducer.tag) {
            case ACTION_WELLNESS_DOCUMENT_DELETE_SUCCESS:
                wellnessDocumentDelete = Object.assign({}, state.EditWellnessManagementPoolReducer.data);
                delete state.EditWellnessManagementPoolReducer;
                return { wellnessDocumentDelete }
            default:
        }
    }
    return {
        ViewWellnessManagmentApproval,
        WellnessApprovalClientType,
        WellnessApprovalClientName,
        EditWellnessApproval,
        WellnessType,
        AssignRm,
        wellnessDocumentDelete
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionViewWellnessApproval,
        actionEditWellnessApprovalClientType,
        actionEditWellnessApprovalClientName,
        actionEditWellnessApproval,
        actionWellnessManagementAssignRm,
        actionWellnessManagementWellnessType,
        actionWellnessDocumentDelete
    }, dispatch)
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(WellnessApprovalEdit))