import React, { useEffect, useState } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateBox } from 'devextreme-react/date-box';
import ErrorLabel from "../../../../../../component/form/error_label";
import { useForm, Controller } from "react-hook-form";
import dateFormat from 'dateformat';
import { UNAUTHORIZED, ERROR } from '../../../../../../constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import 'react-day-picker/lib/style.css';
import $ from 'jquery';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import {
    actionEditInventory, actionEditAssetTypeInventory, actionEditEmployeeListInventory,
    ACTION_EDIT_INVENTORY_SUCCESS, ACTION_EDIT_ASSET_TYPE_SUCCESS, ACTION_EDIT_EMPLOYEE_LIST_SUCCESS
} from './action';
import Swal from 'sweetalert2';
import moment from 'moment'


const EditInventory = (props) => {

    const [minDateValue, setMinDateValue] = useState(new Date());
    const { register, handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = useForm()
    const [employeeListDropDown, SetEmployeeListDropDown] = useState([]);
    const [assetTypeDropDown, SetAssetTypeDropDown] = useState([]);
    const [statusDropDwon, SetStatusDropDown] = useState([
        { value: 'Assigned', name: 'Assigned' },
        { value: 'Unassigned', name: 'Unassigned' },
        { value: 'Discarded', name: 'Discarded' },

    ]);

    const [statusValue, assetTypeValue] = watch(['status', 'asset_type']);

    const initialRender = () => {
        props.actionEditAssetTypeInventory()
        props.actionEditEmployeeListInventory()

    }
    useEffect(initialRender, []);

    const validatePurchaseDate = (value) => {
        const dateOfPurchase = getValues('date_of_purchase');
        if (dateOfPurchase && value) {
            // Compare dates
            return new Date(value) > new Date(dateOfPurchase) || 'Warranty Expiry Date must be greater than Date of Purchase';
        }
        return true; // If either date is not set, skip validation
    };
    useEffect(() => {
        if (props?.location?.state?.detail && employeeListDropDown?.length > 0 && assetTypeDropDown?.length > 0) {
            const details = props.location.state.detail;

            const dateOfPurchaseConverted = details?.date_of_purchase !== null ? moment(details?.date_of_purchase, "DD-MMM-YYYY").format("YYYY-MM-DD") : null;
            const warrantyExpiryDateConverted = details?.warranty_expiry_date ? moment(details?.warranty_expiry_date, "DD-MMM-YYYY").format("YYYY-MM-DD") : null;

            // Set each field individually using setValue
            setValue("id", details.id);
            setValue("status", details?.status || '');
            setValue("asset_type", details?.asset_type || '');
            setValue("brand", details?.brand || '');
            setValue("model", details?.model || '');
            setValue("serial_no", details?.serial_no || '');
            setValue("date_of_purchase", dateOfPurchaseConverted);
            setValue("warranty_expiry_date", warrantyExpiryDateConverted);
            setValue("cost_of_asset", details?.cost_of_asset || null);
            setValue("hdd", details?.hdd || null);
            setValue("ram", details?.ram || null);
            setValue("remarks", details?.remarks || null);
            setValue("asset_type_description", details?.asset_type_description || null);
            if (details?.inventory_history.length > 0) {
                setValue("user_id", details?.inventory_history[0]?.user_id);
            }
        }
    }, [props.location, employeeListDropDown, assetTypeDropDown]);



    const onEditInventoryUpdate = () => {
        const { EditInventory } = props;
        var EditInventoryResponse = Object.assign({}, EditInventory);
        $("#edit-inventory").prop("disabled", false);
        if (EditInventoryResponse.result === true) {
            $("#edit-inventory-loader").addClass("hidden");
            $("#progress-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Inventory Updated Successfully");
            props.history.push('/tasks/inventory');
        } else {
            $("#edit-inventory-loader").addClass("hidden");
            $("#progress-loader").addClass("hidden");
            switch (EditInventoryResponse.status) {
                case ERROR:
                    Swal.fire({
                        title: 'Upload Error',
                        text: EditInventoryResponse.message,
                        icon: 'error'
                    }).then(success => {

                    })
                    break;
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onEditInventoryUpdate, [props.EditInventory]);


    const onEmployeeListUpdate = () => {
        const { EditEmployeeList } = props;
        const EmployeeListResponse = { ...EditEmployeeList };
        if (EmployeeListResponse.result === true) {
            SetEmployeeListDropDown(EmployeeListResponse.response);
        } else {
            switch (EmployeeListResponse.status) {
                case UNAUTHORIZED:

                default:
            }
        }
    }
    useEffect(onEmployeeListUpdate, [props.EditEmployeeList]);

    const onAssetTypeUpdate = () => {
        const { EditAssetTypeResponse } = props;
        const AssetTypeResponse = { ...EditAssetTypeResponse };
        if (AssetTypeResponse?.result === true) {
            SetAssetTypeDropDown(AssetTypeResponse?.response?.assetTypes);
        } else {
            switch (AssetTypeResponse.status) {
                case UNAUTHORIZED:

                default:
            }
        }
    }
    useEffect(onAssetTypeUpdate, [props.EditAssetTypeResponse]);




    const onSubmit = (data) => {
        console.log("dfdsfsd", JSON.stringify(data))
        $("#progress-loader").removeClass("hidden");
        $("#edit-inventory-loader").removeClass("hidden");
        $("#edit-inventory").prop("disabled", true);
        props.actionEditInventory(data);
    }

    return (
        <>
            <section>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Edit Inventory </h5>
                    </div>
                    <div className="content-header-right col-12 col-md-6 col-lg-6">
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            {/* Status Dropdown */}
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Status&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        rules={{ required: 'Status is required' }}
                                                        render={({ field }) => (
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={statusDropDwon}
                                                                allowFiltering={true}
                                                                placeholder="Select Status"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'name' }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.status && <ErrorLabel message={errors.status.message} />}
                                                </div>
                                            </div>

                                            {/* Employee Name Dropdown */}
                                            {statusValue === 'Assigned' && (
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Employee Name&nbsp;<span className="danger">*</span></label>
                                                        <Controller
                                                            name="user_id"
                                                            control={control}
                                                            rules={{ required: 'Please Select Employee Name' }}
                                                            render={({ field }) => (
                                                                <DropDownListComponent
                                                                    {...field}
                                                                    dataSource={employeeListDropDown}
                                                                    fields={{ text: 'full_name', value: 'user_id' }}
                                                                    allowFiltering={true}
                                                                    placeholder="Select Employee Name"
                                                                    value={field.value}
                                                                />
                                                            )}
                                                        />
                                                        {errors.user_id && <ErrorLabel message={errors.user_id.message} />}
                                                    </div>
                                                </div>
                                            )}

                                            {/* Asset Type Dropdown */}
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Asset Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="asset_type"
                                                        control={control}
                                                        rules={{ required: 'Asset Type is required' }}
                                                        render={({ field }) => (
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={assetTypeDropDown}
                                                                allowFiltering={true}
                                                                placeholder="Select Assign to"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'name' }}
                                                                enabled={false}

                                                            />
                                                        )}
                                                    />
                                                    {errors.asset_type && <ErrorLabel message={errors.asset_type.message} />}
                                                </div>
                                            </div>

                                            {/* Discription Input */}
                                            {assetTypeValue === "Others" && (
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">Discription&nbsp;
                                                            {
                                                                (assetTypeValue === 'Others'
                                                                )
                                                                &&
                                                                <span className="danger">*</span>
                                                            }
                                                        </label>

                                                        <Controller
                                                            name="asset_type_description"
                                                            control={control}
                                                            rules={{ required: assetTypeValue === 'Others' ? 'Discription is required' : false }}
                                                            render={({ field }) => (
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Discription"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            )}


                                            {/* Brand Input */}
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Brand&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="brand"
                                                        control={control}
                                                        rules={{ required: "Brand Name is Required" }}
                                                        render={({ field }) => (
                                                            <input
                                                                className="form-control"
                                                                placeholder="Enter Brand Name"
                                                                {...field}
                                                                readOnly
                                                            />
                                                        )}
                                                    />
                                                    {errors.brand && <ErrorLabel message={errors.brand.message} />}
                                                </div>
                                            </div>

                                            {/* Model Name Input */}
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Model Name&nbsp;
                                                        {
                                                            assetTypeValue !== 'Others' &&
                                                            <span className="danger">*</span>
                                                        }
                                                    </label>

                                                    <Controller
                                                        name="model"
                                                        control={control}
                                                        rules={{ required: assetTypeValue !== 'Others' ? "Model Name is Required" : false }}
                                                        render={({ field }) => (
                                                            <input
                                                                className="form-control"
                                                                placeholder="Enter Model Name"
                                                                {...field}
                                                                readOnly
                                                            />
                                                        )}
                                                    />
                                                    {errors.model && <ErrorLabel message={errors.model.message} />}
                                                </div>
                                            </div>

                                            {/* Serial Number */}
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Serial Number&nbsp;
                                                        {
                                                            assetTypeValue !== 'Others' &&
                                                            <span className="danger">*</span>
                                                        }
                                                    </label>

                                                    <Controller
                                                        name="serial_no"
                                                        control={control}
                                                        rules={{
                                                            required: assetTypeValue !== 'Others' ? 'Serial Number is required' : false
                                                        }}
                                                        render={({ field }) => (
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Serial Number"
                                                                {...field}
                                                                readOnly
                                                            />
                                                        )}
                                                    />
                                                    {errors.serial_no && <ErrorLabel message={errors.serial_no.message} />}
                                                </div>
                                            </div>

                                            {/* Date of Purchase */}
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Date of Purchase&nbsp;
                                                        {
                                                            assetTypeValue !== 'Others' &&
                                                            <span className="danger">*</span>
                                                        }
                                                    </label>
                                                    <Controller
                                                        name="date_of_purchase"
                                                        control={control}
                                                        rules={{
                                                            required: assetTypeValue !== 'Others' ? 'Date of Purchase is required' : false
                                                        }}
                                                        render={({ field }) => (
                                                            <DateBox
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                acceptCustomValue={false}
                                                                stylingMode="underlined"
                                                                className="form-group greyed-out"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={false}
                                                                value={field.value}
                                                                disabled
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.date_of_purchase && <ErrorLabel message={errors.date_of_purchase.message} />}
                                                </div>
                                            </div>

                                            {/* Warranty Expiry Date */}
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control"
                                                    >Warranty Expiry Date&nbsp;
                                                        {
                                                            (assetTypeValue !== 'Others' &&
                                                                assetTypeValue !== 'Sim Card' &&
                                                                assetTypeValue !== 'Mouse' &&
                                                                assetTypeValue !== 'Headset' &&
                                                                assetTypeValue !== 'Keyboard' &&
                                                                assetTypeValue !== 'Charger') && (
                                                                <span className="danger">*</span>
                                                            )
                                                        }
                                                    </label>
                                                    <Controller
                                                        name="warranty_expiry_date"
                                                        control={control}
                                                        rules={{
                                                            required:
                                                                (assetTypeValue !== 'Others' &&
                                                                    assetTypeValue !== 'Sim Card' &&
                                                                    assetTypeValue !== 'Mouse' &&
                                                                    assetTypeValue !== 'Headset' &&
                                                                    assetTypeValue !== 'Keyboard' &&
                                                                    assetTypeValue !== 'Charger') ?
                                                                    'Warranty Expiry Date is required' : false,
                                                            validate: validatePurchaseDate
                                                        }}
                                                        render={({ field }) => (
                                                            <DateBox
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                acceptCustomValue={false}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                value={field.value}
                                                                onValueChange={(value) => {
                                                                    const formattedValue = dateFormat(value, 'yyyy-mm-dd');
                                                                    field.onChange(formattedValue);
                                                                    // Update minDateValue for the warranty expiry date field
                                                                    setMinDateValue(formattedValue);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.warranty_expiry_date && <ErrorLabel message={errors.warranty_expiry_date.message} />}
                                                </div>
                                            </div>


                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Cost of Asset</label> {/* No asterisk for non-mandatory */}
                                                    <Controller
                                                        name="cost_of_asset"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <input
                                                                type="number"  // Simple number input
                                                                className="form-control"
                                                                placeholder="Enter Cost of Asset"
                                                                {...field}
                                                            />
                                                        )}
                                                    />
                                                    {errors.cost_of_asset && (
                                                        <ErrorLabel message={errors.cost_of_asset?.message} />
                                                    )}
                                                </div>
                                            </div>

                                            {/* HDD and RAM Inputs for Laptop or PC */}
                                            {(assetTypeValue === 'Laptop' || assetTypeValue === 'PC') && (
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">HDD&nbsp;
                                                            {
                                                                (
                                                                    assetTypeValue === 'Laptop' ||
                                                                    assetTypeValue === 'PC')
                                                                &&
                                                                <span className="danger">*</span>
                                                            }
                                                        </label>
                                                        <Controller
                                                            name="hdd"
                                                            control={control}
                                                            rules={{
                                                                required: (
                                                                    assetTypeValue === 'Laptop' ||
                                                                    assetTypeValue === 'PC') ?
                                                                    'HDD is required' : false,
                                                            }}
                                                            render={({ field }) => (
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter HDD Size"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                        {errors.hdd && <ErrorLabel message={errors.hdd.message} />}
                                                    </div>
                                                </div>
                                            )}

                                            {(assetTypeValue === 'Laptop' || assetTypeValue === 'PC') && (
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">RAM&nbsp;
                                                            {
                                                                (
                                                                    assetTypeValue === 'Laptop' ||
                                                                    assetTypeValue === 'PC')
                                                                &&
                                                                <span className="danger">*</span>
                                                            }
                                                        </label>
                                                        <Controller
                                                            name="ram"
                                                            control={control}
                                                            rules={{
                                                                required: (assetTypeValue === 'Others' ||
                                                                    assetTypeValue === 'Laptop' ||
                                                                    assetTypeValue === 'PC') ?
                                                                    'RAM is required' : false,

                                                            }}
                                                            render={({ field }) => (
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter RAM Size"
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                        {errors.ram && <ErrorLabel message={errors.ram.message} />}
                                                    </div>
                                                </div>
                                            )}

                                            {/* Remarks Input */}
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Remarks&nbsp;</label>
                                                    <Controller
                                                        name="remarks"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Remarks"
                                                                {...field}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none">
                                        <button
                                            id="create-inventory"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="create-inventory-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={() => {
                                                props.history.push('/tasks/inventory');
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}


const mapStateToProps = (state) => {
    var EditInventory = null;
    var EditAssetTypeResponse = null;
    var EditEmployeeList = null;


    if (
        state.EditInventoryReducer != null &&
        Object.keys(state.EditInventoryReducer).length > 0 &&
        state.CreateTaskReducer.constructor === Object
    ) {
        switch (state.EditInventoryReducer.tag) {
            case ACTION_EDIT_INVENTORY_SUCCESS:
                EditInventory = Object.assign({}, state.EditInventoryReducer.data);
                delete state.EditInventoryReducer;
                return { EditInventory };
            case ACTION_EDIT_ASSET_TYPE_SUCCESS:
                EditAssetTypeResponse = Object.assign({}, state.EditInventoryReducer.data);
                delete state.EditInventoryReducer;
                return { EditAssetTypeResponse };
            case ACTION_EDIT_EMPLOYEE_LIST_SUCCESS:
                EditEmployeeList = Object.assign({}, state.EditInventoryReducer.data);
                delete state.EditInventoryReducer;
                return { EditEmployeeList };
            default:
        }
    }

    return {
        EditInventory,
        EditAssetTypeResponse,
        EditEmployeeList
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionEditInventory, actionEditAssetTypeInventory, actionEditEmployeeListInventory }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(EditInventory))
































