import {
    ACTION_EDIT_INVENTORY_SUCCESS,
    ACTION_EDIT_ASSET_TYPE_SUCCESS,
    ACTION_EDIT_EMPLOYEE_LIST_SUCCESS
} from "./action";
import createReducer from "../../../../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const EditInventoryReducer = createReducer(INITIAL_STATE, {

    [ACTION_EDIT_INVENTORY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_INVENTORY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_ASSET_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_ASSET_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EDIT_EMPLOYEE_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EDIT_EMPLOYEE_LIST_SUCCESS,
                loading: false,
            }
        );
    }
});

export default EditInventoryReducer;
