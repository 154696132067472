import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import ScreenTitle from '../../../../../../component/screen_title';
import InventoryHistory from '../../../../../../component/widgets/inventory_history';

const InventoryView = (props) => {

    const [inventoryDetails, setInventoryDetails] = useState(null);

    const initialRender = () => {
        if (props?.location?.state?.detail != null) {
            setInventoryDetails(props?.location?.state?.detail);
        }
    }
    useEffect(initialRender, [props?.location])

    const getFullNames = (inventory) => {
        // return inventory?.profile[0].full_name;
        if (Array.isArray(inventory) && inventory.length > 0) {
            // Return the full_name of the most recent profile in inventory_history
            return inventory[0]?.profile?.full_name || '';
        }
        // Return an empty string if inventory is empty or undefined
        return '';
    };



    return (
        <>
            <ScreenTitle title="Inventory Details" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row mt-2">
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Status</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        inventoryDetails?.status || '-'
                                    }
                                </h4>
                            </div>
                            {inventoryDetails?.status === 'Assigned' && (
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Employee Name</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {
                                            getFullNames(inventoryDetails?.inventory_history)
                                        }
                                    </h4>
                                </div>
                            )}
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Asset Type</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        inventoryDetails?.asset_type || '-'
                                    }
                                </h4>
                            </div>
                            {inventoryDetails?.asset_type === 'Others' && (
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">Discription</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {
                                            inventoryDetails?.asset_type_description || '-'
                                        }
                                    </h4>
                                </div>
                            )}
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Brand</h6>
                                <h4 className="font-medium-3 text-bold-700 text-justify">
                                    {
                                        inventoryDetails?.brand || '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Model Name</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        inventoryDetails?.model || '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Serial Number</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        inventoryDetails?.serial_no || '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Date of Purchase</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        inventoryDetails?.date_of_purchase || '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Warranty Expiry Date</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        inventoryDetails?.warranty_expiry_date || '-'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Cost of Asset</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        inventoryDetails?.cost_of_asset || '-'
                                    }
                                </h4>
                            </div>
                            {(inventoryDetails?.asset_type === 'Laptop' || inventoryDetails?.asset_type === 'PC') && (
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">HDD</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {inventoryDetails?.hdd || '-'}
                                    </h4>
                                </div>
                            )}
                            {(inventoryDetails?.asset_type === 'Laptop' || inventoryDetails?.asset_type === 'PC') && (
                                <div className="col-12 col-md-6 col-lg-6 mb-1">
                                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">RAM</h6>
                                    <h4 className="font-medium-1 text-bold-700 text-justify">
                                        {inventoryDetails?.ram || '-'}
                                    </h4>
                                </div>
                            )}

                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                                <h6 className="font-small-2 text-uppercase blue-grey darken-1">Remark</h6>
                                <h4 className="font-medium-1 text-bold-700 text-justify">
                                    {
                                        inventoryDetails?.remarks || '-'
                                    }
                                </h4>
                            </div>
                        </div>

                        {
                            inventoryDetails != null ?
                                <InventoryHistory
                                    history={inventoryDetails?.inventory_history}
                                />
                                :
                                <></>
                        }
                        <div className="form-actions border-0 mt-0 pt-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    props.history.goBack();
                                }}
                            >Back</button>
                        </div>
                    </div>

                </div>

            </section>
        </>
    )
}

export default InventoryView