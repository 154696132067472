
import React, { useEffect, useState } from "react"
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { UNAUTHORIZED } from '../../../../../constants';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ErrorLabel from "../../../../../component/form/error_label";
import { bindActionCreators } from 'redux';
import { useForm, Controller } from "react-hook-form";
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import Chart from "react-apexcharts";
import $ from "jquery";
import ScreenTitle from '../../../../../component/screen_title';
import { actionProposalList, ACTION_PROPOSAL_LIST_SUCCESS } from "./action";
import { actionProbabilityView, ACTION_MASTER_PROBABILITY_PROPOSAL_VIEW_SUCCESS } from "./action";
import { actionProposalRMView, ACTION_MASTER_ASSIGN_RM_VIEW_SUCCESS } from "./action";
import { actionProposalPolicyTypeView, ACTION_CLAIMS_POLICY_TYPE_VIEW_SUCCESS } from "./action";
import moment from "moment";
import ArrayStore from 'devextreme/data/array_store';
import { TagBox } from 'devextreme-react/tag-box';
import ViewProposalDetails from "./component/proposaldetails";
import { FiEye } from "react-icons/fi";

const ProposalDashboardView = (props) => {
    const { match } = props
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
    const [proposalList, setProposalList] = useState([]);
    const [probabilityGroupDropdown, setProbabilityGroupDropdown] = useState(null);
    const [proposalRm, setProposalRM] = useState(null);
    const [proposalPolicy, setProposalPolicy] = useState(null);
    const [policyType, setPolicyType] = useState([])
    const [proposalRMType, setProposalRMType] = useState([]);
    const [probabiltyType, setProbabilityType] = useState([]);
    const [ProposalDetails, setProposalDetails] = useState([]);
    const [proposalSeries, setProposalSeries] = useState([]);
    const [showProposalView, setShowProposalView] = useState(false)
    const [proposalListSelectedData, setProposalListSelectedData] = useState(null);
    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionProbabilityView();
        props.actionProposalRMView({ user_id: props.auth.getUser().user_id });
        props.actionProposalPolicyTypeView();


    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (props != null && props.location.state != null) {
            setValue('policy_type', props.location.state.typePolicy);
            setValue('rm_name_id', props.location.state.rmNameId);
            setValue('probability_value', props.location.state.probabilityValue);
            props.actionProposalList({
                "rm_name_id": props.location.state.rmNameId,
                "policy_type": props.location.state.typePolicy,
                "probability_value": props.location.state.probabilityValue,
                "days_count": props.location.state.daysCount,
                "title": props.location.state.title
            });
        }
    }, [props.location.state])

    const onMasterProbabilityViewUpdate = () => {
        const { ProbabilityDropdown } = props;
        $("#proposal-reset").prop("disabled", false);
        var ProbabilityDropdownResponse = Object.assign({}, ProbabilityDropdown);
        if (ProbabilityDropdownResponse.result === true) {
            $("#proposal-reset-loader").addClass("hidden");
            setProbabilityGroupDropdown(ProbabilityDropdownResponse.response);
        } else {
            $("#proposal-reset-loader").addClass("hidden");
            switch (ProbabilityDropdownResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                default:
            }
        }
    }
    useEffect(onMasterProbabilityViewUpdate, [props.ProbabilityDropdown]);

    const onMasterProposalRMViewUpdate = () => {
        const { ProposalRM } = props;
        $("#proposal-reset").prop("disabled", false);
        var ProposalRMResponse = Object.assign({}, ProposalRM);
        if (ProposalRMResponse.result === true) {
            $("#proposal-reset-loader").addClass("hidden");
            var arrAssignRM = Object.assign([], ProposalRMResponse.response);
            var user = {
                user_id: 0,
                full_name: "Open Lead"
            };

            arrAssignRM.unshift(user);

            setProposalRM(arrAssignRM);
        } else {
            $("#proposal-reset-loader").addClass("hidden");
            switch (ProposalRMResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                default:
            }
        }
    }
    useEffect(onMasterProposalRMViewUpdate, [props.ProposalRM]);

    const onProposalPolicyTypeViewUpdate = () => {
        const { ProposalPolicyType } = props;
        $("#proposal-reset").prop("disabled", false);
        if (ProposalPolicyType != null) {
            var ProposalPolicyTypeResponse = Object.assign({}, ProposalPolicyType);
            if (ProposalPolicyTypeResponse.result === true) {
                $("#proposal-reset-loader").addClass("hidden");
                setProposalPolicy(ProposalPolicyTypeResponse.response);
            } else {
                $("#proposal-reset-loader").addClass("hidden");
                switch (ProposalPolicyTypeResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                    default:
                }
            }
        }
    }
    useEffect(onProposalPolicyTypeViewUpdate, [props.ProposalPolicyType]);

    const onProposalListUpdate = () => {
        const { ProposalList } = props
        var ProposalListResponse = Object.assign({}, ProposalList);
        $("#attendance-report").prop("disabled", false);
        if (ProposalListResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            $("#attendance-report-loader").addClass("hidden");
            setProposalDetails(ProposalListResponse.response);
            var proposalSeriesValues = [];
            ProposalListResponse.response.proposal_details?.policy_type_series.forEach(element => {
                proposalSeriesValues.push(element);
            });
            if (proposalSeriesValues.length > 0) {
                setProposalSeries(proposalSeriesValues)
            }
        } else {
            $("#attendance-report-loader").addClass("hidden");
            $("#progress-loader").addClass("hidden");
            switch (ProposalListResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onProposalListUpdate, [props.ProposalList])

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "rm_name", caption: "RM Name" },
        { dataField: "client_name", caption: "Client Name" },
        { dataField: "policy_type", caption: "Department" },
        { dataField: "brokerage_amount", caption: "Brokerage amt." },
        { dataField: "broaker_name", caption: "Broker name" },
        { dataField: "expected_premium", caption: "Expeted premium" },
        { dataField: "renewal_date", caption: "Renewal Date" },
        { dataField: "probability", caption: "probability % " },
    ]);

    const renderRenewalDate = (cell) => {
        return <div>{moment(cell.data.renewal_date).format("DD/MM/YYYY")}</div>

    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            <Button
                                render={(params) => (
                                    <button
                                        className="btn btn-icon btn-sm"
                                        onClick={() => {
                                            setShowProposalView(true);
                                            setProposalListSelectedData(params.data)
                                        }}
                                    >
                                        <FiEye size={16} className="primary" />
                                    </button>
                                )}
                            />
                        }
                    </Column>
                );
            } else if (objColumn.dataField === "renewal_date") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderRenewalDate}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                );
            }

        }

        );
        return arrColumns;
    };
    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0">Opportunity Details</h5>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                    <div className="flex-column">
                        <div className="d-flex flex-row w-100 justify-content-start justify-content-lg-end">
                            <button
                                className="btn btn-secondary btn-sm mr-1 mb-1 mb-md-0 mb-lg-0 shadow round"
                                onClick={() => {
                                    props.history.goBack();
                                }}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <form noValidate autoComplete="Off">
                        <div className="form-body">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6">
                                    <h4 className="mb-0">Filters</h4>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="label-control">RM Name&nbsp;</label>
                                        <Controller
                                            name="rm_name_id"
                                            control={control}
                                            render={({ field }) =>
                                                <TagBox
                                                    {...field}
                                                    dataSource={
                                                        new ArrayStore({
                                                            data: proposalRm,
                                                            key: 'user_id'
                                                        })
                                                    }
                                                    dropDownOptions={{
                                                        onInitialized: () => {
                                                            console.log('initial')
                                                        },
                                                        onContentReady: () => {
                                                            console.log('content ready')
                                                        },
                                                        onShowing: () => {
                                                            console.log('showing')
                                                        },
                                                        onShown: () => {
                                                            console.log('shown')
                                                        }
                                                    }}
                                                    deferRendering={true}
                                                    displayExpr="full_name"
                                                    valueExpr="user_id"
                                                    placeholder="Select RM Name"
                                                    searchEnabled={true}
                                                    searchMode="contains"
                                                    maxDisplayedTags={2}
                                                    showMultiTagOnly={false}
                                                    className="form-control"
                                                    stylingMode="underlined"
                                                    value={field.value}
                                                    onValueChanged={({ value }) => {
                                                        if (value != null || value !== undefined) {
                                                            setProposalRMType(value);
                                                            field.onChange(value);
                                                        }
                                                    }}
                                                    showClearButton={true}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="label-control">Type of Department&nbsp;</label>
                                        <Controller
                                            name="policy_type"
                                            control={control}
                                            render={({ field }) =>
                                                <TagBox
                                                    {...field}
                                                    dataSource={
                                                        new ArrayStore({
                                                            data: proposalPolicy,
                                                            key: 'id'
                                                        })
                                                    }
                                                    dropDownOptions={{
                                                        onInitialized: () => {
                                                            console.log('initial')
                                                        },
                                                        onContentReady: () => {
                                                            console.log('content ready')
                                                        },
                                                        onShowing: () => {
                                                            console.log('showing')
                                                        },
                                                        onShown: () => {
                                                            console.log('shown')
                                                        }
                                                    }}
                                                    deferRendering={true}
                                                    displayExpr="name"
                                                    valueExpr="id"
                                                    placeholder="Select Type of Department"
                                                    searchEnabled={true}
                                                    searchMode="contains"
                                                    maxDisplayedTags={2}
                                                    showMultiTagOnly={false}
                                                    className="form-control"
                                                    stylingMode="underlined"
                                                    value={field.value}
                                                    onValueChanged={({ value }) => {
                                                        if (value != null || value !== undefined) {
                                                            setPolicyType(value);
                                                            field.onChange(value);
                                                        }
                                                    }}
                                                    showClearButton={true}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="label-control">Probability&nbsp;</label>
                                        <Controller
                                            name="probability_value"
                                            control={control}
                                            render={({ field }) =>
                                                <TagBox
                                                    {...field}
                                                    dataSource={
                                                        new ArrayStore({
                                                            data: probabilityGroupDropdown,
                                                            key: 'value'
                                                        })
                                                    }
                                                    dropDownOptions={{
                                                        onInitialized: () => {
                                                            console.log('initial')
                                                        },
                                                        onContentReady: () => {
                                                            console.log('content ready')
                                                        },
                                                        onShowing: () => {
                                                            console.log('showing')
                                                        },
                                                        onShown: () => {
                                                            console.log('shown')
                                                        }
                                                    }}
                                                    deferRendering={true}
                                                    displayExpr="title"
                                                    valueExpr="value"
                                                    placeholder="Select Probability"
                                                    searchEnabled={true}
                                                    searchMode="contains"
                                                    maxDisplayedTags={2}
                                                    showMultiTagOnly={false}
                                                    className="form-control"
                                                    stylingMode="underlined"
                                                    value={field.value}
                                                    onValueChanged={({ value }) => {
                                                        if (value != null || value !== undefined) {
                                                            setProbabilityType(value);
                                                            field.onChange(value);
                                                        }
                                                    }}
                                                    showClearButton={true}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-4 col-md-2 col-lg-2 col-xl-1">
                                    <div className="form-actions mt-1 border-0 float-none">
                                        <button
                                            id="attendance-report"
                                            type='submit'
                                            className="btn btn-primary mr-8  btn-md w-80 shadow round"
                                            onClick={(event) => {
                                                event.preventDefault()
                                                $("#attendance-report-loader").removeClass("hidden");
                                                $("#attendance-report").prop("disabled", true);
                                                $("#progress-loader").removeClass("hidden");
                                                props.actionProposalList({
                                                    "rm_name_id": proposalRMType,
                                                    "policy_type": policyType,
                                                    "probability_value": probabiltyType,
                                                    "days_count": props.location.state.daysCount,
                                                    "title": props.location.state.title
                                                })
                                            }}
                                        >
                                            <div className="d-flex align-items-center">
                                                &nbsp;Apply
                                                <span id="attendance-report-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-4 col-md-2 col-lg-2 col-xl-1">
                                    <div className="form-actions mt-1 border-0 float-none">
                                        <button
                                            id="proposal-reset"
                                            type='button'
                                            className="btn btn-primary  btn-md w-80 shadow round"
                                            onClick={(event) => {
                                                $("#proposal-reset-loader").removeClass("hidden");
                                                $("#proposal-reset").prop("disabled", true);
                                                $("#progress-loader").removeClass("hidden");
                                                setProbabilityGroupDropdown([]);
                                                setProposalRM([]);
                                                setProposalPolicy([])
                                                setProbabilityType(null)
                                                setPolicyType(null)
                                                setProposalRMType(null)
                                                setValue('policy_type', null);
                                                setValue('rm_name_id', null);
                                                setValue('probability_value', null);
                                                props.actionProposalList({
                                                    "rm_name_id": [],
                                                    "policy_type": [],
                                                    "probability_value": [],
                                                    "days_count": props.location.state.daysCount,
                                                    "title": props.location.state.title
                                                });
                                                props.actionProbabilityView();
                                                props.actionProposalRMView({ user_id: props.auth.getUser().user_id });
                                                props.actionProposalPolicyTypeView();
                                            }
                                            }
                                        >
                                            <div className="d-flex align-items-center">
                                                &nbsp;Reset
                                                <span id="proposal-reset-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {
                Number(ProposalDetails?.proposal_details?.proposals_count) > 0 ?
                    <>
                        <div className='row'>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                <div className="card h-100 shadow mb-0">
                                    <div className="card-header border-bottom-secondary-light px-1 py-1">
                                        <div className="d-flex flex-row justify-content-between ">
                                            <div className="flex-column">
                                                <h4 className="font-medium-1 text-black text-bold-600 mb-0 text-md-center ">
                                                    {ProposalDetails?.proposal_details?.title}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-12 col-md-4 col-sm-12 col-lg-4 col-xl-3 font-weight-bold mt-md-5 text-center text-md-right text-right me-0 pr-md-0 py-1 h1">
                                            {ProposalDetails?.proposal_details?.proposals_count}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 statistics-chart mt-md-2 mb-1 mb-xl-0">
                                            <Chart
                                                type="donut"
                                                height={400}
                                                width={300}
                                                options={{
                                                    dataLabels: {
                                                        enabled: true,
                                                        style: {
                                                            fontSize: "10px",
                                                            fontWeight: 400,
                                                        },
                                                        textAnchor: "middle",
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            expandOnClick: true,
                                                            customScale: 1,
                                                            donut: {
                                                                size: "60%",
                                                            },
                                                        },
                                                    },
                                                    legend: {
                                                        fontSize: "10px",
                                                        fontWeight: 600,
                                                        position: "right",
                                                        horizontalAlign: 'center',
                                                        markers: {
                                                            width: 5,
                                                            height: 5,
                                                            radius: 5,
                                                        },
                                                        itemMargin: {
                                                            vertical: 0,
                                                        },
                                                    },
                                                    labels: (ProposalDetails?.proposal_details?.policy_type_label)
                                                }}
                                                series={proposalSeries}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-6 mt-2 mt-xl-0">
                                <div className="card dashboard shadow h-100 mb-0">
                                    <div className="card-header border-bottom px-1 py-1">
                                        <div className="d-flex flex-row justify-content-between align-items-center">
                                            <div className="flex-column">
                                                <h4 className="font-medium-1 text-black text-md-center text-bold-600 mb-0">
                                                    Total Summary
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-12 col-md-12 col-lg-12 py-1">
                                            <h4 className='text-bold-600 px-1 text-md-center font-medium-1'>Total Expeted Brokerage</h4>
                                            <h2 className='text-bold-600 text-md-center px-1'>{ProposalDetails?.proposal_details?.total_brokerage.toFixed(2)}<small class="text-bold-600 font-medium-1 ml-half">{ProposalDetails?.proposal_details?.total_brokerage_unit}</small></h2>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-12 col-md-12 col-lg-12 py-1">
                                            <h4 className='text-bold-600 px-1 text-md-center font-medium-1'>Total Expeted Premium</h4>
                                            <h2 className='text-bold-600 text-md-center px-1'>{ProposalDetails?.proposal_details?.total_premium.toFixed(2)}<small class="text-bold-600 font-medium-1 ml-half">{ProposalDetails?.proposal_details?.total_premium_unit}</small></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <p className=" text-md-center font-medium-3 text-bold-600 px-1 mt-5 pt-1 mx-auto">No Opportunity details found</p>
            }
            {
                ProposalDetails?.proposal_details?.policy_list != null && ProposalDetails?.proposal_details?.policy_list.length != 0 ?
                    <div className="card my-2">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6">
                                    <h3 className="content-header-title text-capitalize text-bold-800 text-black mb-0 pb-0">
                                        {ProposalDetails?.proposal_details?.title + " "} {ProposalDetails?.proposal_details?.proposals_count != 0 ? `(${ProposalDetails?.proposal_details?.proposals_count})` : ""}
                                    </h3>
                                </div>
                                <div className="col-12 col-lg-12 col-md-12">
                                    <DataGrid
                                        dataSource={ProposalDetails?.proposal_details?.policy_list}
                                        columnHidingEnabled={false}
                                        showBorders={true}
                                        export={{
                                            fileName: "Opportunity dashboard",
                                            enabled: true,
                                            allowExportSelectedData: false,
                                            excelFilterEnabled: true,
                                        }}
                                        onToolbarPreparing={(e) => {
                                            e.toolbarOptions.items.unshift({
                                                location: 'before',
                                            })
                                        }}
                                    >
                                        <SearchPanel visible={true} />
                                        <FilterRow visible={true} />
                                        <HeaderFilter visible={true} />
                                        {renderColumns()}
                                        <Pager
                                            visible={true}
                                            showPageSizeSelector={false}
                                            showNavigationButtons={true}
                                            allowedPageSizes={[4, 6, 10]}
                                        />
                                        <Paging
                                            defaultPageIndex={0}
                                            defaultPageSize={10}
                                            enabled={true}
                                        />
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
            }
            <ViewProposalDetails
                show={showProposalView}
                handleClose={() => {
                    setShowProposalView(false);
                }}
                proposalListSelectedData={proposalListSelectedData}
            />
        </>
    )
}
const mapStateToProps = (state) => {
    var ProposalList = null;
    var ProposalRM = null;
    var ProposalPolicyType = null;
    var ProbabilityDropdown = null;
    if (
        state.ProposalListReducer != null &&
        Object.keys(state.ProposalListReducer).length > 0 &&
        state.ProposalListReducer.constructor === Object
    ) {
        switch (state.ProposalListReducer.tag) {
            case ACTION_PROPOSAL_LIST_SUCCESS:
                ProposalList = Object.assign({}, state.ProposalListReducer.data);
                delete state.ProposalListReducer;
                return { ProposalList };
            case ACTION_MASTER_PROBABILITY_PROPOSAL_VIEW_SUCCESS:
                ProbabilityDropdown = Object.assign({}, state.ProposalListReducer.data);
                delete state.ProposalListReducer;
                return { ProbabilityDropdown };
            case ACTION_MASTER_ASSIGN_RM_VIEW_SUCCESS:
                ProposalRM = Object.assign({}, state.ProposalListReducer.data);
                delete state.ProposalListReducer;
                return { ProposalRM };
            case ACTION_CLAIMS_POLICY_TYPE_VIEW_SUCCESS:
                ProposalPolicyType = Object.assign({}, state.ProposalListReducer.data);
                delete state.ProposalListReducer;
                return { ProposalPolicyType };
            default:

        }
    }
    return {
        ProposalList,
        ProposalRM,
        ProposalPolicyType,
        ProbabilityDropdown,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionProposalList, actionProbabilityView, actionProposalRMView, actionProposalPolicyTypeView }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ProposalDashboardView))
