
import React, { useEffect, useState } from "react"
import { UNAUTHORIZED } from "../../../../../constants";
import { useForm, Controller } from "react-hook-form";
import ArrayStore from 'devextreme/data/array_store';
import { TagBox } from 'devextreme-react/tag-box';
import Chart from "react-apexcharts";
import $ from "jquery";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withAuth } from "react-auth-guard";
import ScreenTitle from '../../../../../component/screen_title';
import { actionProbability, actionProposalRM, actionProposalPolicyType, ACTION_MASTER_PROBABILITY_PROPOSAL_SUCCESS, ACTION_MASTER_ASSIGN_RM_SUCCESS, ACTION_CLAIMS_POLICY_TYPE_SUCCESS } from './action';
import { actionProposalDashboard, ACTION_PROPOSAL_DASHBOARD_VIEW_SUCCESS } from './action';
import { verifyPermission } from '../../../../../utils';

const ProposalDashboard = (props) => {

    const { match } = props
    const { control, formState: { errors }, setValue } = useForm();
    const [policy, setPolicy] = useState([]);
    const [probability, setProbability] = useState([0]);
    const [rmName, setRmName] = useState([]);
    const [probabilityGroupDropdown, setProbabilityGroupDropdown] = useState(null);
    const [proposalRm, setProposalRM] = useState(null);
    const [proposalRmName, setProposalRmName] = useState(null);
    const [proposalPolicy, setProposalPolicy] = useState(null);
    const [policyType, setPolicyType] = useState([])
    const [proposalRMType, setProposalRMType] = useState([]);
    const [probabiltyType, setProbabilityType] = useState([]);
    const [objProposalDetails, setObjProposalDetails] = useState(null);
    const [proposalMappedCount, setProposalMappedCount] = useState(null);
    const [showDetails, setShowDetails] = useState(false);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionProposalRM({ user_id: props.auth.getUser().user_id });
    }
    useEffect(initialRender, [props.show]);

    const onMasterProposalRMUpdate = () => {
        const { ProposalRM } = props;
        var ProposalRMResponse = Object.assign({}, ProposalRM);
        $("#proposal-reset").prop("disabled", false);
        if (ProposalRMResponse.result === true) {
            $("#proposal-reset-loader").addClass("hidden");
            setProposalRmName(ProposalRMResponse.response);
            var arrAssignRM = Object.assign([], ProposalRMResponse.response);
            var user = {
                user_id: 0,
                full_name: "Open Lead"
            };
            arrAssignRM.unshift(user);
            setProposalRM(arrAssignRM);
            props.actionProposalPolicyType();
        } else {
            $("#proposal-reset-loader").addClass("hidden");
            switch (ProposalRMResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                default:
            }
        }
    }
    useEffect(onMasterProposalRMUpdate, [props.ProposalRM]);

    useEffect(() => {
        if (proposalRmName != null && proposalRmName.length > 0 && showDetails === false) {
            setValue('rm_name_id', [proposalRmName[0].user_id])
            setRmName([proposalRmName[0].user_id]);
        }
    }, [proposalRmName])

    const onProposalPolicyTypeUpdate = () => {
        const { ProposalPolicyType } = props;
        if (ProposalPolicyType != null) {
            var ProposalPolicyTypeResponse = Object.assign({}, ProposalPolicyType);
            $("#proposal-reset").prop("disabled", false);
            if (ProposalPolicyTypeResponse.result === true) {
                $("#proposal-reset-loader").addClass("hidden");
                setProposalPolicy(ProposalPolicyTypeResponse.response);
                props.actionProbability();
            } else {
                $("#proposal-reset-loader").addClass("hidden");
                switch (ProposalPolicyTypeResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                    default:
                }
            }
        }
    }
    useEffect(onProposalPolicyTypeUpdate, [props.ProposalPolicyType]);

    useEffect(() => {
        if (proposalPolicy != null && proposalPolicy.length > 0 && showDetails === false) {
            setValue('policy_type', [proposalPolicy[0].id])
            setPolicy([proposalPolicy[0].id]);
        }
    }, [proposalPolicy])

    const onMasterProbabilityGroupUpdate = () => {
        const { ProbabilityDropdown } = props;
        $("#proposal-reset").prop("disabled", false);
        var ProbabilityDropdownResponse = Object.assign({}, ProbabilityDropdown);
        if (ProbabilityDropdownResponse.result === true) {
            $("#proposal-reset-loader").addClass("hidden");
            setProbabilityGroupDropdown(ProbabilityDropdownResponse.response);
        } else {
            $("#proposal-reset-loader").addClass("hidden");
            switch (ProbabilityDropdownResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                default:
            }
        }
    }
    useEffect(onMasterProbabilityGroupUpdate, [props.ProbabilityDropdown]);

    useEffect(() => {
        if (probabilityGroupDropdown != null && probabilityGroupDropdown.length > 0 && showDetails === false) {
            setValue('probability_value', [probabilityGroupDropdown[0].value])
            setProbability([probabilityGroupDropdown[0].value]);
        }
    }, [probabilityGroupDropdown])

    useEffect(() => {
        if (probabilityGroupDropdown != null && probabilityGroupDropdown.length > 0 && showDetails === false) {
            props.actionProposalDashboard({
                "rm_name_id": rmName,
                "policy_type": policy,
                "probability_value": probability
            });
        }
    }, [probabilityGroupDropdown])

    const onProposalDashboardUpdate = () => {
        const { ProposalView } = props;
        if (ProposalView != null) {
            var ProposalViewResponse = Object.assign({}, ProposalView);
            $("#propsal-create").prop("disabled", false);
            if (ProposalViewResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                $("#proposal-dashboard-loader").addClass("hidden");
                setProposalMappedCount(ProposalViewResponse.response.summary.reduce((count, currentValue) => count + currentValue.proposals_count, 0))
                setObjProposalDetails(ProposalViewResponse.response);
            } else {
                $("#proposal-dashboard-loader").addClass("hidden");
                $("#progress-loader").addClass("hidden");
                switch (ProposalViewResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onProposalDashboardUpdate, [props.ProposalView]);

    return (
        <>
            <ScreenTitle title="Opportunity Dashboard" />
            <section>
                <div className="pb-2">
                    <div className="card">
                        <div className="card-body">
                            <form noValidate autoComplete="Off" >
                                <div className="form-body">
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <h4 className="mb-0">Filters</h4>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 col-md-4 col-lg-4 col-xl-3">
                                            <div className="form-group">
                                                <label className="label-control">RM Name&nbsp;</label>
                                                <Controller
                                                    name="rm_name_id"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <TagBox
                                                            {...field}
                                                            dataSource={
                                                                new ArrayStore({
                                                                    data: proposalRm,
                                                                    key: 'user_id'
                                                                })
                                                            }
                                                            dropDownOptions={{
                                                                onInitialized: () => {
                                                                    ('initial')
                                                                },
                                                                onContentReady: () => {
                                                                    ('content ready')
                                                                },
                                                                onShowing: () => {
                                                                    ('showing')
                                                                },
                                                                onShown: () => {
                                                                    ('shown')
                                                                }
                                                            }}
                                                            deferRendering={true}
                                                            displayExpr="full_name"
                                                            valueExpr="user_id"
                                                            placeholder="Select RM Name"
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            maxDisplayedTags={2}
                                                            showMultiTagOnly={false}
                                                            className="form-control"
                                                            stylingMode="underlined"
                                                            value={field.value}
                                                            onValueChanged={({ value }) => {
                                                                if (value != null || value !== undefined) {
                                                                    setProposalRMType(value);
                                                                    field.onChange(value);
                                                                }
                                                            }}
                                                            showClearButton={true}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 col-xl-3">
                                            <div className="form-group">
                                                <label className="label-control">Type of Department&nbsp;</label>
                                                <Controller
                                                    name="policy_type"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <TagBox
                                                            {...field}
                                                            id="policy_type"
                                                            name="policy_type"
                                                            dataSource={
                                                                new ArrayStore({
                                                                    data: proposalPolicy,
                                                                    key: 'id'
                                                                })
                                                            }
                                                            dropDownOptions={{
                                                                onInitialized: () => {
                                                                    ('initial')
                                                                },
                                                                onContentReady: () => {
                                                                    ('content ready')
                                                                },
                                                                onShowing: () => {
                                                                    ('showing')
                                                                },
                                                                onShown: () => {
                                                                    ('shown')
                                                                }
                                                            }}
                                                            deferRendering={true}
                                                            displayExpr="name"
                                                            valueExpr="id"
                                                            placeholder="Select Type of Department"
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            maxDisplayedTags={2}
                                                            showMultiTagOnly={false}
                                                            className="form-control"
                                                            stylingMode="underlined"
                                                            value={field.value}
                                                            onValueChanged={({ value }) => {
                                                                if (value != null || value !== undefined) {
                                                                    setPolicyType(value);
                                                                    field.onChange(value);
                                                                }
                                                            }}
                                                            showClearButton={true}
                                                        />
                                                    }
                                                />

                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 col-xl-3">
                                            <div className="form-group">
                                                <label className="label-control">Probability&nbsp;</label>
                                                <Controller
                                                    name="probability_value"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <TagBox
                                                            {...field}
                                                            dataSource={
                                                                new ArrayStore({
                                                                    data: probabilityGroupDropdown,
                                                                    key: 'value'
                                                                })
                                                            }
                                                            dropDownOptions={{
                                                                onInitialized: () => {
                                                                    ('initial')
                                                                },
                                                                onContentReady: () => {
                                                                    ('content ready')
                                                                },
                                                                onShowing: () => {
                                                                    ('showing')
                                                                },
                                                                onShown: () => {
                                                                    ('shown')
                                                                }
                                                            }}
                                                            deferRendering={true}
                                                            displayExpr="title"
                                                            valueExpr="value"
                                                            placeholder="Select Probability"
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            maxDisplayedTags={2}
                                                            showMultiTagOnly={false}
                                                            className="form-control"
                                                            stylingMode="underlined"
                                                            value={field.value}
                                                            onValueChanged={({ value }) => {
                                                                if (value != null || value !== undefined) {
                                                                    setProbabilityType(value);
                                                                    field.onChange(value);
                                                                }
                                                            }}
                                                            showClearButton={true}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="col-4 col-md-2 col-lg-2 col-xl-1">
                                            <div className="form-actions mt-1 border-0 float-none">
                                                <button
                                                    id="proposal-dashboard"
                                                    type='submit'
                                                    className="btn btn-primary btn-md w-80 shadow round"
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        $("#proposal-dashboard-loader").removeClass("hidden");
                                                        $("#propsal-create").prop("disabled", true);
                                                        $("#progress-loader").removeClass("hidden");
                                                        props.actionProposalDashboard({
                                                            "rm_name_id": proposalRMType,
                                                            "policy_type": policyType,
                                                            "probability_value": probabiltyType

                                                        })
                                                    }}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        &nbsp;Apply
                                                        <span id="proposal-dashboard-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-2 col-lg-2 col-xl-1">
                                            <div className="form-actions mt-1 border-0 float-none">
                                                <button
                                                    id="proposal-reset"
                                                    type='button'
                                                    className="btn btn-primary btn-md w-80 shadow round"
                                                    onClick={(event) => {
                                                        $("#proposal-reset-loader").removeClass("hidden");
                                                        $("#proposal-reset").prop("disabled", true);
                                                        setProbabilityGroupDropdown([]);
                                                        setProposalRM([]);
                                                        setProposalPolicy([])
                                                        setProbabilityType(null)
                                                        setPolicyType(null)
                                                        setProposalRMType(null)
                                                        setRmName([]);
                                                        setPolicy([]);
                                                        setProbability([]);
                                                        $("#progress-loader").removeClass("hidden");
                                                        setValue('policy_type', null);
                                                        setValue('rm_name_id', null);
                                                        setValue('probability_value', null);
                                                        setShowDetails(true);
                                                        props.actionProposalDashboard({});
                                                        props.actionProbability();
                                                        props.actionProposalRM({ user_id: props.auth.getUser().user_id });
                                                        props.actionProposalPolicyType();
                                                    }
                                                    }
                                                >
                                                    <div className="d-flex align-items-center">
                                                        &nbsp;Reset
                                                        <span id="proposal-reset-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                    </div>
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row">
                        {
                            objProposalDetails != null && objProposalDetails.summary != null && objProposalDetails.summary.length != 0 && proposalMappedCount != 0 ?
                                objProposalDetails.summary.map((item, index) => (
                                    <>
                                        {Number(item.proposals_count) > 0 ?
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-4 mt-2">
                                                <div className="card shadow h-100">
                                                    <div className="card-header border-bottom-secondary-light px-1 py-1">
                                                        <div className="font-medium-1 text-black text-bold-600 mb-0">
                                                            <div className="d-flex flex-row justify-content-between align-items-center">
                                                                <div className="flex-column">
                                                                    <h4 className="font-medium-1 text-black text-bold-600 mb-0">
                                                                        {item.title}
                                                                    </h4>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="card-body px-1 py-0">
                                                        <div className='row'>
                                                            <h1 className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-2 font-weight-bold mt-xl-5 text-center text-xl-right  me-0 pr-md-0 h2 py-1">
                                                                {item.proposals_count}
                                                            </h1>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 statistics-chart mx-0 px-0 mt-2">
                                                                <Chart
                                                                    type="donut"
                                                                    height={400}
                                                                    width={300}
                                                                    options={{
                                                                        dataLabels: {
                                                                            enabled: true,
                                                                            style: {
                                                                                fontSize: "10px",
                                                                                fontWeight: 400,
                                                                            },
                                                                            textAnchor: "middle",
                                                                        },
                                                                        plotOptions: {
                                                                            pie: {
                                                                                expandOnClick: true,
                                                                                customScale: 1,
                                                                                donut: {
                                                                                    size: "60%",
                                                                                },
                                                                            },
                                                                        },
                                                                        legend: {
                                                                            fontSize: "10px",
                                                                            fontWeight: 600,
                                                                            position: "right",
                                                                            horizontalAlign: 'center',
                                                                            markers: {
                                                                                width: 5,
                                                                                height: 5,
                                                                                radius: 5,
                                                                            },
                                                                            itemMargin: {
                                                                                vertical: 0,
                                                                            },
                                                                        },
                                                                        labels: item.policy_type_label,
                                                                    }}
                                                                    series={item.policy_type_series}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        verifyPermission("PROPOSAL_VIEW_DETAILS") ?
                                                            <div className='card-footer py-1'>
                                                                <a className='float-right primary' href=""
                                                                    onClick={() => {
                                                                        props.history.push({ pathname: `${match.url}/view`, state: { daysCount: Number(item.days_count), title: item.title, typePolicy: policyType != null && policyType != '' ? policyType : policy, rmNameId: proposalRMType != null && proposalRMType != '' ? proposalRMType : rmName, probabilityValue: probabiltyType != null && probabiltyType != '' ? probabiltyType : probability } })
                                                                    }}
                                                                ><u>View Details</u></a>

                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </>
                                ))
                                :
                                <p className=" text-md-center font-medium-3 text-bold-600 px-1 mt-5 pt-1 mx-auto">No Opportunity to show</p>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var ProbabilityDropdown = null;
    var ProposalRM = null;
    var ProposalPolicyType = null;
    var ProposalView = null;
    if (
        state.ProbabilityReducer != null &&
        Object.keys(state.ProbabilityReducer).length > 0 &&
        state.ProbabilityReducer.constructor === Object
    ) {
        switch (state.ProbabilityReducer.tag) {
            case ACTION_MASTER_PROBABILITY_PROPOSAL_SUCCESS:
                ProbabilityDropdown = Object.assign({}, state.ProbabilityReducer.data);
                delete state.ProbabilityReducer;
                return { ProbabilityDropdown };
            case ACTION_MASTER_ASSIGN_RM_SUCCESS:
                ProposalRM = Object.assign({}, state.ProbabilityReducer.data);
                delete state.ProbabilityReducer;
                return { ProposalRM };
            case ACTION_CLAIMS_POLICY_TYPE_SUCCESS:
                ProposalPolicyType = Object.assign({}, state.ProbabilityReducer.data);
                delete state.ProbabilityReducer;
                return { ProposalPolicyType };
            case ACTION_PROPOSAL_DASHBOARD_VIEW_SUCCESS:
                ProposalView = Object.assign({}, state.ProbabilityReducer.data);
                delete state.ProbabilityReducer;
                return { ProposalView };
            default:
        }
    }
    return {
        ProbabilityDropdown,
        ProposalRM,
        ProposalPolicyType,
        ProposalView
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionProbability,
            actionProposalRM,
            actionProposalPolicyType,
            actionProposalDashboard,

        },
        dispatch
    );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ProposalDashboard))
