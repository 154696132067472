import { Controller, useForm } from "react-hook-form";
import ScreenTitle from "../../../../../../component/screen_title";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { useEffect, useState } from "react";
import {
  ACTION_BIRTHDAY_WISH_EDIT_SUCCESS,
  actionBirthdayWishEdit,
} from "./action";
import { actionSalesRM, ACTION_MASTER_SALES_RM_SUCCESS  } from "../add/action";
import { connect } from "react-redux";
import { withAuth } from "react-auth-guard";
import { bindActionCreators } from "redux";
import $ from "jquery";
import {
  ERROR,
  PRIMARY_COLOR,
  UNAUTHORIZED,
  VALIDATION_ERROR,
} from "../../../../../../constants";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const BirthDayWishEdit = (props) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [sendCake, setSendCake]= useState();
  const [salesRM, setSalesRM]= useState();
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().toLocaleDateString("default", { month: "long" })
  );
  const [assignRM, setAssignRM] = useState([]);
  const detailData = props?.location?.state?.data;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(()=>{
    props.actionSalesRM({ user_id: props.auth.getUser().user_id });
  },[])

  const initialRender = () => {
    Object.keys(detailData).forEach((fieldName) => {
      setValue(fieldName, String(detailData[fieldName]));
    });
  };
  useEffect(initialRender, [detailData]);

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= currentYear - 100; i--) {
      years.push({
        value: i.toString(),
        text: i.toString(),
      });
    }
    return years;
  };

const generateMonthOptions = () => {
    const months = [];
    for (let i = 0; i < 12; i++) {
      const monthNumber = i + 1;
      const monthName = new Date(selectedYear, i, 1).toLocaleDateString(
        "default",
        { month: "long" }
      );
      months.push({ value: monthNumber.toString(), text: monthName });
    }
    return months;
  };

  const generateDayOptions = () => {
    let daysInMonth;
    const currentMonth = new Date().toLocaleDateString("default", {
      month: "long",
    });
    if (
      selectedMonth === currentMonth &&
      selectedYear === new Date().getFullYear()
    ) {
      daysInMonth = new Date(
        selectedYear,
        new Date(Date.parse(selectedMonth + " 1, " + selectedYear)).getMonth() +
          1,
        0
      ).getDate();
    } else {
      daysInMonth = new Date(
        selectedYear,
        new Date(Date.parse(selectedMonth + " 1, " + selectedYear)).getMonth() +
          1,
        0
      ).getDate();
    }

    const days = [];
    for (let i = 1; i <= daysInMonth; i++) {
      days.push({ value: i.toString(), text: i.toString() });
    }
    return days;
  };
  const onBirthDayWishEditUpdate = () => {
    const { BirthDayWishEdit } = props;
    if (BirthDayWishEdit != null) {
      var BirthDayWishEditResponse = Object.assign({}, BirthDayWishEdit);
      if (BirthDayWishEditResponse.result === true) {
        $("#birthday_wish-form-loader").addClass("hidden");
        $("#save_birthday_wish").prop("disabled", false);
        props.history.goBack();
      } else {
        $("#birthday_wish-form-loader").addClass("hidden");
        $("#save_birthday_wish").prop("disabled", false);
        switch (BirthDayWishEditResponse.status) {
          case UNAUTHORIZED:
            props.auth.logout();
            break;
            case VALIDATION_ERROR:
            var errorMessage = "";

            if (BirthDayWishEditResponse.message.hasOwnProperty("email")) {
              errorMessage += BirthDayWishEditResponse.message.email + "<br>";
            }
            if (BirthDayWishEditResponse.message.hasOwnProperty("mobile")) {
              errorMessage += BirthDayWishEditResponse.message.mobile + "<br>";
            }

            if (errorMessage.length > 0) {
              Swal.fire({
                title: "Error",
                icon: "error",
                html: errorMessage,
                confirmButtonColor: PRIMARY_COLOR,
              }).then((result) => {});
            }
            break;
          case ERROR:
            toast.error(BirthDayWishEditResponse.message);
            break;
          default:
        }
      }
    }
  };
  useEffect(onBirthDayWishEditUpdate, [props.BirthDayWishEdit]);

  const onMasterAssignRMUpdate = () => {
    const { AssignRM } = props;
    var AssignRMResponse = Object.assign({}, AssignRM);
    if (AssignRMResponse.result === true) {

        var arrAssignRM = Object.assign([], AssignRMResponse.response);

        setAssignRM(arrAssignRM);
        setValue('sales_rm_id', detailData?.sales_rm_id)
    } else {
        switch (AssignRMResponse.status) {
            case UNAUTHORIZED:

            default:
        }
    }
}
useEffect(onMasterAssignRMUpdate, [props.AssignRM]);

  const onSubmit = (data) => {
    $("#birthday_wish-form-loader").removeClass("hidden");
    $("#save_birthday_wish").prop("disabled", true);
    (data?.dob_year === 'null'  || data?.dob_year === 'undefined') && delete data?.dob_year;
    data.dob_month = selectedMonth;
    data.send_cake = sendCake;
    data.sales_rm_id = salesRM;
    data.id = detailData?.id;
    props.actionBirthdayWishEdit(data);
  };

  return (
    <>
      <ScreenTitle title="Edit Birthday Wish" />
      <section>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                <form
                  noValidate
                  autoComplete="Off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-body">
                    <div className="row">
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label className="label-control">
                            Name&nbsp;<span className="danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Enter Name"
                            {...register("name", {
                              required: "Name is required",
                            })}
                          />
                          {errors?.name && (
                            <ErrorLabel message={errors?.name?.message} />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label className="label-control">Mobile Number</label>
                          <input
                            className="form-control"
                            id="mobile"
                            name="mobile"
                            maxLength={10}
                            placeholder="Enter Number"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            {...register("mobile", {
                              required: "Mobile Number is required",
                              pattern: {
                                value: /^[6789]\d{9}$/,
                                message:
                                  "Please enter 10 digit valid Mobile Number",
                              },
                            })}
                          />
                          {errors?.mobile && (
                            <ErrorLabel message={errors?.mobile?.message} />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label className="label-control">Email</label>
                          <input
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Enter Email"
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value:
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Please enter valid Email Id",
                              },
                            })}
                          />
                          {errors?.email && (
                            <ErrorLabel message={errors?.email?.message} />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label className="label-control">
                            Day&nbsp;<span className="danger">*</span>
                          </label>
                          <Controller
                            name="dob_date"
                            control={control}
                            render={({ field }) => (
                              <DropDownListComponent
                                {...field}
                                id="dob_date"
                                name="dob_date"
                                dataSource={generateDayOptions()}
                                allowFiltering={false}
                                placeholder="Select Day"
                                value={field.value}
                                fields={{ text: "text", value: "value" }}
                                change={(event) => {
                                  if (event.itemData != null) {
                                    field.onChange(event.itemData.value);
                                  }
                                }}
                              />
                            )}
                            rules={{
                              required: "Day is required",
                            }}
                          />

                          {errors.dob_date && (
                            <ErrorLabel message={errors.dob_date?.message} />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label className="label-control">
                            Month&nbsp;<span className="danger">*</span>
                          </label>
                          <Controller
                            name="dob_month"
                            control={control}
                            render={({ field }) => (
                              <DropDownListComponent
                                {...field}
                                id="dob_month"
                                name="dob_month"
                                dataSource={generateMonthOptions()}
                                allowFiltering={false}
                                placeholder="Select Month"
                                value={field.value}
                                fields={{ text: "text", value: "value" }}
                                change={(event) => {
                                  if (event.itemData != null) {
                                    field.onChange(event.itemData.value);
                                    setSelectedMonth(
                                      Number(event.itemData.value)
                                    );
                                  }
                                }}
                              />
                            )}
                            rules={{
                              required: "Month is required",
                            }}
                          />

                          {errors.dob_month && (
                            <ErrorLabel message={errors.dob_month?.message} />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label className="label-control">Year</label>
                          <Controller
                            name="dob_year"
                            control={control}
                            render={({ field }) => (
                              <DropDownListComponent
                                {...field}
                                id="dob_year"
                                name="dob_year"
                                dataSource={generateYearOptions()}
                                allowFiltering={false}
                                placeholder="Select Year"
                                value={field.value}
                                fields={{ text: "text", value: "value" }}
                                change={(event) => {
                                  if (event.itemData != null) {
                                    field.onChange(event.itemData.value);
                                    setSelectedYear(event.itemData.value);
                                  }
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className={`col-12 col-md-4 col-lg-4`}>
                          <div className="form-group">
                              <label className="label-control">Sales RM</label>
                              <Controller
                                  name="sales_rm_id"
                                  control={control}
                                  render={({ field }) =>
                                      <DropDownListComponent
                                          {...field}
                                          dataSource={assignRM}
                                          fields={{ text: 'full_name', value: 'user_id' }}
                                          allowFiltering={true}
                                          placeholder="Select Sales RM"
                                          value={field.value}
                                          change={(event) => {
                                              if (event.itemData != null) {
                                                  field.onChange(event.itemData.user_id)
                                                  setSalesRM(event.itemData.user_id)
                                              }
                                          }}
                                      />
                                  }
                              />
                          </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label className="label-control">
                           Send cake?
                          </label>
                          <Controller
                            name="send_cake"
                            control={control}
                            render={({ field }) => (
                              <DropDownListComponent
                                {...field}
                                id="send_cake"
                                name="send_cake"
                                dataSource={[
                                  {
                                    text:'Yes',
                                    value:'yes'
                                  },
                                  {
                                    text:'No',
                                    value:'no'
                                  }
                                ]}
                                allowFiltering={false}
                                placeholder="Select"
                                value={field.value}
                                fields={{ text: "text", value: "value" }}
                                change={(event) => {
                                  if (event.itemData != null) {
                                    field.onChange(event.itemData.value);
                                    setSendCake(event.itemData.value)
                                  }
                                }}
                              />
                            )}
                          />
                          <small className="text-success">Cake will be sent on Approval basis only</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-actions border-0 float-none ">
                    <button
                      id="save_birthday_wish"
                      className="btn btn-primary mr-1 shadow round text-uppercase"
                      type="submit"
                    >
                      <div className="d-flex align-items-center">
                        Submit
                        <span
                          id="birthday_wish-form-loader"
                          className="spinner-border spinner-border-sm ml-half hidden"
                        ></span>
                      </div>
                    </button>
                    <button
                      className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                      onClick={(event) => {
                        event.preventDefault();
                        props.history.goBack();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = (state) => {
  var BirthDayWishEdit = null;
  var AssignRM =null
  if (
    state.BirthdayWishesEditReducer != null &&
    Object.keys(state.BirthdayWishesEditReducer).length > 0 &&
    state.BirthdayWishesEditReducer.constructor === Object
  ) {
    switch (state.BirthdayWishesEditReducer.tag) {
      case ACTION_BIRTHDAY_WISH_EDIT_SUCCESS:
        BirthDayWishEdit = Object.assign(
          {},
          state.BirthdayWishesEditReducer.data
        );
        delete state.BirthdayWishesEditReducer;
        return { BirthDayWishEdit };
      default:
    }
  }

  if (
    state.BirthdayWishesAddReducer &&
    Object.keys(state.BirthdayWishesAddReducer).length > 0 &&
    state.BirthdayWishesAddReducer.constructor === Object
  ) {
    switch (state.BirthdayWishesAddReducer.tag) {
        case ACTION_MASTER_SALES_RM_SUCCESS:
        AssignRM = Object.assign({}, state.BirthdayWishesAddReducer.data);
        delete state.BirthdayWishesAddReducer;
        return { AssignRM };
      default:
    }
  }

  return {
    BirthDayWishEdit,
    AssignRM
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ actionBirthdayWishEdit, actionSalesRM }, dispatch);
};
export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(BirthDayWishEdit)
);
