import React, { useEffect, useState } from "react";
import ScreenTitle from '../../../../../../component/screen_title';
import { DateBox } from 'devextreme-react/date-box';
import { Controller, useForm } from "react-hook-form";
import dateFormat from "dateformat";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { checkFileExtension } from '../../../../../../utils';
import { ACTION_EDIT_MASTER_CITIES_SUCCESS, ACTION_EDIT_MASTER_SALES_RM_SUCCESS, ACTION_EDIT_NEW_REQUEST_SUCCESS, actionEditNewRequest, actionMasterCities, actionSalesRM } from './action';
import { ACTION_VIEW_NEW_REQUEST_SUCCESS, actionViewNewRequest } from './action';
import { ACTION_EDIT_CLIENT_TYPE_SUCCESS, actionEditRiskManagementClientType } from "./action";
import { ACTION_EDIT_CLIENT_NAME_SUCCESS, actionEditRiskManagementClientName } from "./action";
import { ACTION_EDIT_TYPE_OF_INSPECTION_SUCCESS, actionEditRiskManagementTypeOfInspection } from "./action";
import { ERROR, MEETING_FILE_ERROR_MESSAGE, MEETING_FILE_SIZE, PRIMARY_COLOR, UNAUTHORIZED, characterLimit } from '../../../../../../constants';
import Swal from 'sweetalert2';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withAuth } from "react-auth-guard";
import moment from "moment";
import { toast } from "react-toastify";
import DocumentIframe from "../../../../../../component/popups/document_iframe";
import { downloadFile } from "../../../../../../utils";

const RiskManagementEdit = (props) => {
    const { register, handleSubmit, control, formState: { errors }, setValue, getValues } = useForm();
    const [riskDocument, setRiskDocument] = useState(null);
    const validExt = ['doc', 'docx', 'pptx', 'ppt', 'xlsx', 'xls', 'pdf', 'zip'];
    const [selectTypeOfInspection, setSelectTypeOfInspection] = useState(null)
    const [clientType, setClientType] = useState([]);
    const [clientName, setClientName] = useState([]);
    const [typeOfInspection, setTypeOfInspection] = useState([]);
    const [viewNewRequest, SetViewNewRequest] = useState(null);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)
    const [assignRM, setAssignRM] = useState([]);
    const [masterCity, setMasterCity] = useState([]);

    const initialRender = () => {
        props.actionViewNewRequest({ id: props.location.state.id })
        props.actionSalesRM({ user_id: props.auth.getUser().user_id });
        props.actionMasterCities();
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (viewNewRequest != null) {
            props.actionEditRiskManagementTypeOfInspection();
            props.actionEditRiskManagementClientType();
        }
    }, [viewNewRequest]);

    const onViewNewRequestUpdate = () => {
        const { ViewNewRequest } = props;
        if (ViewNewRequest != null) {
            var ViewNewRequestResponse = Object.assign({}, ViewNewRequest);
            if (ViewNewRequestResponse.result === true) {
                setValue('created_at', moment(ViewNewRequestResponse.response.created_at).format("DD/MM/YYYY"));
                setValue('address', ViewNewRequestResponse.response.address);
                setValue('occupancy', ViewNewRequestResponse.response.occupancy != null && ViewNewRequestResponse.response.occupancy != "undefined" ? ViewNewRequestResponse.response.occupancy : '');
                setValue('sum_insured', ViewNewRequestResponse.response.sum_insured);
                setValue('description', ViewNewRequestResponse.response.description);
                setValue('inspection_date', ViewNewRequestResponse.response.inspection_date);
                setValue('inspection_end_date', ViewNewRequestResponse.response.inspection_end_date);
                setValue('remarks', ViewNewRequestResponse.response.remarks != null && ViewNewRequestResponse.response.remarks != 'undefined' ? ViewNewRequestResponse.response.remarks : '');
                SetViewNewRequest(ViewNewRequestResponse.response);
            } else {
                switch (ViewNewRequestResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onViewNewRequestUpdate, [props.ViewNewRequest]);

    useEffect(() => {
        if (clientType != null && clientType.length > 0) {
            if (viewNewRequest != null && viewNewRequest.client_type != null) {
                setValue('client_type', viewNewRequest.client_type);
                props.actionEditRiskManagementClientName({ client_type: viewNewRequest.client_type });
            }
        }
    }, [clientType])

    const onClientTypeUpdate = () => {
        const { ClientType } = props;
        var ClientTypeResponse = Object.assign({}, ClientType);
        if (ClientTypeResponse.result === true) {
            setClientType(ClientTypeResponse.response);
        } else {
            switch (ClientTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onClientTypeUpdate, [props.ClientType]);

    useEffect(() => {
        if (clientName != null && clientName.length > 0) {
            if (viewNewRequest != null && viewNewRequest.client_id != null) {
                setValue('client_id', viewNewRequest?.client_id);
            }
        }
    }, [clientName])

    const onClientNameUpdate = () => {
        const { ClientName } = props;
        var ClientNameResponse = Object.assign({}, ClientName);
        if (ClientNameResponse.result === true) {
            setClientName(ClientNameResponse.response);
        } else {
            switch (ClientNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onClientNameUpdate, [props.ClientName]);

    useEffect(() => {
        if (typeOfInspection != null && typeOfInspection.length > 0) {
            if (viewNewRequest != null && viewNewRequest?.inspection_id != null) {
                setValue('inspection_id', viewNewRequest.inspection_id);
            }
        }
    }, [typeOfInspection])

    useEffect(() => {
        if (assignRM != null && assignRM.length > 0) {
            if (viewNewRequest != null && viewNewRequest.rm_id != null) {
                setValue('rm_id', viewNewRequest?.rm_id);
            }
        }
    }, [assignRM, viewNewRequest])

    const onMasterAssignRMUpdate = () => {
        const { AssignRM } = props;
        var AssignRMResponse = Object.assign({}, AssignRM);
        if (AssignRMResponse.result === true) {
            var arrAssignRM = Object.assign([], AssignRMResponse.response);
            setAssignRM(arrAssignRM);
        } else {
            switch (AssignRMResponse.status) {
                case UNAUTHORIZED:
                default:
            }
        }
    }
    useEffect(onMasterAssignRMUpdate, [props.AssignRM]);

    useEffect(() => {
        if (masterCity != null && masterCity.length > 0) {
            if (viewNewRequest != null && viewNewRequest.rm_city_id != null) {
                setValue('rm_city_id', viewNewRequest?.rm_city_id);
            }
        }
    }, [masterCity, viewNewRequest])

    const onMasterCitiesUpdate = () => {
        const { Cities } = props
        if (Cities != null) {
            var MasterCitiesResponse = Object.assign({}, Cities);
            if (MasterCitiesResponse.result === true) {
                setMasterCity(MasterCitiesResponse.response);
            } else {
                switch (MasterCitiesResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        setMasterCity([]);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onMasterCitiesUpdate, [props.Cities])

    const onTypeOfInspectionUpdate = () => {
        const { TypeOfInspection } = props;
        var TypeOfInspectionResponse = Object.assign({}, TypeOfInspection);
        if (TypeOfInspectionResponse.result === true) {
            setTypeOfInspection(TypeOfInspectionResponse.response);
        } else {
            switch (TypeOfInspectionResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onTypeOfInspectionUpdate, [props.TypeOfInspection]);

    const onRequestEditUpdate = () => {
        const { RequestEdit } = props;
        var RequestEditResponse = Object.assign({}, RequestEdit);
        if (RequestEditResponse.result === true) {
            props.history.goBack();
            setData(KEY_TOAST_MESSAGE, "Request updated successfully");
        } else {
            switch (RequestEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    toast.error(RequestEditResponse.message);
                default:
            }
        }
    }
    useEffect(onRequestEditUpdate, [props.RequestEdit]);

    const onSubmit = (data) => {
        var files = [];
        if (riskDocument != null) {
            files.push({ 'name': 'upload_document', 'value': riskDocument });
        }
        delete data.upload_document;
        data.id = props.location.state.id;
        data.client_id = Number(data.client_id);
        data.occupancy = data.occupancy != null ? data.occupancy : '';
        data.sum_insured = data.sum_insured != null ? data.sum_insured : '';
        data.inspection_id = data.inspection_id != null ? data.inspection_id : '';
        data.inspection_date = data.inspection_date != null ? data.inspection_date : '';
        data.inspection_end_date = data.inspection_end_date != null ? data.inspection_end_date : '';
        data.remarks = data.remarks != null ? data.remarks : '';
        data.description = data.description != null ? data.description : viewNewRequest?.description;
        if (data.created_at === null || data.created_at === undefined) {
            delete data.created_at;
        }
        if (!data.rm_city_id) {
            delete data.rm_city_id;
        }
        props.actionEditNewRequest(data, files);
    }

    return (
        <>

            <ScreenTitle title="Edit Risk Management" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Created Date</label>
                                                    <input
                                                        id="created_at"
                                                        name="created_at"
                                                        className="form-control"
                                                        type="text"
                                                        disabled={true}
                                                        {...register("created_at")}
                                                    />
                                                    {errors.created_at && <ErrorLabel message={errors.created_at?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Type</label>
                                                    <Controller
                                                        name="client_type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_type"
                                                                name="client_type"
                                                                dataSource={clientType}
                                                                allowFiltering={false}
                                                                placeholder="Select Client Type"
                                                                value={field.value}
                                                                enabled={false}
                                                                // enabled={viewNewRequest != null && viewNewRequest?.assign_engineer === null ? false : true}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: ' Please select client type'
                                                        }}
                                                    />

                                                    {errors.client_type && <ErrorLabel message={errors.client_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Name</label>
                                                    <Controller
                                                        name="client_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_id"
                                                                name="client_id"
                                                                dataSource={clientName}
                                                                allowFiltering={false}
                                                                placeholder="Select Client Name"
                                                                value={field.value}
                                                                enabled={false}
                                                                // enabled={viewNewRequest != null && viewNewRequest?.assign_engineer === null ? false : true}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: viewNewRequest != null && viewNewRequest.client_id != null ? ' Please select client name' : false
                                                        }}
                                                    />

                                                    {errors.client_id && <ErrorLabel message={errors.client_id?.message} />}
                                                </div>

                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Location Address&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        type="text"
                                                        maxLength={250}
                                                        placeholder="Enter Location Address"
                                                        {...register('address', {
                                                            required: 'Location Address is required',
                                                            maxLength:
                                                            {
                                                                value: 250,
                                                                message: "Please enter 250 charcter only"
                                                            }
                                                        })}
                                                    />
                                                    {errors.address?.type === 'required' && <ErrorLabel message={errors.address?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Occupancy</label>
                                                    <input
                                                        className="form-control"
                                                        id="occupancy"
                                                        name="occupancy"
                                                        type="text"
                                                        // disabled={viewNewRequest != null && viewNewRequest?.assign_engineer === null ? true : false}
                                                        placeholder="Enter occupancy"
                                                        {...register('occupancy')}
                                                    />
                                                    {errors.occupancy?.type && <ErrorLabel message={errors.occupancy?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Sum Insured </label>
                                                    <input
                                                        className="form-control"
                                                        id="sum_insured"
                                                        name="sum_insured"
                                                        type="text"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        placeholder="Enter sum insured"
                                                        {...register('sum_insured',
                                                            {
                                                                pattern: {
                                                                    value: /^[0-9]+$/,
                                                                    message: 'Please enter valid value'
                                                                }
                                                            }
                                                        )}
                                                    />
                                                    {errors.sum_insured?.type && <ErrorLabel message={errors.sum_insured?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Type of Inspection</label>
                                                    <Controller
                                                        name="inspection_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="inspection_id"
                                                                name="inspection_id"
                                                                dataSource={typeOfInspection}
                                                                allowFiltering={false}
                                                                placeholder="Select Type of Inspection"
                                                                value={field.value}
                                                                // enabled={false}
                                                                // enabled={viewNewRequest != null && viewNewRequest?.assign_engineer === null ? false : true}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        setSelectTypeOfInspection(event.itemData.name)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: ' Please select type of inspection'
                                                        }}
                                                    />

                                                    {errors.inspection_id && <ErrorLabel message={errors.inspection_id?.message} />}
                                                </div>
                                            </div>
                                            {
                                                selectTypeOfInspection === 'Others' ?
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                        <label className="label-control">Description&nbsp;<span className="danger">*</span></label>
                                                        <input
                                                                className="form-control"
                                                                id="description"
                                                                name="description"
                                                                type="text"
                                                                placeholder="Enter description"
                                                                {...register('description', {
                                                                    required: selectTypeOfInspection === "Others" ? "Description is required" : false
                                                                })}
                                                            />
                                                            {errors.description && <ErrorLabel message={errors?.description?.message} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                    </>
                                            }
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">RM Name&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="rm_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="rm_id"
                                                                name="rm_id"
                                                                dataSource={assignRM}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                allowFiltering={true}
                                                                placeholder="Select RM Name"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'RM Name is required'
                                                        }}
                                                    />

                                                    {errors.rm_id && <ErrorLabel message={errors.rm_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">RM Location</label>
                                                    <Controller
                                                        name="rm_city_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="rm_city_id"
                                                                name="rm_city_id"
                                                                dataSource={masterCity}
                                                                allowFiltering={true}
                                                                placeholder="Select RM Location"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    {errors.rm_city_id && <ErrorLabel message={errors.rm_city_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Start Date of Inspection</label>
                                                    <Controller
                                                        name="inspection_date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="inspection_date"
                                                                name="inspection_date"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                value={field.value}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }

                                                    />
                                                    {errors.inspection_date && <ErrorLabel message={errors.inspection_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">End Date of Inspection</label>
                                                    <Controller
                                                        name="inspection_end_date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="inspection_end_date"
                                                                name="inspection_end_date"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                value={field.value}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }

                                                    />
                                                    {errors.inspection_end_date && <ErrorLabel message={errors.inspection_end_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <fieldset class="form-group">
                                                    <label for="basicInputFile">Document</label>
                                                    <div class="custom-file">
                                                        <input
                                                            type="file"
                                                            class="custom-file-input"
                                                            id="upload_document"
                                                            accept=".doc,.docx,.pptx,.ppt,.xlsx,.xls,.pdf,.zip"
                                                            name="upload_document"
                                                            onChange={(event) => {
                                                                if (
                                                                    event.target.files
                                                                ) {

                                                                    var fileSize = event.target.files[0].size;

                                                                    var ext = (event.target.files[0].name).split('.').pop();

                                                                    if (checkFileExtension(ext, validExt)) {

                                                                        if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                            setRiskDocument(event.target.files[0]);
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                icon: 'error',
                                                                                html: MEETING_FILE_ERROR_MESSAGE,
                                                                                confirmButtonColor: PRIMARY_COLOR
                                                                            }).then((result) => {

                                                                            });
                                                                        }
                                                                    }
                                                                    else {
                                                                        Swal.fire({
                                                                            title: 'Upload Error',
                                                                            text: 'You can only upload Word, PPT, Excel, PDF & ZIP files',
                                                                            icon: 'error'
                                                                        }).then(success => {

                                                                        })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                        <label class="custom-file-label" for="upload_document">
                                                            {
                                                                riskDocument != null ?
                                                                    riskDocument.name : "Choose file"
                                                            }
                                                        </label>
                                                        <div className="mt-1">
                                                            {
                                                                (viewNewRequest != null && viewNewRequest.risk_management_document != null) ?
                                                                    // <div id="action_risk_management_document">
                                                                    //     <a target="_blank" href={viewNewRequest.risk_management_document}>Click Here to View</a>
                                                                    // </div>
                                                                    // : <></>

                                                                    <>
                                                                        <a className="primary" id="action_risk_management_document"
                                                                            onClick={() => {

                                                                                if (viewNewRequest?.risk_management_document?.split('.').pop() == 'pdf' ||
                                                                                    viewNewRequest?.risk_management_document?.split('.').pop() == 'PDF' ||
                                                                                    viewNewRequest?.risk_management_document?.split('.').pop() == 'png' ||
                                                                                    viewNewRequest?.risk_management_document?.split('.').pop() == 'jpg' ||
                                                                                    viewNewRequest?.risk_management_document?.split('.').pop() == 'jpeg' ||
                                                                                    viewNewRequest?.risk_management_document?.split('.').pop() == 'gif'
                                                                                ) {
                                                                                    setSelectedDocumentIndex(viewNewRequest.risk_management_document)
                                                                                    setShowDocumentDetails(true);
                                                                                    setExtension(viewNewRequest.risk_management_document?.split('.').pop())
                                                                                    setDocumentDetails(viewNewRequest)
                                                                                } else {
                                                                                    downloadFile(viewNewRequest.risk_management_document);
                                                                                }
                                                                            }}
                                                                        >
                                                                            Click Here to View
                                                                        </a>
                                                                        <a id="downloadLink" className="d-none"></a>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Remark</label>
                                                    <input
                                                        className="form-control"
                                                        id="remarks"
                                                        name="remarks"
                                                        type="text"
                                                        // disabled={viewNewRequest != null && viewNewRequest?.assign_engineer === null ? true : false}
                                                        placeholder="Enter Remark"
                                                        {...register('remarks')}
                                                    />
                                                    {errors.remarks?.type && <ErrorLabel message={errors.remarks?.message} />}
                                                </div>
                                            </div>
                                          
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="edit_risk_management"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="risk-management-edit-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <DocumentIframe
                    {...props}
                    show={showDocumentDetails}
                    documentDetails={selectedDocumentIndex}
                    detailsOfDocument={documentDetails}
                    extension={extension}
                    handleClose={() => {
                        setShowDocumentDetails(false);
                    }}
                />
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var ViewNewRequest = null;
    var RequestEdit = null;
    var ClientType = null;
    var ClientName = null;
    var TypeOfInspection = null;
    var AssignRM = null;
    var Cities = null;

    if (
        state.EditRiskListReducer != null &&
        Object.keys(state.EditRiskListReducer).length > 0 &&
        state.EditRiskListReducer.constructor === Object
    ) {
        switch (state.EditRiskListReducer.tag) {
            case ACTION_VIEW_NEW_REQUEST_SUCCESS:
                ViewNewRequest = Object.assign({}, state.EditRiskListReducer.data);
                delete state.EditRiskListReducer;
                return { ViewNewRequest };
            case ACTION_EDIT_NEW_REQUEST_SUCCESS:
                RequestEdit = Object.assign({}, state.EditRiskListReducer.data);
                delete state.EditRiskListReducer;
                return { RequestEdit };
            case ACTION_EDIT_CLIENT_TYPE_SUCCESS:
                ClientType = Object.assign({}, state.EditRiskListReducer.data);
                delete state.EditRiskListReducer;
                return { ClientType };
            case ACTION_EDIT_CLIENT_NAME_SUCCESS:
                ClientName = Object.assign({}, state.EditRiskListReducer.data);
                delete state.EditRiskListReducer;
                return { ClientName };
            case ACTION_EDIT_TYPE_OF_INSPECTION_SUCCESS:
                TypeOfInspection = Object.assign({}, state.EditRiskListReducer.data);
                delete state.EditRiskListReducer;
                return { TypeOfInspection };
            case ACTION_EDIT_MASTER_SALES_RM_SUCCESS:
                AssignRM = Object.assign({}, state.EditRiskListReducer.data);
                delete state.EditRiskListReducer;
                return { AssignRM };
            case ACTION_EDIT_MASTER_CITIES_SUCCESS:
                Cities = Object.assign({}, state.EditRiskListReducer.data);
                delete state.EditRiskListReducer;
                return { Cities };
            default:
        }
    }
    return {
        ViewNewRequest,
        RequestEdit,
        ClientType,
        ClientName,
        TypeOfInspection,
        AssignRM,
        Cities
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionEditNewRequest, actionViewNewRequest,
            actionEditRiskManagementTypeOfInspection,
            actionEditRiskManagementClientName,
            actionEditRiskManagementClientType, actionMasterCities, actionSalesRM
        },
        dispatch
    );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RiskManagementEdit))
