import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../component/form/error_label"
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

const AssignRm = (props) => {

    const { register, handleSubmit, formState: { errors }, reset, control } = useForm();
    const [assignRmArray, setAssignRmArray] = useState([]);

    const initialRender = () => {

        if (props.show === true) {
            setAssignRmArray(props?.data)
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');

            $("#approval_action-loader").addClass("hidden");
            $("#approval_action").prop("disabled", false);
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };

    useEffect(initialRender, [props.show]);



    const onSubmit = (data) => {
        $("#approval_action-loader").removeClass("hidden");
        $("#approval_action").prop("disabled", true);
        props.handleClose(data);

    }

    return (

        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="education-add"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Edit Assign RM
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body no-scroll" style={{ overflow: "scroll" }}>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Assign RM&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="assign_rm"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={assignRmArray}
                                                                allowFiltering={false}
                                                                placeholder="Select Assign RM"
                                                                value={field.value}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Assign RM is required'
                                                        }}
                                                    />

                                                    {errors.assign_rm && <ErrorLabel message={errors.assign_rm?.message} />}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.handleClose();
                                            }}
                                        > Cancel</button>

                                        <button
                                            id="approval_action"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                &nbsp;Submit
                                                <span id="approval_action-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};


export default AssignRm
