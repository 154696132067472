import Api from '../../../../../../api';
import { fetchSuccess, loading } from '../../../../../../utils/action.util';
import { API_CREATE_INVENTORY, API_CREATE_TASK, API_ASSET_TYPE, API_EMPLOYEE_LIST_INVENTORY } from '../../../../../../api/constants';
import { API_CREATE_TASK_ACCOMPAINED_BY } from '../../../../../../api/constants';
import { PRINT } from '../../../../../../utils';

export const ACTION_CREATE_INVENTORY_LOADING = 'ACTION_CREATE_INVENTORY_LOADING';
export const ACTION_CREATE_INVENTORY_SUCCESS = 'ACTION_CREATE_INVENTORY_SUCCESS';
export const ACTION_ASSET_TYPE_SUCCESS = 'ACTION_ASSET_TYPE_SUCCESS';
export const ACTION_ASSET_TYPE_LOADING = 'ACTION_ASSET_TYPE_LOADING';
export const ACTION_EMPLOYEE_LIST_SUCCESS = 'ACTION_EMPLOYEE_LIST_SUCCESS';
export const ACTION_EMPLOYEE_LIST_LOADING = 'ACTION_EMPLOYEE_LIST_LOADING';



export function actionCreateInventory(params) {
    return (dispatch, getState) =>
        Api.post(API_CREATE_INVENTORY, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CREATE_INVENTORY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CREATE_INVENTORY_LOADING, { loading: false, data }));
        });
}

export function actionAssetTypeInventory() {
    return (dispatch, getState) =>
        Api.get(API_ASSET_TYPE).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_ASSET_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_ASSET_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionEmployeeListInventory() {
    return (dispatch, getState) =>
        Api.get(API_EMPLOYEE_LIST_INVENTORY + '/active').then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_EMPLOYEE_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EMPLOYEE_LIST_LOADING, { loading: false, data }));
        });
}
