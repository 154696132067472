import { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { UNAUTHORIZED } from '../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionViewWellnessManagmentRequest, ACTION_WELLNESS_MANAGEMENT_VIEW_SUCCESS } from '../../action';
import moment from 'moment';
import RequestHistory from '../../../../../../component/widgets/request_history';
import $ from 'jquery';
import _ from 'lodash';
import { downloadFile } from '../../../../../../utils';
import DocumentIframe from '../../../../../../component/popups/document_iframe';
import { getData, KEY_USER } from '../../../../../../utils/preferences';

const WellnessPoolView = (props) => {
    const [wellnessPoolDetail, setWellnessPoolDetail] = useState(null);
    const [wellnessApprovalDetail, setWellnessApprovalDetail] = useState(null);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)
    const [departments, setDepartments] = useState(null);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionViewWellnessManagmentRequest({ id: props.location.state.id });

        var userDetail = getData(KEY_USER);
        if (userDetail != null) {
            var userObj = JSON.parse(userDetail)
            setDepartments(userObj.departments)
        }
    }
    useEffect(initialRender, []);

    const onRequestViewUpdate = () => {
        const { RequestView } = props;
        var RequestViewResponse = Object.assign({}, RequestView);
        if (RequestViewResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setWellnessPoolDetail(RequestViewResponse.response);
        } else {
            $("#progress-loader").addClass("hidden");
            switch (RequestViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onRequestViewUpdate, [props.RequestView]);
    return (
        <>
            <ScreenTitle title={`View ${props.location.state.request_type} Wellness Request`} />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Created Date</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        wellnessPoolDetail != null && wellnessPoolDetail?.created_at != null ?
                                            moment(wellnessPoolDetail?.created_at).format("DD/MM/YYYY") : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Client Type</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        wellnessPoolDetail != null && wellnessPoolDetail?.client_type != null ?
                                            _.capitalize(wellnessPoolDetail?.client_type) : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Client Name</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        wellnessPoolDetail != null && wellnessPoolDetail?.client_name != null ?
                                            wellnessPoolDetail?.client_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Type</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        wellnessPoolDetail != null && wellnessPoolDetail?.wellness_type_name != null ?
                                            wellnessPoolDetail.wellness_type_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>

                        {wellnessPoolDetail != null && wellnessPoolDetail?.wellness_type_name != null &&
                            wellnessPoolDetail.wellness_type_name === "Others" ? <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Description</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        wellnessPoolDetail != null && wellnessPoolDetail?.wellness_type_detail != null ?
                                            wellnessPoolDetail.wellness_type_detail : '-'
                                    }
                                </h4>
                            </div>
                        </div> : <></>}

                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Location Address</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        wellnessPoolDetail != null && wellnessPoolDetail?.address != null ?
                                            wellnessPoolDetail?.address : '-'
                                    }
                                </h4>
                            </div>
                        </div>

                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Date of Activity</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        wellnessPoolDetail != null && wellnessPoolDetail?.activity_date != null ?
                                            moment(wellnessPoolDetail?.activity_date).format("DD/MM/YYYY") : '-'
                                    }
                                </h4>
                            </div>
                        </div>

                        {wellnessPoolDetail != null && wellnessPoolDetail?.wellness_executed_by != null ? <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Wellness Executed By</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {wellnessPoolDetail.wellness_executed_by || '-'}
                                </h4>
                            </div>
                        </div> : <></>}

                        {wellnessPoolDetail != null && wellnessPoolDetail?.assign_rm != null ? <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">
                                    {departments != null && departments.length > 0 && departments.includes('Wellness') ? 'Assigned RM' : 'Assigned RM'}
                                </h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {wellnessPoolDetail.assign_rm || '-'}
                                </h4>
                            </div>
                        </div> : <></>}


                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Remark</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        wellnessPoolDetail != null && wellnessPoolDetail?.remarks != null ?
                                            wellnessPoolDetail?.remarks : '-'
                                    }
                                </h4>
                            </div>
                        </div>

                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Reason</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        wellnessPoolDetail != null && wellnessPoolDetail?.reason != null ?
                                            wellnessPoolDetail.reason : '-'
                                    }
                                </h4>
                            </div>
                        </div>

                        {
                            props?.location?.state?.status_name != 'pending' ?
                                <div className="row pt-2">
                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                        <h4 className="text-uppercase font-small-3 mb-0">Approved By</h4>
                                    </div>
                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                        <h4 className="font-medium-1 text-bold-700 mb-0">
                                            {
                                                wellnessPoolDetail != null && wellnessPoolDetail?.approved_by != null ?
                                                    wellnessPoolDetail?.approved_by : '-'
                                            }
                                        </h4>
                                    </div>
                                </div>
                                :
                                <></>
                        }


                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Raised by </h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        wellnessPoolDetail != null && wellnessPoolDetail?.raised_by != null ?
                                            wellnessPoolDetail?.raised_by : '-'
                                    }
                                </h4>
                            </div>
                        </div>

                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Actual Date of Activity</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        wellnessPoolDetail != null && wellnessPoolDetail?.actual_activity_date != null ?
                                            moment(wellnessPoolDetail?.actual_activity_date).format("DD/MM/YYYY") : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        {/* <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Assigned RM</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        wellnessPoolDetail != null && wellnessPoolDetail?.assign_rm != null ?
                                            wellnessPoolDetail?.assign_rm : '-'
                                    }
                                </h4>
                            </div>
                        </div> */}


                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3">
                                <h4 className="text-uppercase font-small-3 mb-0">Document</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3">
                                {
                                    (wellnessPoolDetail != null && wellnessPoolDetail.wellness_request_document != null && wellnessPoolDetail.wellness_request_document.length > 0) ?
                                        wellnessPoolDetail.wellness_request_document.map((objLink, index) => (
                                            <>
                                                <div>
                                                    {/* <a target="_blank" href={objLink.wellness_request_document} download="Meeting Document">Document {index + 1}</a> */}
                                                    <a className="primary"
                                                        onClick={() => {
                                                            if (objLink.wellness_request_document?.split('.').pop() == 'pdf' ||
                                                                objLink.wellness_request_document?.split('.').pop() == 'PDF' ||
                                                                objLink.wellness_request_document?.split('.').pop() == 'png' ||
                                                                objLink.wellness_request_document?.split('.').pop() == 'jpg' ||
                                                                objLink.wellness_request_document?.split('.').pop() == 'jpeg' ||
                                                                objLink.wellness_request_document?.split('.').pop() == 'gif'
                                                            ) {
                                                                setSelectedDocumentIndex(objLink.wellness_request_document)
                                                                setShowDocumentDetails(true);
                                                                setExtension(objLink.wellness_request_document?.split('.').pop())
                                                                setDocumentDetails(objLink)
                                                            } else {
                                                                downloadFile(objLink.wellness_request_document);
                                                            }
                                                        }}
                                                    >
                                                        Document {index + 1}
                                                    </a>
                                                    <a id="downloadLink" className="d-none"></a>
                                                </div>
                                            </>

                                        )) : <h4 className="font-medium-1 text-bold-700 mb-0">-</h4>
                                }
                            </div >
                        </div>
                        {
                            wellnessPoolDetail != null && wellnessPoolDetail.request_wellness_status.length > 0 ?
                                <RequestHistory
                                    history={wellnessPoolDetail.request_wellness_status}
                                />
                                :
                                <></>
                        }
                        <div className="form-actions border-0 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {

                                    event.preventDefault();
                                    if (props?.location?.state?.from === 'client-group') {
                                        props.history.push({
                                            pathname: "/client/group-level-view",
                                            state: {
                                                client_group_id: wellnessPoolDetail?.client_group_id,
                                                tab: props?.location?.state?.selectedTab,
                                                client_detail: "wellness"
                                            }
                                        })
                                    } else {
                                        props.history.goBack();
                                    }
                                }}
                            >Back</button>

                        </div>
                    </div>
                </div>
            </section>
            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                detailsOfDocument={documentDetails}
                extension={extension}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />
        </>
    )
}
const mapStateToProps = (state) => {
    var RequestView = null;

    if (
        state.viewWellnessManagementReducer != null &&
        Object.keys(state.viewWellnessManagementReducer).length > 0 &&
        state.viewWellnessManagementReducer.constructor === Object
    ) {
        switch (state.viewWellnessManagementReducer.tag) {

            case ACTION_WELLNESS_MANAGEMENT_VIEW_SUCCESS:
                RequestView = Object.assign({}, state.viewWellnessManagementReducer.data);
                delete state.viewWellnessManagementReducer;
                return { RequestView };
            default:
        }
    }
    return {
        RequestView,

    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionViewWellnessManagmentRequest
    }, dispatch)
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(WellnessPoolView))

