import React from 'react';
import { Route, Switch } from 'react-router';
import TaskDetails from './task/list';
import TasksView from './task/view';
import CreateTask from './create_task';
import TaskPoolList from './task_pool/list';
import TaskPoolView from './task_pool/view';
import InventoryRoute from './inventory';

const TaskDetailsRoute = (props) => {

    const { match } = props;

    return (
        <>
            <Switch>
                <Route exact path={`${match.url}`} component={TaskDetails} />
                <Route path={`${match.url}/view`} component={TasksView} />
                <Route path={`${match.url}/create-task`} component={CreateTask} />
                <Route path={`${match.url}/task-pool/view`} component={TaskPoolView} />
                <Route path={`${match.url}/task-pool`} component={TaskPoolList} />
                <Route path={`${match.url}/inventory`} component={InventoryRoute} />

            </Switch>
        </>
    )
}

export default TaskDetailsRoute