import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../component/form/error_label"
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { connect } from 'react-redux';
import { withAuth } from 'react-auth-guard';
import { UNAUTHORIZED, TRAVEL, ASSET, IT } from '../../../constants';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../utils/preferences';
import { toast } from "react-toastify";
import { bindActionCreators } from 'redux';
import { actionTaskStatus, ACTION_TASK_STATUS_SUCCESS } from './action';

const TaskStatus = (props) => {

    const { register, handleSubmit, formState: { errors }, reset, control } = useForm();
    const [taskStatus, setTaskStatus] = useState([]);

    const initialRender = () => {

        if (props.show === true) {

            reset({ remark: '' })

            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');

            $("#approval_action-loader").addClass("hidden");
            $("#approval_action").prop("disabled", false);

            if (props.auth.getUser().user_id === props.task.user_id && props.auth.getUser().user_id === props.task.assign_to) {
                switch (props.task.status) {
                    case 'open':
                        setTaskStatus([
                            // {
                            //     value: 'in-progress',
                            //     text: 'In Progress'
                            // },
                            {
                                value: 'completed',
                                text: 'Completed'
                            }])
                        break;
                    case 'in-progress':
                        setTaskStatus([
                            {
                                value: 'completed',
                                text: 'Completed'
                            }
                        ]);
                        break;
                    default:
                }
            } else {
                // Owner is Login
                if (props.auth.getUser().user_id === props.task.user_id) {
                    switch (props.task.status) {
                        case 'open':
                            setTaskStatus([{
                                value: 'cancel',
                                text: 'Cancel'
                            }])
                            break;
                        case 'to_review':
                            setTaskStatus([
                                {
                                    value: 'completed',
                                    text: 'Completed'
                                },
                                {
                                    value: 're-open',
                                    text: 'Re-Open'
                                }
                            ]);
                            break;
                        default:
                    }
                }
                // User is Login
                if (props.auth.getUser().user_id === props.task.assign_to) {
                    if (props.task.type === ASSET || props.task.type === TRAVEL || props.task.type === IT) {
                        switch (props.task.status) {
                            case 'open':
                                setTaskStatus([{
                                    value: 'completed',
                                    text: 'Completed'
                                }])
                                break;
                            default:
                        }
                    } else {
                        switch (props.task.status) {
                            case 'open':
                            case 're-open':
                                setTaskStatus([{
                                    value: 'in-progress',
                                    text: 'In Progress'
                                }])
                                break;
                            case 'in-progress':
                                setTaskStatus([{
                                    value: 'to_review',
                                    text: 'To Review'
                                }])
                                break;
                            default:
                        }
                    }
                }
            }
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };

    useEffect(initialRender, [props.show]);

    const onTasksStatusUpdate = () => {
        const { TasksStatus } = props;
        var TasksStatusResponse = Object.assign({}, TasksStatus);
        $("#approval_action").prop("disabled", false);
        if (TasksStatusResponse.result === true) {
            if (getData(KEY_TOAST_MESSAGE) != null) {
                toast.success(getData(KEY_TOAST_MESSAGE));
                removeData(KEY_TOAST_MESSAGE);
            }
            setTaskStatus(TasksStatusResponse.response);
            props.handleClose(TasksStatusResponse.response);
            $("#approval_action-loader").addClass("hidden");
        } else {
            $("#approval_action-loader").addClass("hidden");
            switch (TasksStatusResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                default:
            }
        }
    }
    useEffect(onTasksStatusUpdate, [props.TasksStatus]);

    const onSubmit = (data) => {
        $("#approval_action-loader").removeClass("hidden");
        $("#approval_action").prop("disabled", true);
        data.id = props.task.id;
        data.status = data.status;
        props.actionTaskStatus(data);

    }

    return (

        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="education-add"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Task Status
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body no-scroll" style={{ overflow: "scroll" }}>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Action&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={taskStatus}
                                                                allowFiltering={false}
                                                                placeholder="Select Action"
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Action is required'
                                                        }}
                                                    />

                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Reason&nbsp;<span className="danger">*</span></label>
                                                    <textarea
                                                        className="form-control"
                                                        id="remark"
                                                        name="remark"
                                                        rows={5}
                                                        placeholder="Add Reason"
                                                        {...register('remark', { required: 'Reason is required' })}
                                                    />
                                                    {errors.remark && <ErrorLabel message={errors.remark?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.handleClose();
                                            }}
                                        > Cancel</button>

                                        <button
                                            id="approval_action"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                &nbsp;Submit
                                                <span id="approval_action-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
const mapStateToProps = (state) => {
    var TasksStatus = null;

    if (
        state.TaskStatusReducer != null &&
        Object.keys(state.TaskStatusReducer).length > 0 &&
        state.TaskStatusReducer.constructor === Object
    ) {
        switch (state.TaskStatusReducer.tag) {
            case ACTION_TASK_STATUS_SUCCESS:
                TasksStatus = Object.assign({}, state.TaskStatusReducer.data);
                delete state.TaskStatusReducer;
                return { TasksStatus };
            default:
        }
    }
    return {
        TasksStatus,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionTaskStatus }, dispatch);
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(TaskStatus));
