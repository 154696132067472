import Api from "../../../../../../api";
import {
    API_EDIT_WELLNESS_REQUEST,
    API_RISK_MANAGEMENT_CLIENT_TYPE,
    API_WELLNESS_MANAGEMENT_REQUEST_VIEW,
    API_ASSIGN_RM,
    API_WELLNESS_MANAGEMENT_WELLNESS_TYPE,
    API_WELLNESS_DOCUMENT_DELETE
} from "../../../../../../api/constants";
import { fetchSuccess, loading } from "../../../../../../utils/action.util";

export const ACTION_VIEW_WELLNESS_POOL_SUCCESS = "ACTION_VIEW_WELLNESS_POOL_SUCCESS";
export const ACTION_VIEW_WELLNESS_POOL_LOADING = "ACTION_VIEW_WELLNESS_POOL_LOADING";

export const ACTION_WELLNEESS_POOL_EDIT_LOADING = 'ACTION_WELLNEESS_POOL_EDIT_LOADING';
export const ACTION_WELLNESS_POOL_EDIT_SUCCESS = 'ACTION_WELLNESS_POOL_EDIT_SUCCESS';

export const ACTION_EDIT_WELLNESS_POOL_CLIENT_TYPE_SUCCESS = "ACTION_EDIT_WELLNESS_POOL_CLIENT_TYPE_SUCCESS";
export const ACTION_EDIT_WELLNESS_POOL_CLIENT_TYPE_LOADING = "ACTION_EDIT_WELLNESS_POOL_CLIENT_TYPE_LOADING";

export const ACTION_EDIT_WELLNESS_POOL_ASSIGN_ENGINEER_SUCCESS = "ACTION_EDIT_WELLNESS_POOL_ASSIGN_ENGINEER_SUCCESS";
export const ACTION_EDIT_WELLNESS_POOL_ASSIGN_ENGINEER_LOADING = "ACTION_EDIT_WELLNESS_POOL_ASSIGN_ENGINEER_LOADING";

export const ACTION_EDIT_WELLNESS_POOL_TYPE_SUCCESS = "ACTION_EDIT_WELLNESS_POOL_TYPE_SUCCESS";
export const ACTION_EDIT_WELLNESS_POOL_TYPE_LOADING = "ACTION_EDIT_WELLNESS_POOL_TYPE_LOADING";

export const ACTION_WELLNESS_DOCUMENT_DELETE_SUCCESS = "ACTION_WELLNESS_DOCUMENT_DELETE_SUCCESS";
export const ACTION_WELLNESS_DOCUMENT_DELETE_LOADING = "ACTION_WELLNESS_DOCUMENT_DELETE_LOADING";

export function actionViewWellnessPool(params) {
    return (dispatch, getState) => {
        Api.post(API_WELLNESS_MANAGEMENT_REQUEST_VIEW, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_VIEW_WELLNESS_POOL_SUCCESS, { data }))
        }).catch((reason) => {
            var data = {
                message: ""
            }
            dispatch(loading(ACTION_VIEW_WELLNESS_POOL_LOADING, { loading: false, data }))
        })
    }
}
export function actionEditWellnessPool(params, files) {
    return (dispatch, getState) => {
        Api.multipart(API_EDIT_WELLNESS_REQUEST, params, files).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_WELLNESS_POOL_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_WELLNEESS_POOL_EDIT_LOADING, { loading: false, data }))
        })
    };
}
export function actionEditWellnessPoolClientType() {
    return (dispatch, getState) =>
        Api.get(API_RISK_MANAGEMENT_CLIENT_TYPE).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_EDIT_WELLNESS_POOL_CLIENT_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_WELLNESS_POOL_CLIENT_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionEditWellnessPoolAssignEngineer() {
    return (dispatch, getState) =>
        Api.get(API_ASSIGN_RM).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_EDIT_WELLNESS_POOL_ASSIGN_ENGINEER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_WELLNESS_POOL_ASSIGN_ENGINEER_LOADING, { loading: false, data }));
        });
}

export function actionWellnessManagementWellnessType() {
    return (dispatch, getState) =>
        Api.get(API_WELLNESS_MANAGEMENT_WELLNESS_TYPE).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_EDIT_WELLNESS_POOL_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_EDIT_WELLNESS_POOL_TYPE_LOADING, { loading: false, data }));
        });
}


export function actionWellnessDocumentDelete(params) {
    return (dispatch, getState) => {
        Api.post(API_WELLNESS_DOCUMENT_DELETE, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_WELLNESS_DOCUMENT_DELETE_SUCCESS, { data }))
        }).catch((reason) => {
            var data = {
                message: ""
            }
            dispatch(loading(ACTION_WELLNESS_DOCUMENT_DELETE_LOADING, { loading: false, data }))
        })
    }
}