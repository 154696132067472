import { ACTION_CREATE_INVENTORY_SUCCESS,ACTION_ASSET_TYPE_SUCCESS,ACTION_EMPLOYEE_LIST_SUCCESS} from "./action";
import createReducer from "../../../../../../reducers/createReducer";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const CreateInventoryReducer = createReducer(INITIAL_STATE, {
    [ACTION_CREATE_INVENTORY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CREATE_INVENTORY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_ASSET_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ASSET_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_EMPLOYEE_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_EMPLOYEE_LIST_SUCCESS,
                loading: false,
            }
        );
    }
});

export default CreateInventoryReducer;
