import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import HTMLParser from 'html-react-parser';
import { ERROR, UNAUTHORIZED } from "../../../../../constants";
import Swal from "sweetalert2";
import { clearData, getData, KEY_GLOBAL_SEARCH } from "../../../../../utils/preferences";
import { generateInitialLettersString, linkify, PRINT, timeSince } from "../../../../../utils";
import { toast, ToastContainer } from "react-toastify";
import HomeEvents from "../../../../../component/activity_feed/home_event"
import UpcomingMeetings from "../../../../../component/widgets/upcoming_meeting";
import { withAuth } from "react-auth-guard";
import { FiThumbsUp, FiTrash2 } from 'react-icons/fi';
import { FaHeart } from 'react-icons/fa';
import { RiChat1Line, RiSendPlaneLine } from 'react-icons/ri';
import ReactImageGrid from "@cordelia273/react-image-grid";
import $ from 'jquery';
import LikeDetails from '../../../../../component/activity_feed/like'
import CreatePost from "../../../../../component/activity_feed/create_post";
import PostDetails from "../../../../../component/activity_feed/post";
import { bindActionCreators } from 'redux';
import { actionHomeActivityFeedList, ACTION_HOME_ACTIVITYFEEDLIST_SUCCESS } from './action';
import { actionHomeActivityFeedLike, ACTION_HOME_ACTIVITYFEEDLIKE_SUCCESS } from './action';
import { actionHomeActivityFeedDelete, ACTION_HOME_ACTIVITYFEEDDELETE_SUCCESS } from './action';
import { actionHomeActivityFeedCreateComment, ACTION_HOME_ACTIVITYFEEDCREATECOMMENT_SUCCESS } from './action';
import { actionHomeActivityFeedCommentLike, ACTION_HOME_ACTIVITYFEEDCOMMENTLIKE_SUCCESS } from './action';
import { actionHomeActivityFeedCommentDelete, ACTION_HOME_ACTIVITYFEEDCOMMENTDELETE_SUCCESS } from './action';
import { actionHomeActivityFeedCommentEdit, ACTION_HOME_ACTIVITYFEEDCOMMENTEDIT_SUCCESS } from './action';
import { actionHomeGetTicker, ACTION_HOME_GET_TICKER_SUCCESS } from './action';

const HomeDetails = (props) => {

    const [showLikeDetails, setShowLikeDetails] = useState(false);
    const [likeDetails, setLikeDetails] = useState([]);
    const [showCreatePost, setCreatePost] = useState(false);
    const [activityFeeds, setActivityFeeds] = useState([])
    const [actionPostIndex, setActionPostIndex] = useState(-1);
    const [actionPostCommentId, setActionPostCommentId] = useState(-1);
    const [showPostDetails, setShowPostDetails] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [showComments, setShowComments] = useState([]);
    const [getTicker, setGetTicker] = useState(null);

    const initialRender = () => {
        props.actionHomeUpcomingMeeting({});
        props.actionHomeGetTicker({ section_name: "HEADER_RUNNING_TEXT" });
        $(".content-wrapper").css("padding", "0");
        var global_search = getData(KEY_GLOBAL_SEARCH)
        if (global_search != null) {
            var objGlobalSearch = JSON.parse(global_search)
            var users = Object.assign([], objGlobalSearch.users)
            setAllUsers(users)
        }

        $('body').on('click', '.btn-post-tag', function () {
            props.history.push({ pathname: '/profile', state: { user_id: $(this).attr('id') } })
        })
        $('body').on('click', '.btn-post-str', function () {
            $(this).addClass('d-none').removeClass('d-inline')
            $(`#post-str-${$(this).attr('id').split('-')[3]}`).removeClass('hidden')
        })
        return () => {
            $(".content-wrapper").css("padding", "1.8rem");
        }
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (allUsers != null && allUsers.length > 0) {
            props.actionHomeActivityFeedList({})
        }
    }, [allUsers])

    const onActivityFeedListUpdate = () => {
        const { ActivityFeedList } = props;

        const ActivityFeedListResponse = Object.assign({}, ActivityFeedList)
        if (ActivityFeedListResponse.result === true) {
            setActivityFeeds(ActivityFeedListResponse.response);
            // setPage(ActivityFeedListResponse.response.current_page)
        } else {
            switch (ActivityFeedListResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    setActivityFeeds([])
                    break;
                default:
            }
        }
    }
    useEffect(onActivityFeedListUpdate, [props.ActivityFeedList])

    const onActivityFeedLikeUpdate = () => {
        const { ActivityFeedLike } = props;

        const ActivityFeedLikeResponse = Object.assign({}, ActivityFeedLike)
        if (ActivityFeedLikeResponse.result === true) {
            var arrPosts = Object.assign([], activityFeeds)
            arrPosts[actionPostIndex] = Object.assign({}, ActivityFeedLikeResponse.response)
            setActivityFeeds(arrPosts)
            setActionPostIndex(-1)
        } else {
            setActionPostIndex(-1)
            switch (ActivityFeedLikeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onActivityFeedLikeUpdate, [props.ActivityFeedLike])

    const onActivityFeedDeleteUpdate = () => {
        const { ActivityFeedDelete } = props;

        const ActivityFeedDeleteResponse = Object.assign({}, ActivityFeedDelete)
        if (ActivityFeedDeleteResponse.result === true) {
            var arrPosts = Object.assign([], activityFeeds)
            arrPosts.splice(actionPostIndex, 1)
            setActivityFeeds(arrPosts)
            setActionPostIndex(-1)
        } else {
            setActionPostIndex(-1)
            switch (ActivityFeedDeleteResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onActivityFeedDeleteUpdate, [props.ActivityFeedDelete])

    const onActivityFeedCreateCommentUpdate = () => {
        const { ActivityFeedCreateComment } = props;
        const ActivityFeedCreateCommentResponse = Object.assign({}, ActivityFeedCreateComment);
        if (ActivityFeedCreateCommentResponse.result === true) {
            var arrPosts = Object.assign([], activityFeeds)
            arrPosts[actionPostIndex] = Object.assign({}, ActivityFeedCreateCommentResponse.response)
            setActivityFeeds(arrPosts)
            $(`#post-${ActivityFeedCreateCommentResponse.response.id}`).val('')
            setActionPostIndex(-1)
        } else {
            setActionPostIndex(-1)
            switch (ActivityFeedCreateCommentResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    break;
                default:
            }
        }
    }
    useEffect(onActivityFeedCreateCommentUpdate, [props.ActivityFeedCreateComment])

    const onActivityFeedCommentLikeUpdate = () => {
        const { ActivityFeedCommentLike } = props;
        const ActivityFeedCommentLikeResponse = Object.assign({}, ActivityFeedCommentLike);
        if (ActivityFeedCommentLikeResponse.result === true) {
            var arrPosts = Object.assign([], activityFeeds)
            arrPosts[actionPostIndex] = Object.assign({}, ActivityFeedCommentLikeResponse.response)
            setActivityFeeds(arrPosts)
            setActionPostIndex(-1)
        } else {
            setActionPostIndex(-1)
            switch (ActivityFeedCommentLikeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onActivityFeedCommentLikeUpdate, [props.ActivityFeedCommentLike])

    const onActivityFeedCommentDeleteUpdate = () => {
        const { ActivityFeedCommentDelete } = props;
        const ActivityFeedCommentDeleteResponse = Object.assign({}, ActivityFeedCommentDelete);
        if (ActivityFeedCommentDeleteResponse.result === true) {
            var arrPosts = Object.assign([], activityFeeds)
            arrPosts[actionPostIndex] = Object.assign({}, ActivityFeedCommentDeleteResponse.response)
            setActivityFeeds(arrPosts)
            setActionPostIndex(-1)
        } else {
            setActionPostIndex(-1)
            switch (ActivityFeedCommentDeleteResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onActivityFeedCommentDeleteUpdate, [props.ActivityFeedCommentDelete])

    const onActivityFeedCommentEditUpdate = () => {
        const { ActivityFeedCommentEdit } = props;
        const ActivityFeedCommentEditResponse = Object.assign({}, ActivityFeedCommentEdit);
        if (ActivityFeedCommentEditResponse.result === true) {
            var arrPosts = Object.assign([], activityFeeds)
            arrPosts[actionPostIndex] = Object.assign({}, ActivityFeedCommentEditResponse.response)
            setActivityFeeds(arrPosts)
            $(`#comment-edit-view-${actionPostCommentId}`).addClass('hidden')
            $(`#comment-view-${actionPostCommentId}`).removeClass('hidden')
            setActionPostIndex(-1)
            setActionPostCommentId(-1)
        } else {
            setActionPostIndex(-1)
            setActionPostCommentId(-1)
            switch (ActivityFeedCommentEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    break;
                default:
            }
        }
    }
    useEffect(onActivityFeedCommentEditUpdate, [props.ActivityFeedCommentEdit])

    const onGetTickerUpdate = () => {
        const { GetTicker } = props;
        const GetTickerResponse = Object.assign({}, GetTicker)
        if (GetTickerResponse.result === true) {
            setGetTicker(GetTickerResponse.response.display_content_description)
        } else {
            switch (GetTickerResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onGetTickerUpdate, [props.GetTicker]);

    const renderPostImage = (activity, indexActivity) => {

        var arrImages = activity.images.map(image => image.full_url);

        return (
            <ReactImageGrid
                images={arrImages}
                modal={false}
                onClick={(image) => {
                    if (arrImages.length > 1) {
                        setActionPostIndex(indexActivity)
                        setShowPostDetails(true)
                    }
                }}
            />
        )
    }

    const renderTags = (tags) => {
        var arrTags = []
        if (tags.length === 1) {
            arrTags.push(
                <button key={`btn-tag-${0}`} type="button" class="btn text-primary text-bold-700 p-0" onClick={() => { props.history.push({ pathname: '/profile', state: { user_id: tags[0].user_id } }) }}>
                    @{tags[0].full_name}
                </button>
            )
        } else {
            tags.forEach((tag, tagIndex) => {
                arrTags.push(
                    <button key={`btn-tag-${tagIndex}`} type="button" class="btn text-primary text-bold-700 mr-half p-0" onClick={() => { props.history.push({ pathname: '/profile', state: { user_id: tag.user_id } }) }}>
                        @{tag.full_name}
                    </button>
                )
            })
        }
        return arrTags
    }

    const renderPostContent = (postContent, activity_id, activity) => {
        var formatText = postContent
        // PRINT('selected Tags', selectedTagUserText)
        if (allUsers != null && allUsers.length > 0) {
            allUsers.forEach((user, indexUser) => {
                var indexOfUser = String(formatText).indexOf(`##${user.id}:${user.name}##`)
                // PRINT('index user', indexOfUser)
                if (indexOfUser > -1) {
                    formatText = formatText.replace(`##${user.id}:${user.name}##`, `<button id=${user.id} type="button" class='btn text-primary text-bold-700 p-0 btn-post-tag'>@${user.name}</button>`)
                }
            })
        }
        // if(formatText.indexOf(activity.meta_url) > -1) {
        //     formatText = formatText.replace(activity.meta_url,`<a href=${activity.meta_url} target='_blank' class='text-primary'>${activity.meta_url}</a>`)
        // }
        if (formatText.length > 300) {
            // var subString1 = formatText.substring(0,300)
            var subString1 = formatText && formatText.length > 300 ? formatText.slice(0, 300).split(' ').slice(0, -1).join(' ') : formatText;
            // subString1 += ' ';
            var subString2 = `<span id='post-str-${activity_id}' class="hidden">${formatText.substring(subString1.length, formatText.length)}</span>`
            var readMore = `<button id='btn-post-str-${activity_id}' class="d-inline btn btn-sm px-0 text-primary text-bold-600 btn-post-str">Read more</button>`
            var mainString = subString1.concat(readMore)
            mainString = mainString.concat(subString2)
            mainString = linkify(mainString)
            return HTMLParser(String(mainString).trim())
        } else {
            formatText = linkify(formatText)
        }
        return HTMLParser(String(formatText).substring(0, formatText.length).trim().replaceAll('\n', '<br>'))
    }

    const renderActivities = () => {
        var arrActivities = [];
        if (activityFeeds != null && activityFeeds.length > 0) {
            activityFeeds.forEach((activity, indexActivity) => {
                arrActivities.push(
                    <div className="card rounded w-100" key={indexActivity} id={`activity-scroll-${activity.id}`}>
                        <div className="card-header custom-card-header-activity px-1">
                            <div className="d-flex flex-row">
                                <div className="flex-column">
                                    {
                                        (activity.profile_pic != null && activity.profile_pic.length > 0) ?
                                            <img
                                                src={activity.profile_pic}
                                                className="rounded-circle img-border cursor-pointer"
                                                alt="Card"
                                                width={50}
                                                height={50}
                                                style={{}}
                                                onClick={() => {
                                                    props.history.push({ pathname: '/profile', state: { user_id: activity.user_id } })
                                                }}
                                            />
                                            :
                                            <>
                                                <div
                                                    className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-medium-3 cursor-pointer"
                                                    style={{ height: 50, width: 50 }}
                                                    onClick={() => {
                                                        props.history.push({ pathname: '/profile', state: { user_id: activity.user_id } })
                                                    }}
                                                >
                                                    {activity.initial_name}
                                                </div>
                                            </>
                                    }
                                    <img
                                        src="/assets/images/avatar-s-8.png"
                                        class="rounded-circle img-border height-50 hidden"
                                        alt="Card"
                                    />
                                </div>
                                <div className="flex-column align-items-center ml-1 pt-half">
                                    <h6 className="font-small-3 py-0 text-bold-600 text-black mt-half mb-0 cursor-pointer"
                                        onClick={() => {
                                            props.history.push({ pathname: '/profile', state: { user_id: activity.user_id } })
                                        }}
                                    >
                                        {activity.full_name != null ? activity.full_name : '-'}
                                    </h6>
                                    <h6 className="font-small-3 py-0 my-0 text-bold-400 mt-half">{activity.timeSince}</h6>
                                </div>
                                {
                                    activity.user_id === props.auth.getUser().user_id ?
                                        <div className="flex-column ml-auto">
                                            <button type="button" className="btn text-black pt-half pr-0"
                                                onClick={() => {
                                                    Swal.fire({
                                                        titleText: 'Delete Post',
                                                        text: 'Are you sure, you want to delete this post?',
                                                        showCancelButton: true,
                                                        cancelButtonText: 'No',
                                                        confirmButtonText: 'Yes',
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            setActionPostIndex(indexActivity)
                                                            props.actionHomeActivityFeedDelete({
                                                                id: activity.id
                                                            })
                                                        }
                                                    })
                                                }}
                                            >
                                                <FiTrash2 size={16} />
                                            </button>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                        <div className="card-content px-0">
                            {
                                activity.content != null ?
                                    <div className="px-2">
                                        <p>{activity.content != null ? renderPostContent(activity.content, activity.id, activity) : ''}</p>
                                    </div>
                                    :
                                    <></>
                            }
                            {
                                activity.meta_url != null ?
                                    <div
                                        className="d-flex flex-row p-half rounded bg-white border m-half cursor-pointer"
                                        onClick={() => {
                                            window.open(activity.meta_url, "_blank")
                                        }}
                                    >
                                        <div className="flex-column mr-half">
                                            <img
                                                src={activity.meta_image}
                                                style={{ objectFit: 'cover' }}
                                                height="60"
                                                width="100"
                                                alt=""
                                            />
                                        </div>
                                        <div className="flex-column bg-blue-grey bg-lighten-5 p-half">
                                            <h5 className="text-black font-medium-2 text-bold-500">
                                                {activity.meta_title}
                                            </h5>
                                            <p>
                                                {activity.meta_description}
                                            </p>
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }
                            {
                                activity.tags != null && activity.tags.length > 0 ?
                                    <div className="px-2 mb-1">
                                        - with {renderTags(activity.tags)}
                                    </div>
                                    :
                                    <></>
                            }
                            {
                                activity.images != null && activity.images.length > 0 ?
                                    renderPostImage(activity, indexActivity)
                                    :
                                    <></>
                            }
                        </div>
                        <div className="card-footer pt-half">
                            <div className="row">
                                <div className="col-auto px-0">
                                    <button type="button" className="btn d-flex align-items-center">
                                        <FiThumbsUp
                                            size={24}
                                            className={`${Number(activity.is_liked_post) === 0 ? 'blue-grey lighten-1' : 'text-primary'}`}
                                            onClick={() => {
                                                if (Number(activity.is_liked_post) === 0) {
                                                    setActionPostIndex(indexActivity)
                                                    props.actionHomeActivityFeedLike({
                                                        post_id: activity.id,
                                                        user_id: props.auth.getUser().user_id,
                                                        like: true
                                                    })
                                                } else {
                                                    setActionPostIndex(indexActivity)
                                                    props.actionHomeActivityFeedLike({
                                                        post_id: activity.id,
                                                        user_id: props.auth.getUser().user_id,
                                                        like: false
                                                    })
                                                }
                                            }}
                                        />
                                        <span className="ml-half blue-grey lighten-1">
                                            <h6 className="mb-0" onClick={() => {
                                                if (activity.likes != null && activity.likes.length > 0) {
                                                    setLikeDetails(activity.likes);
                                                    setShowLikeDetails(true)
                                                }
                                            }}> {activity.likes != null ? activity.likes.length === 1 ? `1 Like` : `${activity.likes.length} Likes` : 0}
                                            </h6>
                                        </span>
                                    </button>
                                </div>
                                <div className="col-auto px-0">
                                    <button type="button" className="btn d-flex align-items-center">
                                        <RiChat1Line
                                            size={24}
                                            className={`${Number(activity.is_own_comment) === 0 ? 'blue-grey lighten-1' : 'blue-grey lighten-1'}`}
                                        />
                                        <span className="ml-half blue-grey lighten-1">
                                            {activity.comments != null ? activity.comments.length === 1 ? `1 Comment` : `${activity.comments.length} Comments` : 0}
                                        </span>
                                    </button>
                                </div>
                            </div>
                            {
                                activity.comments != null && activity.comments.length > 0 ?
                                    <div className="activity-comments mb-1" key={`comment-section-${indexActivity}`}>
                                        {
                                            activity.comments != null && activity.comments.length > 2 ?
                                                <div className={`d-flex flex-row view-previous-comment-${activity.id}`}>
                                                    <div className="flex-row ">
                                                        <button type="button" className="btn px-0 text-bold-600"
                                                            onClick={() => {
                                                                $(`.activity-comment-${activity.id}`).removeClass('d-none').addClass('d-flex')
                                                                $(`.view-previous-comment-${activity.id}`).addClass('d-none').removeClass('d-flex')
                                                                var viewPreviousComment = Object.assign([], showComments);
                                                                viewPreviousComment.push(`view-previous-comment-${activity.id}`);
                                                                setShowComments(viewPreviousComment);
                                                            }}
                                                        >
                                                            View Previous Comments
                                                        </button>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            activity.comments.map((comment, indexComment) => (
                                                <div className={`flex-row mb-1 activity-comment-${activity.id} ${indexComment < activity.comments.length - 3 ? showComments.includes(`view-previous-comment-${activity.id}`) ? 'd-flex' : 'd-none' : 'd-flex'} ${indexComment === 0 ? 'mt-1' : ''}`} key={indexComment}>
                                                    <div className="flex-column">
                                                        {
                                                            (comment.profile_pic != null && comment.profile_pic.length > 0) ?
                                                                <img
                                                                    src={comment.profile_pic}
                                                                    className="rounded-circle img-border cursor-pointer"
                                                                    alt="Card"
                                                                    width={50}
                                                                    height={50}
                                                                    style={{}}
                                                                    onClick={() => {
                                                                        props.history.push({ pathname: '/profile', state: { user_id: comment.user_id } })
                                                                    }}
                                                                />
                                                                :
                                                                <>
                                                                    <div
                                                                        className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-medium-3 cursor-pointer"
                                                                        style={{ height: 50, width: 50 }}
                                                                        onClick={() => {
                                                                            props.history.push({ pathname: '/profile', state: { user_id: comment.user_id } })
                                                                        }}
                                                                    >
                                                                        {comment.initial_name}
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                    <div className="flex-column w-100">
                                                        <div id={`comment-view-${comment.id}`} className="bg-blue-grey bg-lighten-5 rounded p-half ml-1">
                                                            <h1 className="font-small-3 text-bold-600 text-black mb-half cursor-pointer"
                                                                onClick={() => {
                                                                    props.history.push({ pathname: '/profile', state: { user_id: comment.user_id } })
                                                                }}
                                                            >
                                                                {comment.full_name != null ? comment.full_name : '-'}
                                                            </h1>
                                                            <p className="font-small-3 text-bold-400 mb-0">{comment.comment != null ? HTMLParser(linkify(comment.comment)) : '-'}</p>
                                                            {
                                                                comment.likes != null && comment.likes.length > 0 ?
                                                                    <div className="badge bg-white shadow text-black round px-half float-right mr-half cursor-pointer" style={{ marginTop: -5 }}
                                                                        onClick={() => {
                                                                            if (comment.likes != null && comment.likes.length > 0) {
                                                                                setLikeDetails(comment.likes);
                                                                                setShowLikeDetails(true)
                                                                            }
                                                                        }}
                                                                    >
                                                                        <FaHeart color={'#ff084a'} size={12} className="mr-half" />
                                                                        <span className="font-small-1 text-bold-600">{comment.likes.length}</span>
                                                                    </div>
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>
                                                        <div id={`comment-edit-view-${comment.id}`} className="hidden">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <div className="flex-column w-100">
                                                                    <fieldset className="form-group mb-0 mx-auto w-100">
                                                                        <input
                                                                            onKeyPress={(event) => {
                                                                                if (event.key == 'Enter') {
                                                                                    if (event.target.value.length > 0) {
                                                                                        setActionPostIndex(indexActivity)
                                                                                        setActionPostCommentId(comment.id)
                                                                                        props.actionHomeActivityFeedCommentEdit({
                                                                                            post_id: activity.id,
                                                                                            id: comment.id,
                                                                                            comment: $(`#post-comment-edit-${comment.id}`).val()
                                                                                        })
                                                                                    }
                                                                                }
                                                                            }}
                                                                            id={`post-comment-edit-${comment.id}`}
                                                                            className="form-control round"
                                                                            placeholder="write comment here"
                                                                        />
                                                                    </fieldset>
                                                                </div>
                                                                <div className="flex-column ml-auto">
                                                                    <RiSendPlaneLine
                                                                        size={24}
                                                                        className="ml-half blue-grey lighten-1"
                                                                        onClick={() => {
                                                                            // alert($(`#post-${activity.id}`).val())
                                                                            if ($(`#post-comment-edit-${comment.id}`).val() != null && $(`#post-comment-edit-${comment.id}`).val().length > 0) {
                                                                                setActionPostIndex(indexActivity)
                                                                                setActionPostCommentId(comment.id)
                                                                                props.actionHomeActivityFeedCommentEdit({
                                                                                    post_id: activity.id,
                                                                                    id: comment.id,
                                                                                    comment: $(`#post-comment-edit-${comment.id}`).val()
                                                                                })
                                                                            } else {
                                                                                Swal.fire({
                                                                                    title: 'Post Comment',
                                                                                    text: 'Please enter comment',
                                                                                    icon: 'error'
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <button type="button" className="btn text-primary"
                                                                onClick={() => {
                                                                    $(`#comment-edit-view-${comment.id}`).addClass('hidden')
                                                                    $(`#comment-view-${comment.id}`).removeClass('hidden')
                                                                }}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                        <div className="d-flex flex-row ml-1 align-items-center">
                                                            <div className="flex-column flex-column font-small-2 text-light ">{comment.timeSince}</div>
                                                            <div className="flex-column">
                                                                <button
                                                                    type="button"
                                                                    className="btn text-bold-400 text-black"
                                                                    onClick={() => {
                                                                        var is_like_comment = false
                                                                        if (comment.likes != null && comment.likes.length > 0) {
                                                                            var indexLike = comment.likes.findIndex((like, index) => like.like.user_id === props.auth.getUser().user_id)
                                                                            if (indexLike > -1) {
                                                                                is_like_comment = true
                                                                            }
                                                                        }
                                                                        if (!is_like_comment) {
                                                                            setActionPostIndex(indexActivity)
                                                                            props.actionHomeActivityFeedCommentLike({
                                                                                post_id: activity.id,
                                                                                comment_id: comment.id,
                                                                                like: true
                                                                            })
                                                                        } else {
                                                                            setActionPostIndex(indexActivity)
                                                                            props.actionHomeActivityFeedCommentLike({
                                                                                post_id: activity.id,
                                                                                comment_id: comment.id,
                                                                                like: false
                                                                            })
                                                                        }
                                                                    }}
                                                                >
                                                                    {(comment.likes != null && comment.likes.length > 0 && comment.likes.findIndex((like, index) => like.like.user_id === props.auth.getUser().user_id) > -1) ? 'Unlike' : 'Like'}
                                                                </button>
                                                            </div>
                                                            {
                                                                comment.user_id === props.auth.getUser().user_id ?
                                                                    <>
                                                                        <div className="flex-column">
                                                                            <button
                                                                                type="button"
                                                                                className="btn text-bold-400 text-black pl-0"
                                                                                onClick={() => {
                                                                                    $(`#comment-edit-view-${comment.id}`).removeClass('hidden')
                                                                                    $(`#post-comment-edit-${comment.id}`).val(comment.comment)
                                                                                    $(`#comment-view-${comment.id}`).addClass('hidden')
                                                                                }}
                                                                            >
                                                                                Edit
                                                                            </button>
                                                                        </div>
                                                                        <div className="flex-column">
                                                                            <button
                                                                                type="button"
                                                                                className="btn text-bold-400 text-black pl-0"
                                                                                onClick={() => {
                                                                                    Swal.fire({
                                                                                        title: 'Delete Post Comment',
                                                                                        text: 'Are you sure, you want to delete comment?',
                                                                                        showCancelButton: true,
                                                                                        cancelButtonText: 'No',
                                                                                        showConfirmButton: true,
                                                                                        confirmButtonText: 'Yes',
                                                                                    }).then(result => {
                                                                                        if (result.isConfirmed) {
                                                                                            setActionPostIndex(indexActivity)
                                                                                            props.actionHomeActivityFeedCommentDelete({
                                                                                                id: comment.id,
                                                                                                post_id: activity.id,
                                                                                            })
                                                                                        }
                                                                                    })
                                                                                }}
                                                                            >
                                                                                Delete
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <></>
                            }
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex flex-row align-items-center">
                                        <div className="flex-column">
                                            {
                                                (props.auth.getUser().profile_photo != null && props.auth.getUser().profile_photo.length > 0) ?
                                                    <img
                                                        src={props.auth.getUser().profile_photo}
                                                        className="rounded-circle img-border"
                                                        alt="Card"
                                                        width={50}
                                                        height={50}
                                                        style={{ border: '5px solid #FFF' }}
                                                    />
                                                    :
                                                    <>
                                                        <div
                                                            className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-medium-3"
                                                            style={{ border: '5px solid #FFF', height: 50, width: 50 }}
                                                        >
                                                            {generateInitialLettersString(props.auth.getUser().first_name + ' ' + props.auth.getUser().last_name)}
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                        <div className="flex-column w-100">
                                            <div className="d-flex flex-row align-items-center">
                                                <div className="flex-column w-100">
                                                    <fieldset className="form-group mb-0 mx-auto w-100">
                                                        <input
                                                            id={`post-${activity.id}`}
                                                            className="form-control round"
                                                            placeholder="write comment here"
                                                            maxLength={5000}
                                                            onKeyPress={(event) => {
                                                                if (event.key == 'Enter') {
                                                                    if (event.target.value.length > 0) {
                                                                        setActionPostIndex(indexActivity)
                                                                        props.actionHomeActivityFeedCreateComment({
                                                                            post_id: activity.id,
                                                                            comment: $(`#post-${activity.id}`).val()
                                                                        })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </fieldset>
                                                </div>
                                                <div className="flex-column ml-auto">
                                                    <RiSendPlaneLine
                                                        size={24}
                                                        className="ml-half blue-grey lighten-1"
                                                        onClick={() => {
                                                            // alert($(`#post-${activity.id}`).val())
                                                            if ($(`#post-${activity.id}`).val() != null && $(`#post-${activity.id}`).val().length > 0) {
                                                                setActionPostIndex(indexActivity)
                                                                props.actionHomeActivityFeedCreateComment({
                                                                    post_id: activity.id,
                                                                    comment: $(`#post-${activity.id}`).val()
                                                                })
                                                            } else {
                                                                Swal.fire({
                                                                    title: 'Post Comment',
                                                                    text: 'Please enter comment',
                                                                    icon: 'error'
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            });
        }
        return arrActivities
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 mt-1 pb-2">
                        <div
                            className="card rounded w-100"
                            onClick={() => {
                                setCreatePost(true);
                            }}
                        >
                            <div className="card-body custom-card-body-activity">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex flex-row align-items-center">
                                            <div className="flex-column">
                                                {
                                                    (props.auth.getUser().profile_photo != null && props.auth.getUser().profile_photo.length > 0) ?
                                                        <img
                                                            src={props.auth.getUser().profile_photo}
                                                            className="rounded-circle img-border"
                                                            alt="Card"
                                                            width={50}
                                                            height={50}
                                                            style={{ border: '5px solid #FFF' }}
                                                        />
                                                        :
                                                        <>
                                                            <div
                                                                className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-medium-3"
                                                                style={{ border: '5px solid #FFF', height: 50, width: 50 }}
                                                            >
                                                                {generateInitialLettersString(props.auth.getUser().first_name + ' ' + props.auth.getUser().last_name)}
                                                            </div>
                                                        </>
                                                }
                                            </div>
                                            <div className="flex-column w-100">
                                                <fieldset className="form-group mb-0">
                                                    <input className="form-control border-0 round bg-blue-grey bg-lighten-4 blue-grey darken-4 font-medium-1 cursor-pointer" disabled value={`What's on your mind, ${props.auth.getUser().first_name}?`} />
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            activityFeeds != null && activityFeeds.length > 0 ?
                                renderActivities() :
                                <div id="post_loader" className="text-center">
                                    <div className="spinner-border spinner-border-sm ml-half text-primary"></div>
                                </div>
                        }
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 p-1 pb-2">
                        <div className="mr-2">
                            <div className="dashboard-menu-top px-1 shadow mb-2">
                                {
                                    getTicker != null && getTicker.length > 0 ?
                                        <div className="d-flex justify-content-between justify-content-lg-between w-100">
                                            <marquee>
                                                <p className="text-white pt-1">
                                                    {getTicker}
                                                </p>
                                            </marquee>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                            <HomeEvents {...props} />
                            <UpcomingMeetings {...props} />
                        </div>
                    </div>
                </div>
            </div>
            <LikeDetails
                show={showLikeDetails}
                data={likeDetails}
                handleClose={() => {
                    setLikeDetails([])
                    setShowLikeDetails(false)
                }}
            />
            <CreatePost
                show={showCreatePost}
                handleClose={(newPost) => {
                    if (newPost != null) {
                        toast.success('Post created successfully')
                        var arrPosts = Object.assign([], activityFeeds)
                        arrPosts.unshift(newPost)
                        setActivityFeeds(arrPosts)
                    }
                    setCreatePost(false)
                }}
            />
            <PostDetails
                show={showPostDetails}
                data={actionPostIndex > -1 ? activityFeeds[actionPostIndex] : null}
                handleClose={(post) => {
                    if (post != null) {
                        var arrPost = Object.assign([], activityFeeds)
                        arrPost[actionPostIndex] = post
                        setActivityFeeds(arrPost)
                        setActionPostIndex(-1)
                    }
                    setShowPostDetails(false)
                }}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    var ActivityFeedList = null;
    var ActivityFeedLike = null;
    var ActivityFeedDelete = null;
    var ActivityFeedCreateComment = null;
    var ActivityFeedCommentLike = null;
    var ActivityFeedCommentDelete = null;
    var ActivityFeedCommentEdit = null;
    var GetTicker = null;

    if (
        state.HomeReducer != null &&
        Object.keys(state.HomeReducer).length > 0 &&
        state.HomeReducer.constructor === Object
    ) {
        switch (state.HomeReducer.tag) {
            case ACTION_HOME_ACTIVITYFEEDLIST_SUCCESS:
                ActivityFeedList = Object.assign({}, state.HomeReducer.data);
                delete state.HomeReducer;
                return { ActivityFeedList };
            case ACTION_HOME_ACTIVITYFEEDLIKE_SUCCESS:
                ActivityFeedLike = Object.assign({}, state.HomeReducer.data);
                delete state.HomeReducer;
                return { ActivityFeedLike };
            case ACTION_HOME_ACTIVITYFEEDDELETE_SUCCESS:
                ActivityFeedDelete = Object.assign({}, state.HomeReducer.data);
                delete state.HomeReducer;
                return { ActivityFeedDelete };
            case ACTION_HOME_ACTIVITYFEEDCREATECOMMENT_SUCCESS:
                ActivityFeedCreateComment = Object.assign({}, state.HomeReducer.data);
                delete state.HomeReducer;
                return { ActivityFeedCreateComment };
            case ACTION_HOME_ACTIVITYFEEDCOMMENTLIKE_SUCCESS:
                ActivityFeedCommentLike = Object.assign({}, state.HomeReducer.data);
                delete state.HomeReducer;
                return { ActivityFeedCommentLike };
            case ACTION_HOME_ACTIVITYFEEDCOMMENTDELETE_SUCCESS:
                ActivityFeedCommentDelete = Object.assign({}, state.HomeReducer.data);
                delete state.HomeReducer;
                return { ActivityFeedCommentDelete };
            case ACTION_HOME_ACTIVITYFEEDCOMMENTEDIT_SUCCESS:
                ActivityFeedCommentEdit = Object.assign({}, state.HomeReducer.data);
                delete state.HomeReducer;
                return { ActivityFeedCommentEdit };
            case ACTION_HOME_GET_TICKER_SUCCESS:
                GetTicker = Object.assign({}, state.HomeReducer.data);
                delete state.HomeReducer;
                return { GetTicker };
            default:

        }
    }
    return {
        ActivityFeedList,
        ActivityFeedLike,
        ActivityFeedDelete,
        ActivityFeedCreateComment,
        ActivityFeedCommentLike,
        ActivityFeedCommentDelete,
        ActivityFeedCommentEdit,
        GetTicker,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionHomeActivityFeedList,
            actionHomeActivityFeedLike,
            actionHomeActivityFeedDelete,
            actionHomeActivityFeedCreateComment,
            actionHomeActivityFeedCommentLike,
            actionHomeActivityFeedCommentDelete,
            actionHomeActivityFeedCommentEdit,
            actionHomeGetTicker,
        },
        dispatch
    );
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(HomeDetails))
