import { ACTION_GET_ASSET_USERS_SUCCESS, ACTION_TASK_POOL_VIEW_SUCCESS } from "./action";
import { ACTION_TASK_ASSIGN_TO_ME_SUCCESS } from "./action";
import createReducer from "../../../../../../reducers/createReducer";
const INITIAL_STATE = {
    data: [],
    loading: false,
};

const TaskPoolViewReducer = createReducer(INITIAL_STATE, {
    [ACTION_TASK_POOL_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_TASK_POOL_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_TASK_ASSIGN_TO_ME_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_TASK_ASSIGN_TO_ME_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_ASSET_USERS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_ASSET_USERS_SUCCESS,
                loading: false,
            }
        );
    }
});

export default TaskPoolViewReducer;