import React, { useEffect, useState } from 'react';
import { withAuth } from 'react-auth-guard';
import { DropDownListComponent, AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { FiPlus, FiUser, FiPhone, FiTrash2, FiEdit, FiMail, FiShield, FiEye } from 'react-icons/fi';
import { FaUserCircle } from 'react-icons/fa';
import { IoShieldCheckmarkOutline } from 'react-icons/io5';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import ContactDetails from '../../../../../../../component/form/contact_details';
import PolicyDetailModal from '../../../../../../../component/form/policy_details';
import ProposalDetailModal from '../../../../../../../component/popups/proposal_details';
import Swal from 'sweetalert2';
import moment from 'moment';
import { useForm, Controller } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from '../../../../../../../component/form/error_label';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { KEY_GLOBAL_SEARCH, KEY_TOAST_MESSAGE, getData, setData } from '../../../../../../../utils/preferences';
import { ERROR, PRIMARY_COLOR, UNAUTHORIZED, VALIDATION_ERROR } from '../../../../../../../constants';
import { actionCompanyGroup, ACTION_MASTER_COMPANY_GROUP_SUCCESS } from "./action";
import { actionGetGroupCompanies, ACTION_COMPANY_SUCCESS } from "./action";
import { actionCustomerCategory, ACTION_MASTER_CUSTOMER_CATEGORY_SUCCESS } from "./action";
import { actionIndustrialSegment, ACTION_MASTER_INDUSTRIAL_SEGMENT_SUCCESS } from "./action";
import { actionFormOfOrganization, ACTION_MASTER_FORM_OF_ORGANIZATION_SUCCESS } from "./action";
import { actionLeadSource, ACTION_MASTER_LEAD_SOURCE_SUCCESS } from "./action";
import { actionAssignRM, ACTION_MASTER_ASSIGN_RM_SUCCESS } from "./action";
import { actionStates, ACTION_MASTER_LEAD_STATES_SUCCESS } from "./action";
import { actionCities, ACTION_MASTER_LEAD_CITIES_SUCCESS } from "./action";
import { actionCorporateCustomerSave, ACTION_CORPORATE_CUSTOMER_SAVE_SUCCESS } from "./action";
import { actionAssignRetention, ACTION_MASTER_ASSIGN_RETENTION_SUCCESS } from "./action";
import { actionGetClientSearchList, ACTION_GET_CLIENT_SEARCH_LIST_SUCCESS } from "./action";
import { actionGetOpportunityQuestion, ACTION_GET_OPPORTUNITY_QUESTION_SUCCESS } from "./action";
import { toast } from 'react-toastify';

const CorporateForm = (props) => {

    const { register, handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm({
        defaultValues: {
            multi_branch: 1
        }
    });

    const [clientRequiredFields, setClientRequiredFields] = useState(false);
    const [contactDetails, setContactDetails] = useState([]);
    const [policyDetails, setPolicyDetails] = useState([]);
    const [showContactDetails, setShowContactDetails] = useState(false)
    const [showContact, setShowContact] = useState(false);
    const [showPolicyDetails, setShowPolicyDetails] = useState(false)
    const [companyGroup, setCompanyGroup] = useState([])
    const [getCompanyName, setGetCompanyName] = useState([])
    const [customerCategory, setCustomerCategory] = useState([])
    const [masterStates, setMasterStates] = useState([])
    const [masterCities, setMasterCities] = useState([])
    const [industrialSegment, setIndustrialSegment] = useState([])
    const [formOfOrganization, setFormOfOrganization] = useState([])
    const [leadSource, setLeadSource] = useState({})
    const [assignRM, setAssignRM] = useState({})
    const [assignRetention, setAssignRetention] = useState({})
    const [selectedContactIndex, setSelectedContactIndex] = useState(null);
    const [selectedPolicyIndex, setSelectedPolicyIndex] = useState(null);
    const [showProposalDetails, setShowProposalDetails] = useState(false);
    const [assignRMId, setAssignRMId] = useState(null);

    const initialRender = () => {

        props.actionCompanyGroup();
        props.actionCustomerCategory();
        props.actionStates();
        props.actionIndustrialSegment();
        props.actionFormOfOrganization();
        props.actionLeadSource();
        props.actionAssignRM({ user_id: props.auth.getUser().user_id });
        props.actionAssignRetention({ user_id: props.auth.getUser().user_id });

        $("input[type='radio'][name='customer_group_type'][value='Existing Customer Group']").prop('checked', true);
    }
    useEffect(initialRender, []);

    const onMasterCompanyGroupUpdate = () => {
        const { CompanyGroup } = props;
        var CompanyGroupResponse = Object.assign({}, CompanyGroup);
        if (CompanyGroupResponse.result === true) {
            setCompanyGroup(CompanyGroupResponse.response);
        } else {
            switch (CompanyGroupResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterCompanyGroupUpdate, [props.CompanyGroup]);

    const onMasterGetCompanyNameUpdate = () => {
        const { GetCompanyName } = props;
        var GetCompanyNameResponse = Object.assign({}, GetCompanyName);
        if (GetCompanyNameResponse.result === true) {
            setGetCompanyName(GetCompanyNameResponse.response);
        } else {
            switch (GetCompanyNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterGetCompanyNameUpdate, [props.GetCompanyName]);

    const onMasterCustomerCategoryUpdate = () => {
        const { CustomerCategory } = props;
        var CustomerCategoryResponse = Object.assign({}, CustomerCategory);
        if (CustomerCategoryResponse.result === true) {
            setCustomerCategory(CustomerCategoryResponse.response);
        } else {
            switch (CustomerCategoryResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterCustomerCategoryUpdate, [props.CustomerCategory]);

    const onMasterStatesUpdate = () => {
        const { States } = props;
        var StatesResponse = Object.assign({}, States);
        if (StatesResponse.result === true) {
            setMasterStates(StatesResponse.response);
        } else {
            switch (StatesResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterStatesUpdate, [props.States]);

    const onMasterCitiesUpdate = () => {
        const { Cities } = props;
        var CitiesResponse = Object.assign({}, Cities);
        if (CitiesResponse.result === true) {
            setMasterCities(CitiesResponse.response);
        } else {
            switch (CitiesResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterCitiesUpdate, [props.Cities]);

    const onMasterIndustrialSegmentUpdate = () => {
        const { IndustrialSegment } = props;
        var IndustrialSegmentResponse = Object.assign({}, IndustrialSegment);
        if (IndustrialSegmentResponse.result === true) {
            setIndustrialSegment(IndustrialSegmentResponse.response);
        } else {
            switch (IndustrialSegmentResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterIndustrialSegmentUpdate, [props.IndustrialSegment]);

    const onMasterFormofOrganizationUpdate = () => {
        const { FormofOrganization } = props;
        var FormofOrganizationResponse = Object.assign({}, FormofOrganization);
        if (FormofOrganizationResponse.result === true) {
            setFormOfOrganization(FormofOrganizationResponse.response);
        } else {
            switch (FormofOrganizationResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterFormofOrganizationUpdate, [props.FormofOrganization]);

    const onMasterLeadSourceUpdate = () => {
        const { LeadSource } = props;
        var LeadSourceResponse = Object.assign({}, LeadSource);
        if (LeadSourceResponse.result === true) {
            setLeadSource(LeadSourceResponse.response);
        } else {
            switch (LeadSourceResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterLeadSourceUpdate, [props.LeadSource]);

    const onMasterAssignRMUpdate = () => {
        const { AssignRM } = props;
        var AssignRMResponse = Object.assign({}, AssignRM);
        if (AssignRMResponse.result === true) {

            var arrAssignRM = Object.assign([], AssignRMResponse.response);

            setAssignRM(arrAssignRM);
        } else {
            switch (AssignRMResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    break;
                default:
            }
        }
    }
    useEffect(onMasterAssignRMUpdate, [props.AssignRM]);

    const onMasterAssignRetentionUpdate = () => {
        const { AssignRetention } = props;
        var AssignRetentionResponse = Object.assign({}, AssignRetention);
        if (AssignRetentionResponse.result === true) {
            var arrAssignRetention = Object.assign([], AssignRetentionResponse.response);
            setAssignRetention(arrAssignRetention);
        } else {
            switch (AssignRetentionResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMasterAssignRetentionUpdate, [props.AssignRetention]);

    const onCorporateCustomerSaveUpate = () => {
        const { CorporateCustomer } = props;
        var CorporateCustomerResponse = Object.assign({}, CorporateCustomer);
        $("#save-as-lead").prop("disabled", false);
        $("#convert-to-customer").prop("disabled", false);
        if (CorporateCustomerResponse.result === true) {
            if (getValues('clientOrLead') === 'lead') {
                $("#save-as-lead-loader").addClass("hidden");
                setData(KEY_TOAST_MESSAGE, "Lead Saved Successfully");
                props.history.push('/leads');

            } else {
                $("#convert-to-customer-loader").addClass("hidden");
                setData(KEY_TOAST_MESSAGE, "Client Saved Successfully");
                props.actionGetClientSearchList();
                props.history.push('/client');
            }

        } else {
            $("#save-as-lead-loader").addClass("hidden");
            $("#convert-to-customer-loader").addClass("hidden");
            switch (CorporateCustomerResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case VALIDATION_ERROR:
                    var strMessage = '';

                    if (CorporateCustomerResponse.message.hasOwnProperty('name')) {
                        strMessage += CorporateCustomerResponse.message.name + "<br>";
                    }
                    if (CorporateCustomerResponse.message.hasOwnProperty('email')) {
                        strMessage += CorporateCustomerResponse.message.email + "<br>";
                    }
                    if (CorporateCustomerResponse.message.hasOwnProperty('mobile')) {
                        strMessage += CorporateCustomerResponse.message.mobile + "<br>";
                    }

                    if (strMessage.length > 0) {
                        Swal.fire({
                            title: 'Error',
                            icon: 'error',
                            html: strMessage,
                            confirmButtonColor: PRIMARY_COLOR,

                        }).then((result) => {
                        });
                    }
                    break;
                case ERROR:
                    toast.error(CorporateCustomerResponse.message);
                    break;
                default:
            }
        }
    }
    useEffect(onCorporateCustomerSaveUpate, [props.CorporateCustomer]);

    const onGetClientSearchListUpdate = () => {
        const { GetClientSearchList } = props;
        var GetClientSearchListResponse = Object.assign({}, GetClientSearchList);
        if (GetClientSearchListResponse.result === true) {
            setData(KEY_GLOBAL_SEARCH, JSON.stringify(GetClientSearchListResponse.response));
        } else {
            switch (GetClientSearchListResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onGetClientSearchListUpdate, [props.GetClientSearchList]);

    const onGetOpportunityQuestionUpdate = () => {
        const { GetOpportunityQuestion } = props
        var GetOpportunityQuestionResponse = Object.assign({}, GetOpportunityQuestion);
        if (GetOpportunityQuestionResponse.result === true) {

        } else {
            switch (GetOpportunityQuestionResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onGetOpportunityQuestionUpdate, [props.GetOpportunityQuestion])

    const onSubmit = (data) => {
        if (data.clientOrLead == 'client' && getValues('created_by') == 0) {

            Swal.fire({
                title: 'Message',
                text: 'Please select the RM to Convert to Customer',
                confirmButtonColor: PRIMARY_COLOR,
            }).then(success => {

            })
        } else {
            data.vertical = 'corporate';
            data.client_of = 'insurance';

            data.contact_details = contactDetails;
            var arrTemp = [];
            policyDetails.map((item) => {
                arrTemp.push({
                    department_id: item.department_id,
                    policy_type_id: item.policy_type_id,
                    insurer_id: item.oppurtunity_type != 'Fresh' ? item.insurer_id : '',
                    brokerage_amnt: item.brokerage_amnt,
                    product_specialist_id: item.product_specialist_id,
                    is_product_specialist_required: item.is_product_specialist_required,
                    expected_premium: item.expected_premium,
                    probability: item.probability,
                    // expiring_date: item.oppurtunity_type != 'Fresh' && item.lead_expiry_date != null && item.lead_expiry_date != '00/00/0000' ? item.lead_expiry_date : null,
                    expiring_date: item.lead_expiry_date != null && item.lead_expiry_date != '00/00/0000' ? item.lead_expiry_date : null,
                    client_remark: item.client_remark,
                    oppurtunity_type: item.oppurtunity_type,
                    intermediary_type: item.oppurtunity_type != 'Fresh' ? item.intermediary_type : '',
                    intermediary_value: item.oppurtunity_type != 'Fresh' ? item.intermediary_value : '',
                    opportunity_questions: item.opportunity_questions,
                    id: item.id
                })
            })
            data.policy_details = arrTemp;

            data.primary_contact = {
                contact_name: getValues('contact_name'),
                contact_designation: getValues('contact_designation'),
                contact_phone: getValues('contact_phone'),
                contact_email: getValues('contact_email'),
            };

            if (Number(data.created_by) === 0) {
                data.created_by = "";
            }

            /**
             * API : /api/client/create
             * METHOD : POST
             * Description : Add Client Record 
             * INPUT : Client Object 
             */
            if (getValues('clientOrLead') === 'lead') {
                $("#save-as-lead-loader").removeClass("hidden");
                $("#save-as-lead").prop("disabled", true);
            } else {
                $("#convert-to-customer-loader").removeClass("hidden");
                $("#convert-to-customer").prop("disabled", true);
            }
            props.actionCorporateCustomerSave(data);
        }
    }

    const renderContactDetails = () => {
        var arrContactDetails = []
        if (contactDetails != null && contactDetails.length > 0) {
            contactDetails.forEach((contact, indexContact) => {
                arrContactDetails.push(
                    <div class="col-12 px-0 mb-1 border-bottom">
                        <div className="float-left">
                            <div className="d-flex flex-row">
                                <div className="flex-column mr-half">
                                    <FaUserCircle size={50} className="blue-grey" />
                                </div>
                                <div className="flex-column">
                                    <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1">
                                        {contact.contact_name}
                                    </p>
                                    <div class="font-small-3 text-bold-700">
                                        {contact.contact_designation}
                                    </div>
                                    <div className="d-flex flex-row align-items-center">
                                        <div className={`flex-column ${contact.contact_phone.length === 0 ? 'hidden' : ''}`}>
                                            <FiPhone size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column mr-1 ${contact.contact_phone.length === 0 ? 'hidden' : ''}`}>
                                            <div class="font-small-3 secondary">{contact.contact_phone}</div>
                                        </div>
                                        <div className={`flex-column ${contact.contact_email.length === 0 ? 'hidden' : ''}`}>
                                            <FiMail size={12} className="mr-half primary" />
                                        </div>
                                        <div className={`flex-column ${contact.contact_email.length === 0 ? 'hidden' : ''}`}>
                                            <div class="d-inline font-small-3 secondary">{contact.contact_email}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="float-right">
                            <button
                                type="button"
                                class="btn pt-0"
                                onClick={() => {
                                    setSelectedContactIndex(indexContact)
                                    setShowContactDetails(true)
                                    setShowContact(true);
                                }}
                            >
                                <FiEdit size={16} />
                            </button>
                            <button type="button" class="btn pt-0"
                                onClick={() => {
                                    Swal.fire({
                                        icon: 'warning',
                                        text: 'Are you sure, you want to delete this contact?',
                                        cancelButtonText: "Cancel",
                                        showCancelButton: true,
                                    }).then(({ isConfirmed }) => {
                                        if (isConfirmed) {
                                            var arrContactDetails = Object.assign([], contactDetails)
                                            arrContactDetails.splice(indexContact, 1)
                                            setContactDetails(arrContactDetails)
                                        }
                                    })
                                }}
                            >
                                <FiTrash2 size={16} />
                            </button>
                        </div>
                        <div className="divider"></div>
                    </div>
                )
            })
        }
        return arrContactDetails
    }

    const renderPolicyDetails = () => {
        var arrPolicyDetails = []
        if (policyDetails != null && policyDetails.length > 0) {
            policyDetails.forEach((policy, indexPolicy) => {
                arrPolicyDetails.push(
                    <div class="col-12 px-0 mb-1 border-bottom">
                        <div className="float-left">
                            <div className="d-flex flex-row pb-1">
                                <div className="flex-column mr-half">
                                    {
                                        (policy.insurer_id != "" && policy.insurer_id != null && Number(policy.insurer_id) != 0 && policy.oppurtunity_type != 'Fresh') ?
                                            <img src={`/assets/images/insurance_companies/${policy.insurer_id}.png`} className="rounded-circle" style={{ width: 50, height: 50, objectFit: 'cover' }} alt="" />
                                            :
                                            <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-3" style={{ width: 50, height: 50 }}>
                                                <IoShieldCheckmarkOutline size={30} />
                                            </div>
                                    }
                                </div>
                                <div className="flex-column">
                                    <p className="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1">{policy.oppurtunity_type != 'Fresh' ? policy.insurer_name : ''}<div className="badge badge-success bg-accent-1 round font-small-2 px-1 ml-half">{policy.policy_type}</div></p>
                                    <div className={`font-small-3 text-bold-700`}><span className="text-secondary text-bold-500 mr-half">Opportunity Type: </span>{policy.oppurtunity_type}</div>
                                    <div className={`font-small-3 text-bold-700`}><span className="text-secondary text-bold-500 mr-half">Department: </span>{policy.department_name}</div>
                                    <div className={`font-small-3 text-bold-700  ${policy.oppurtunity_type != 'Fresh' ? '' : 'hidden'}`}><span className={`text-secondary text-bold-500 mr-half`}>Next Renewal on</span>{policy.lead_expiry_date !== null && moment(policy.lead_expiry_date).isValid() ? moment(policy.lead_expiry_date).format("MMM, DD YYYY") : '-'}</div>
                                    <div className={`font-small-3 text-bold-700`}><span className="text-secondary text-bold-500 mr-half">Intermediary Type: </span>{policy.intermediary_type}</div>
                                    <div className={`font-small-3 text-bold-700`}><span className="text-secondary text-bold-500 mr-half">Intermediary Details: </span>{policy.intermediary_value}</div>
                                    <div className={`font-small-3 text-bold-700 ${policy.client_remark != null && policy.client_remark != "" ? "" : "hidden"}`}><span className="text-secondary text-bold-500 mr-half">Remark: </span>{policy.client_remark}</div>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-md-flex d-lg-flex float-right">
                            <button
                                type="button"
                                class="btn pt-0"
                                onClick={() => {
                                    setSelectedPolicyIndex(indexPolicy)
                                    setShowProposalDetails(true)
                                }}
                            >
                                <FiEye size={16} />
                            </button>
                            <button
                                type="button"
                                class="btn pt-0"
                                onClick={() => {
                                    setSelectedPolicyIndex(indexPolicy)
                                    setShowPolicyDetails(true)
                                }}
                            >
                                <FiEdit size={16} />
                            </button>
                            <button type="button" class="btn pt-0"
                                onClick={() => {
                                    Swal.fire({
                                        icon: 'warning',
                                        text: 'Are you sure, you want to delete this policy details?',
                                        cancelButtonText: "Cancel",
                                        showCancelButton: true,
                                    }).then(({ isConfirmed }) => {
                                        if (isConfirmed) {
                                            var arrPolicyDetails = Object.assign([], policyDetails)
                                            arrPolicyDetails.splice(indexPolicy, 1)
                                            setPolicyDetails(arrPolicyDetails)
                                        }
                                    })
                                }}
                            >
                                <FiTrash2 size={16} />
                            </button>
                        </div>
                        <div className="divider"></div>
                    </div>
                )
            })
        }
        return arrPolicyDetails
    }

    return (
        <>
            <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-body">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <h4 className="d-flex align-items-center form-section my-2 black">
                                <HiOutlineOfficeBuilding size={16} />&nbsp;Company Information
                            </h4>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">Company Group Name&nbsp;<span className="danger">*</span></label>
                                <Controller
                                    name="company_group_name"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <AutoCompleteComponent
                                            {...field}
                                            id="company_group_name"
                                            name="company_group_name"
                                            dataSource={companyGroup}
                                            fields={{ value: 'name' }}
                                            value={field.value}
                                            placeholder="Select Company Group Name"
                                            change={(event) => {
                                                if (event.itemData != null) {
                                                    field.onChange(event.itemData.name)
                                                    props.actionGetGroupCompanies({
                                                        client_group_id: event.itemData.id
                                                    })
                                                }
                                            }}
                                        />
                                    }
                                    rules={{
                                        required: 'Please Select Company Group Name'
                                    }}
                                />
                                {errors.company_group_name && <ErrorLabel message={errors.company_group_name?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <fieldset className="form-group">
                                <label className="label-control">Company Name&nbsp;<span className="danger">*</span></label>
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <AutoCompleteComponent
                                            {...field}
                                            id="name"
                                            name="name"
                                            dataSource={getCompanyName}
                                            fields={{ value: 'company_name' }}
                                            value={field.value}
                                            placeholder="Select Company Name"
                                            change={(event) => {
                                                if (event.itemData != null) {
                                                    field.onChange(event.itemData.company_name)
                                                    setAssignRMId(event.itemData?.created_by)
                                                }
                                            }}
                                        />
                                    }
                                    rules={{
                                        required: 'Please Enter Company Name'
                                    }}
                                />
                                {errors.name && <ErrorLabel message={errors.name?.message} />}
                            </fieldset>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="form-group">
                                <label className="label-control">Address&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                <textarea
                                    className="form-control"
                                    id="address"
                                    name="address"
                                    placeholder="Enter Address"
                                    rows="3"
                                    {...register('address', {
                                        required: clientRequiredFields ? 'Please Enter Address' : false,
                                    })}
                                />
                                {errors.address && <ErrorLabel message={errors.address?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">Customer Category&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                <Controller
                                    name="category"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <DropDownListComponent
                                            {...field}
                                            dataSource={customerCategory}
                                            id="category"
                                            name="category"
                                            fields={{ text: 'title', value: 'value' }}
                                            allowFiltering={true}
                                            placeholder="Select Customer Category"
                                            value={field.value}
                                            change={(event) => field.onChange(event.itemData.value)}
                                        />
                                    }
                                    rules={{
                                        required: clientRequiredFields ? 'Please Select Customer Category' : false
                                    }}
                                />
                                {errors.category && <ErrorLabel message={errors.category?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">Email ID&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                <input
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    placeholder="Enter Email ID"
                                    {...register('email', {
                                        required: clientRequiredFields ? 'Please Enter Email' : false,
                                        pattern: {
                                            value: /^(?!.*@.*pincinsure)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                            message: 'Please enter valid Email Id'
                                        },
                                    }
                                    )}
                                />
                                {errors.email && <ErrorLabel message={errors.email?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">State&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                <Controller
                                    name="state"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <DropDownListComponent
                                            {...field}
                                            dataSource={masterStates}
                                            id="state"
                                            name="state"
                                            fields={{ text: 'name', value: 'id' }}
                                            allowFiltering={true}
                                            placeholder="Select State"
                                            value={field.value}
                                            change={(event) => {
                                                if (event.itemData != null) {
                                                    field.onChange(event.itemData.id)
                                                    setMasterCities([])
                                                    props.actionCities({ state_ids: [event.itemData.id] })
                                                }
                                            }}
                                        />
                                    }
                                    rules={{
                                        required: clientRequiredFields ? 'Please Select State' : false
                                    }}
                                />
                                {errors.state && <ErrorLabel message={errors.state?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">City&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                <Controller
                                    name="city_id"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <DropDownListComponent
                                            {...field}
                                            dataSource={masterCities}
                                            id="city_id"
                                            name="city_id"
                                            fields={{ text: 'name', value: 'id' }}
                                            allowFiltering={true}
                                            value={field.value}
                                            change={(event) => {
                                                if (event.itemData != null) {
                                                    field.onChange(event.itemData.id)
                                                }
                                            }}
                                        />
                                    }
                                    rules={{
                                        required: clientRequiredFields ? 'Please Select City' : false
                                    }}
                                />
                                {errors.city_id && <ErrorLabel message={errors.city_id?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">Pincode&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                <input
                                    className="form-control"
                                    id="pincode"
                                    name="pincode"
                                    type="text"
                                    pattern="[0-9]"
                                    maxLength={6}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    placeholder="Enter Pincode (6 digit)"
                                    {...register('pincode', {
                                        pattern: {
                                            value: /^\d{6}$/,
                                            message: 'Please enter 6 digit valid PIN Code'
                                        },
                                        required: clientRequiredFields ? 'Please Enter Pincode' : false
                                    })}
                                />
                                {errors.pincode && <ErrorLabel message={errors.pincode?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">Mobile&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                <input
                                    className="form-control"
                                    id="mobile"
                                    name="mobile"
                                    maxLength={10}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    placeholder="Enter Mobile Number"
                                    {...register('mobile', {
                                        pattern: {
                                            value: /^[6789]\d{9}$/,
                                            message: 'Please enter 10 digit valid Mobile Number'
                                        },
                                        required: clientRequiredFields ? 'Please Enter Mobile Number' : false
                                    })}
                                />
                                {errors.mobile && <ErrorLabel message={errors.mobile?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">Pan No</label>
                                <input
                                    className="form-control"
                                    id="pan"
                                    name="pan"
                                    maxLength={10}
                                    placeholder="Enter pan No"
                                    {...register('pan', {
                                        pattern: {
                                            value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                                            message: 'Please Enter valid Pan No'
                                        },
                                    })}
                                />
                                {errors.pan && <ErrorLabel message={errors.pan?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">Landline</label>
                                <input
                                    className="form-control"
                                    id="landline"
                                    name="landline"
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    minLength={8}
                                    maxLength={13}
                                    placeholder="Enter Landline"
                                    {...register('landline', {
                                        minLength: {
                                            value: 8,
                                            message: 'Please enter min 8 and max 13 digit valid Landline no'
                                        }
                                    })}
                                />
                                {errors.landline && <ErrorLabel message={errors.landline?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">Industrial Segment&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                <Controller
                                    name="industrial_segment_id"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <DropDownListComponent
                                            {...field}
                                            dataSource={industrialSegment}
                                            id="industrial_segment_id"
                                            name="industrial_segment_id"
                                            fields={{ text: 'name', value: 'id' }}
                                            allowFiltering={true}
                                            placeholder="Select Industrial Segment"
                                            value={field.value}
                                            change={(event) => field.onChange(event.itemData.id)}
                                        />
                                    }
                                    rules={{
                                        required: clientRequiredFields ? 'Please Select Industrial Segment' : false
                                    }}
                                />
                                {errors.industrial_segment_id && <ErrorLabel message={errors.industrial_segment_id?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">Website</label>
                                <input
                                    className="form-control"
                                    id="website"
                                    name="website"
                                    placeholder="Enter Website"
                                    {...register('website', {
                                        pattern: {
                                            value: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                                            message: 'Please Enter valid Website'
                                        },
                                    })}
                                />
                                {errors.website && <ErrorLabel message={errors.website?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">Form of Oraganization&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                <Controller
                                    name="form_of_organization_id"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <DropDownListComponent
                                            {...field}
                                            dataSource={formOfOrganization}
                                            id="form_of_organization_id"
                                            name="form_of_organization_id"
                                            fields={{ text: 'name', value: 'id' }}
                                            allowFiltering={true}
                                            placeholder="Select Form of Oraganization"
                                            value={field.value}
                                            change={(event) => field.onChange(event.itemData.id)}
                                        />
                                    }
                                    rules={{
                                        required: clientRequiredFields ? 'Please Select Form of Oraganization' : false
                                    }}
                                />
                                {errors.form_of_organization_id && <ErrorLabel message={errors.form_of_organization_id?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">Multi Branch</label>
                                <Controller
                                    name="multi_branch"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <DropDownListComponent
                                            {...field}
                                            dataSource={[
                                                {
                                                    name: 'Yes',
                                                    value: 1
                                                },
                                                {
                                                    name: 'No',
                                                    value: 0
                                                }
                                            ]}
                                            id="multi_branch"
                                            name="multi_branch"
                                            allowFiltering={false}
                                            fields={{ text: 'name', value: 'value' }}
                                            placeholder="Select Multi Branch"
                                            value={field.value}
                                            change={(event) => {
                                                field.onChange(event.itemData.value);
                                            }}
                                        />
                                    }
                                />
                                {errors.multi_branch && <ErrorLabel message={errors.multi_branch?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">Lead Source&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                <Controller
                                    name="lead_source_id"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <DropDownListComponent
                                            {...field}
                                            dataSource={leadSource}
                                            id="lead_source_id"
                                            name="lead_source_id"
                                            fields={{ text: 'name', value: 'id' }}
                                            allowFiltering={true}
                                            placeholder="Select Lead Source"
                                            value={field.value}
                                            change={(event) => field.onChange(event.itemData.id)}
                                        />
                                    }
                                    rules={{
                                        required: clientRequiredFields ? 'Please Select Lead Source' : false
                                    }}
                                />
                                {errors.lead_source_id && <ErrorLabel message={errors.lead_source_id?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">Assign RM&nbsp;<span className="danger">*</span></label>
                                <Controller
                                    name="created_by"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <DropDownListComponent
                                            {...field}
                                            dataSource={assignRM}
                                            id="created_by"
                                            name="created_by"
                                            fields={{ text: 'full_name', value: 'user_id' }}
                                            allowFiltering={true}
                                            placeholder="Select Assign RM"
                                            value={field.value}
                                            change={(event) => {
                                                if (event.itemData != null) {
                                                    field.onChange(event.itemData.user_id)
                                                }
                                            }}
                                        />
                                    }
                                    rules={{
                                        required:  'Please Select Assign RM' 
                                    }}
                                />
                                {errors.created_by && <ErrorLabel message={errors.created_by?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">Assign Retention</label>
                                <Controller
                                    name="retention_user_id"
                                    control={control}
                                    render={({ field }) =>
                                        <DropDownListComponent
                                            {...field}
                                            dataSource={assignRetention}
                                            id="retention_user_id"
                                            name="retention_user_id"
                                            fields={{ text: 'full_name', value: 'user_id' }}
                                            allowFiltering={true}
                                            placeholder="Select Assign Retention"
                                            value={field.value}
                                            change={(event) => {
                                                if (event.itemData != null) {
                                                    field.onChange(event.itemData.user_id)
                                                }
                                            }}
                                        />
                                    }
                                />
                                {errors.retention_user_id && <ErrorLabel message={errors.retention_user_id?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <h4 className="d-flex align-items-center form-section mb-2 black">
                                <FiUser size={16} />&nbsp;Primary Contact
                            </h4>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">Contact Name&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                <input
                                    className="form-control"
                                    id="contact_name"
                                    name="contact_name"
                                    placeholder="Enter Contact Name"
                                    {...register('contact_name', {
                                        required: clientRequiredFields ? 'Please Enter Contact Name' : false,
                                        pattern: {
                                            value: /^(?!^ ?[A-Za-z] ?$)([A-Za-z]+( [A-Za-z]+)*)?$/,
                                            message: 'Please Enter Valid Name'
                                        },
                                    })}
                                />
                                {errors.contact_name && <ErrorLabel message={errors.contact_name?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">Contact Designation&nbsp;<span className={`danger ${clientRequiredFields ? '' : 'hidden'}`}>*</span></label>
                                <input
                                    className="form-control"
                                    id="contact_designation"
                                    name="contact_designation"
                                    placeholder="Enter Contact Designation"
                                    {...register(
                                        'contact_designation',
                                        {
                                            required: clientRequiredFields ? 'Please Enter Contact Designation' : false
                                        }
                                    )}
                                />
                                {errors.contact_designation && <ErrorLabel message={errors.contact_designation?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">Contact Phone</label>
                                <input
                                    className="form-control"
                                    id="contact_phone"
                                    name="contact_phone"
                                    placeholder="Enter Contact Phone"
                                    maxLength={10}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    {...register('contact_phone', {
                                        pattern: {
                                            value: /^[6789]\d{9}$/,
                                            message: 'Please enter 10 digit valid Mobile Number'
                                        },
                                    })}
                                />
                                {errors.contact_phone && <ErrorLabel message={errors.contact_phone?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="label-control">Contact Email</label>
                                <input
                                    className="form-control"
                                    id="contact_email"
                                    name="contact_email"
                                    placeholder="Enter Contact Email"
                                    {...register('contact_email', {
                                        pattern: {
                                            value: /^(?!.*@.*pincinsure)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                            message: 'Please enter valid Email Id'
                                        },
                                    })}
                                />
                                {errors.contact_email && <ErrorLabel message={errors.contact_email?.message} />}
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                <span><FiPhone size={16} />&nbsp;Contact Details</span>
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={() => {
                                        setShowContactDetails(true)
                                        setShowContact(false);
                                    }}
                                >
                                    <FiPlus
                                        cursor="pointer"
                                        size={20}
                                    />
                                </button>
                            </h4>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="row mx-0">
                                {
                                    contactDetails.length > 0 ?
                                        renderContactDetails()
                                        :
                                        <h6 className="text-bold-500 text-center">No Contact Details</h6>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                <span><FiShield size={16} />&nbsp;Opportunity Details</span>
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={() => {
                                        setShowPolicyDetails(true)
                                        setSelectedPolicyIndex(null);
                                    }}
                                >
                                    <FiPlus
                                        cursor="pointer"
                                        size={20}
                                    />
                                </button>
                            </h4>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="row mx-0">
                                {
                                    policyDetails.length > 0 ?
                                        renderPolicyDetails()
                                        :
                                        <h6 className="text-bold-500 text-center">No Opportunity Details</h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-actions border-0">
                    <button
                        id="save-as-lead"
                        className="btn btn-primary round text-uppercase shadow mr-1 mb-1 mb-md-0 mb-lg-0"
                        onClick={() => {
                            setValue('clientOrLead', 'lead');
                            setClientRequiredFields(false);
                        }}
                        type="submit"
                    >
                        <div className="d-flex align-items-center">
                            Save as Lead
                            <span id="save-as-lead-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                        </div>
                    </button>
                    <button
                        id="convert-to-customer"
                        className="btn btn-primary round text-uppercase shadow mr-1"
                        onClick={() => {
                            setValue('clientOrLead', 'client');
                            setClientRequiredFields(true);
                        }}
                        type="submit"
                    >
                        <div className="d-flex align-items-center">
                            Convert to Customer
                            <span id="convert-to-customer-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                        </div>
                    </button>
                    <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                        onClick={(event) => {
                            event.preventDefault();
                            props.history.goBack();
                        }}
                    > Cancel</button>
                </div>
            </form>
            <ContactDetails
                show={showContactDetails}
                contact={showContact}
                handleClose={(newContact) => {
                    if (newContact != null) {
                        var arrContacts = Object.assign([], contactDetails)
                        if (selectedContactIndex != null) {
                            arrContacts[selectedContactIndex] = newContact;
                            setSelectedContactIndex(null);
                        } else {
                            arrContacts.push(newContact)
                        }
                        setContactDetails(arrContacts)
                    }
                    setShowContactDetails(false)
                }}
                data={selectedContactIndex != null ? contactDetails[selectedContactIndex] : null}
            />
            <PolicyDetailModal
                show={showPolicyDetails}
                assignRMId={assignRMId != null && assignRMId != '' ? assignRMId : props.auth.getUser().user_id}
                handleClose={(newPolicy) => {
                    if (newPolicy != null) {
                        var arrPolicies = Object.assign([], policyDetails);
                        if (selectedPolicyIndex != null) {
                            arrPolicies[selectedPolicyIndex] = newPolicy;
                            setSelectedPolicyIndex(null);
                        } else {
                            arrPolicies.push(newPolicy)
                        }
                        console.log("new policy", arrPolicies);
                        setPolicyDetails(arrPolicies);
                    }
                    setShowPolicyDetails(false)
                }}
                data={selectedPolicyIndex != null ? policyDetails[selectedPolicyIndex] : null}
            />
            <ProposalDetailModal
                {...props}
                show={showProposalDetails}
                proposalDetails={selectedPolicyIndex > -1 ? policyDetails[selectedPolicyIndex] : null}
                handleClose={() => {
                    setShowProposalDetails(false);
                }}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    var CompanyGroup = null;
    var CustomerCategory = null;
    var IndustrialSegment = null;
    var FormofOrganization = null;
    var LeadSource = null;
    var AssignRM = null;
    var States = null;
    var Cities = null;
    var CorporateCustomer = null;
    var AssignRetention = null;
    var GetClientSearchList = null;
    var GetOpportunityQuestion = null;
    var GetCompanyName = null;

    if (
        state.LeadCreateCorporateReducer != null &&
        Object.keys(state.LeadCreateCorporateReducer).length > 0 &&
        state.LeadCreateCorporateReducer.constructor === Object
    ) {
        switch (state.LeadCreateCorporateReducer.tag) {
            case ACTION_MASTER_COMPANY_GROUP_SUCCESS:
                CompanyGroup = Object.assign({}, state.LeadCreateCorporateReducer.data);
                delete state.LeadCreateCorporateReducer;
                return { CompanyGroup };
            case ACTION_MASTER_CUSTOMER_CATEGORY_SUCCESS:
                CustomerCategory = Object.assign({}, state.LeadCreateCorporateReducer.data);
                delete state.LeadCreateCorporateReducer;
                return { CustomerCategory };
            case ACTION_MASTER_INDUSTRIAL_SEGMENT_SUCCESS:
                IndustrialSegment = Object.assign({}, state.LeadCreateCorporateReducer.data);
                delete state.LeadCreateCorporateReducer;
                return { IndustrialSegment };
            case ACTION_MASTER_FORM_OF_ORGANIZATION_SUCCESS:
                FormofOrganization = Object.assign({}, state.LeadCreateCorporateReducer.data);
                delete state.LeadCreateCorporateReducer;
                return { FormofOrganization };
            case ACTION_MASTER_LEAD_SOURCE_SUCCESS:
                LeadSource = Object.assign({}, state.LeadCreateCorporateReducer.data);
                delete state.LeadCreateCorporateReducer;
                return { LeadSource };
            case ACTION_MASTER_ASSIGN_RM_SUCCESS:
                AssignRM = Object.assign({}, state.LeadCreateCorporateReducer.data);
                delete state.LeadCreateCorporateReducer;
                return { AssignRM };
            case ACTION_MASTER_LEAD_STATES_SUCCESS:
                States = Object.assign({}, state.LeadCreateCorporateReducer.data);
                delete state.LeadCreateCorporateReducer;
                return { States };
            case ACTION_MASTER_LEAD_CITIES_SUCCESS:
                Cities = Object.assign({}, state.LeadCreateCorporateReducer.data);
                delete state.LeadCreateCorporateReducer;
                return { Cities };
            case ACTION_CORPORATE_CUSTOMER_SAVE_SUCCESS:
                CorporateCustomer = Object.assign({}, state.LeadCreateCorporateReducer.data);
                delete state.LeadCreateCorporateReducer;
                return { CorporateCustomer };
            case ACTION_MASTER_ASSIGN_RETENTION_SUCCESS:
                AssignRetention = Object.assign({}, state.LeadCreateCorporateReducer.data);
                delete state.LeadCreateCorporateReducer;
                return { AssignRetention };
            case ACTION_GET_CLIENT_SEARCH_LIST_SUCCESS:
                GetClientSearchList = Object.assign({}, state.LeadCreateCorporateReducer.data);
                delete state.LeadCreateCorporateReducer;
                return { GetClientSearchList };
            case ACTION_GET_OPPORTUNITY_QUESTION_SUCCESS:
                GetOpportunityQuestion = Object.assign({}, state.LeadCreateCorporateReducer.data);
                delete state.LeadCreateCorporateReducer;
                return { GetOpportunityQuestion };
            case ACTION_COMPANY_SUCCESS:
                GetCompanyName = Object.assign({}, state.LeadCreateCorporateReducer.data);
                delete state.LeadCreateCorporateReducer;
                return { GetCompanyName };
            default:
        }
    }
    return {
        CompanyGroup,
        CustomerCategory,
        IndustrialSegment,
        FormofOrganization,
        LeadSource,
        AssignRM,
        States,
        Cities,
        CorporateCustomer,
        AssignRetention,
        GetClientSearchList,
        GetOpportunityQuestion,
        GetCompanyName
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionCompanyGroup,
            actionCustomerCategory,
            actionIndustrialSegment,
            actionFormOfOrganization,
            actionLeadSource,
            actionAssignRM,
            actionStates,
            actionCities,
            actionCorporateCustomerSave,
            actionAssignRetention,
            actionGetClientSearchList,
            actionGetOpportunityQuestion,
            actionGetGroupCompanies
        },
        dispatch
    );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(CorporateForm))