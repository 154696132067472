import React from 'react';
import { Route, Switch } from 'react-router';

import InventoryList from '../inventory/list';
import CreateInventory from '../inventory/add';
import EditInventory from '../inventory/edit';
import InventoryView from '../inventory/view';

const InventoryRoute = (props) => {

    const { match } = props;

    return (
        <>
            <Switch>
                <Route exact path={`${match.url}`} component={InventoryList} />
                <Route path={`${match.url}/add`} component={CreateInventory} />
                <Route path={`${match.url}/edit`} component={EditInventory} />
                <Route path={`${match.url}/view`} component={InventoryView} />

                
            </Switch>
        </>
    )
}

export default InventoryRoute