import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaRegHandshake, FaWpforms } from "react-icons/fa";
import {
  FiCalendar,
  FiHome,
  FiMonitor,
  FiPieChart,
  FiUser,
  FiUserCheck,
  FiRefreshCcw,
  FiClipboard,
} from "react-icons/fi";
import {
  RiThumbUpLine,
  RiUserStarLine,
  RiFeedbackLine,
  RiDashboardLine,
  RiHealthBookLine,
  RiListSettingsLine,
} from "react-icons/ri";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BsCardChecklist, BsLayoutTextSidebarReverse } from "react-icons/bs";
import { IoDocumentTextOutline, IoDocumentLockOutline } from "react-icons/io5";
import { GoNote } from "react-icons/go";
import { FaRegCalendarAlt } from "react-icons/fa";
import $ from "jquery";
import { RiCustomerService2Fill } from "react-icons/ri";
import AddFeedback from "../form/feedback";
import { verifyPermission } from "../../utils";
import { AiOutlineFileProtect } from "react-icons/ai";
import { BsBookmarkCheck } from "react-icons/bs";
import { BiMessageSquareError } from "react-icons/bi";
import { getData, KEY_USER_TOKEN } from "../../utils/preferences";
import { ENVIRONMENT } from "../../api/constants";

const Sidebar = (props) => {
  const { match, location } = props;
  const token = getData(KEY_USER_TOKEN);
  const updatedToken = token.replace(/^"(.*)"$/, '$1');
  const [showFeedback, setShowFeedback] = useState(false);

  // const [showTab, setShowTab] = useState(false);

  // useEffect(() => {

  //   const timer = setTimeout(() => {
  //     setShowTab(verifyPermission('SHOW_TRAVEL_CALENDAR'));
  //   }, 4000);

  //   return () => clearTimeout(timer);
  // }, []);

  const resetAllRouteSelection = (hasSubMenu, clickedPath) => {
    const navSingleItems = document.querySelectorAll(".nav-item");
    navSingleItems.forEach((navItem) => {
      // Remove all menu open class which are single without sub menus
      navItem.classList.remove("open");
      if (navItem.getAttribute("href") === clickedPath) {
        // Add open class to single menu which is selected
        navItem.classList.add("open");
      }
    });

    const navItems = document.querySelectorAll(".nav-item.has-sub");
    navItems.forEach((navItem) => {
      // Remove all parent menu open class which have sub menus
      navItem.classList.remove("open");
      const menuLinks = navItem.querySelectorAll(".menu-item.main-link");
      menuLinks.forEach((menuItem, indexMenuItem) => {
        if (menuItem.getAttribute("href") === clickedPath) {
          // Add open class to parent menu which is selected
          navItem.classList.add("open");
        }
      });
    });
  };

  const isDashboardOpen = (path) => {
    resetAllRouteSelection(false, path);
    switch (path) {
      case "/dashboard":
        return "nav-item open";
      default:
        return "nav-item";
    }
  };

  const isHomeOpen = (path) => {
    resetAllRouteSelection(false, path);
    switch (path) {
      case "/dashboard/activity-feeds":
        return "nav-item open";
      default:
        return "nav-item";
    }
  };

  const isProfileOpen = (path) => {
    resetAllRouteSelection(false, path);
    switch (path) {
      case "/profile":
        return "nav-item open";
      default:
        return "nav-item";
    }
  };

  const isClientOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case "/client":
      case "/client/edit":
      case "/client/view":
        return "nav-item open";
      default:
        return "nav-item";
    }
  };

  const isTravelCalendarOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case '/travel-calendar':
      case '/travel-calendar/update':
        return 'nav-item open';
      default:
        return 'nav-item'
    }
  }

  const isLeadOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case "/leads":
      case "/leads/add":
      case "/leads/edit":
      case "/leads/view":
        return "nav-item open";
      default:
        return "nav-item";
    }
  };
  const isMeetingsOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case "/meetings/customer-meetings":
      case "/meetings/customer-meetings/add":
      case "/meetings/customer-meetings/edit":
      case "/meetings/customer-meetings/view":
      case "/meetings/insurance-company-meetings":
      case "/meetings/insurance-company-meetings/add":
      case "/meetings/insurance-company-meetings/edit":
      case "/meetings/insurance-company-meetings/view":
      case "/meetings/internal-meetings":
      case "/meetings/internal-meetings/add":
      case "/meetings/internal-meetings/edit":
      case "/meetings/internal-meetings/view":
      case "/meetings/serveyor-meetings":
      case "/meetings/serveyor-meetings/add":
      case "/meetings/serveyor-meetings/edit":
      case "/meetings/serveyor-meetings/view":
      case "/meetings/tpa-meetings":
      case "/meetings/tpa-meetings/add":
      case "/meetings/tpa-meetings/edit":
      case "/meetings/tpa-meetings/view":
      case "/meetings/ri-meetings":
      case "/meetings/ri-meetings/add":
      case "/meetings/ri-meetings/edit":
      case "/meetings/training":
      case "/meetings/training/view":
      case "/meetings":
        return "nav-item has-sub open";
      default:
        return "nav-item has-sub";
    }
  };

  const isCustomerServiceOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case '/customer-service/birthday-reminder':
      case '/customer-service/birthday-reminder/logs':
        return 'nav-item has-sub open';
      default:
        return 'nav-item has-sub'
    }
  }

  const isProposalOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case "/proposal":
      case "/proposal/view":
        return "nav-item open";
      default:
        return "nav-item";
    }
  };
  const isPolicyDashboardOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case "/policy":
      case "/policy/view":
        return "nav-item open";
      default:
        return "nav-item";
    }
  };
  const isClaimsOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case "/claims":
      case "/claims/list":
      case "/claims/view":
      case "/claims/edit":
      case "/claims/dashboard":
      case "/claims/register":
      case "/claims/register/loss-site-visit":
      case "/claims/register/loss-site-visit/add":
      case "/claims/register/mandate":
      case "/claims/register/pinc":
      case "/claims/register/loss-site-visit/add":
      case "/claims/pinc/policy/list":
      case "/claims/document/upload":
      case "/claims/download/report":
        return "nav-item has-sub open";
      default:
        return "nav-item has-sub";
    }
  };

  const isTeamOpen = (path) => {
    resetAllRouteSelection(false, path);
    switch (path) {
      case "/teams":
        return "nav-item open";
      default:
        return "nav-item";
    }
  };

  const isHRMSOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case "/hrms":
      case "/hrms/registration":
      case "/hrms/employee/view":
      case "/hrms/employee/edit":
      case "/hrms/request":
      case "/hrms/request/add":
      case "/hrms/request/view":
      case "/hrms/regularize":
      case "/hrms/regularize/add":
      case "/hrms/regularize/view":
      case "/holidays":
      case "/holidays/add":
      case "/holidays/view":
      case "/holidays/edit":
      case "/hrms/asset-request":
      case "/hrms/asset-request/add":
      case "/hrms/asset-request/view":
      case "/hrms/travel-request":
      case "/hrms/travel-request/add":
      case "/hrms/travel-request/view":
      case "/hrms/it-support/list":
      case "/hrms/it-support/add":
      case "/hrms/it-support/view":
      case "/hrms/reimbursement_request":
      case "/hrms/declaration-pool/list":
      case "/hrms/declaration-pool/view":
      case "/hrms/declaration-pool/edit":
      case "/hrms/submission-pool/list":
      case "/hrms/declarations":
        return "nav-item has-sub open";
      default:
        return "nav-item has-sub";
    }
  };

  const isApprovalsOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case "/approvals":
      case "/approvals/leave-approvals":
      case "/approvals/leave-approvals/view":
      case "/approvals/regularize-approvals":
      case "/approvals/regularize-approvals/view":
      case "/approvals/asset-approvals":
      case "/approvals/asset-approvals/view":
      case "/approvals/travel-approvals":
      case "/approvals/travel-approvals/view":
      case "/approvals/reimbursement_approvals":
      case "/approvals/reimbursement_approvals/view":
        return "nav-item has-sub open";
      default:
        return "nav-item has-sub";
    }
  };

  const isRiskManagementOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case "/risk_management":
      case "/risk_management/new_request":
      case "/risk_management/new_request/add":
      case "/risk_management/new_request/edit":
      case "/risk_management/new_request/view":
      case "/risk_management/request_approvals":
      case "/risk_management/requst_approvals/view":
      case "/risk_management/requst_approvals/edit":
      case "/risk_management/risk_pool":
      case "/risk_management/risk_pool/view":
      case "/risk_management/risk_pool/edit":
      case "/risk_management/report":
        return "nav-item has-sub open";
      default:
        return "nav-item has-sub";
    }
  };
  const isWellnessManagementOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case "/wellness_management":
      case "/wellness_management/new_request":
      case "/wellness_management/new_request/add":
      case "/wellness_management/new_request/edit":
      case "/wellness_management/new_request/view":
      case "/wellness_management/wellness_pool":
      case "/wellness_management/wellness_pool/view":
      case "/wellness_management/wellness_pool/edit":
      case "/wellness_management/request_approvals":
      case "/wellness_management/request_approvals/edit":
      case "/wellness_management/request_approvals/view":
      case "/wellness_management/report":
        return "nav-item has-sub open";
      default:
        return "nav-item has-sub";
    }
  };

  const isProposalPool = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case "/proposal_pool":
      case "/proposal_pool/settings":
        return "nav-item has-sub open";
      default:
        return "nav-item has-sub";
    }
  };

  const isPermissionOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case "/settings/roles":
      case "/settings/roles/add":
      case "/settings/roles/edit":
      case "/settings/roles/view":
      case "/settings/cron-dashboard":
      case "/settings/permission":
      case "/settings/permission/add":
      case "/settings/permission/edit":
      case "/settings/branch-cities":
      case "/settings/branch-addresses":
      case "/settings/lead-sources":
      case "/settings/content":
      case "/settings/content/edit":
      case "/settings/team-master":
      case "/settings/team-master/add":
      case "/settings/team-master/edit":
      case "/settings/shift-timing":
      case "/settings/shift-timing/add":
      case "/settings/shift-timing/edit":
      case "/settings/nature_of_expenses":
      case "/settings/nature_of_expenses/add":
      case "/settings/nature_of_expenses/edit":
      case "/settings/transport":
      case "/settings/transport/add":
      case "/settings/transport/edit":
      case "/settings/reimbursement-limit":
      case "/settings/reimbursement-limit/add":
      case "/settings/reimbursement-limit/edit":
      case "/settings/reimbursement-limit/view":
      case "/settings/app-settings":
      case "/settings/app-settings/add":
      case "/settings/app-settings/edit":
      case "/settings/opportunity-questions":
      case "/settings/opportunity-questions/add":
      case "/settings/rm-transfer":
      case "/settings/hrms-settings":
      case "/settings/hrms-settings/add":
      case "/settings/hrms-settings/view":
      case "/settings/hrms-settings/edit":
      case "/settings/rm-transfer/dashboard":
      case "/settings/travel/calendar":
      case "/settings/travel/calendar/update":
        return "nav-item has-sub open";
      default:
        return "nav-item has-sub";
    }
  };

  const isMasterDetailsOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case "/master/department":
      case "/master/policy":
      case "master/insurer":
      case "master/admin_right":
        return "nav-item has-sub open";
      default:
        return "nav-item has-sub";
    }
  };
  const isPlacementOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case "/placement/specific":
      case "/placement/general":
      case "/placement/general-view":
      case "master/admin_right":
      case "master/placement/general":
      case "master/placement/specific":
        return "nav-item has-sub open";
      default:
        return "nav-item has-sub";
    }
  };

  const isTaskDetailsOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case "/tasks":
      case "/tasks/view":
      case "/tasks/create-task":
      case "/tasks/task-pool":
      case "/tasks/task-pool/view":
      case "/tasks/inventory":
      case "/tasks/inventory/add":
        return "nav-item has-sub open";
      default:
        return "nav-item has-sub";
    }
  };

  const isCalendarOpen = (path) => {
    resetAllRouteSelection(false, path);
    switch (path) {
      case "/holidays/calendar":
        return "nav-item open";
      default:
        return "nav-item";
    }
  };

  const isRetentionOpen = (path) => {
    resetAllRouteSelection(false, path);
    switch (path) {
      case "/retention":
        return "nav-item open";
      default:
        return "nav-item";
    }
  };
  const isRIOpen = (path) => {
    resetAllRouteSelection(false, path);
    switch (path) {
      case `//ri.staypinc.com/loginRi?token=${updatedToken}`:
        return "nav-item open";
      default:
        return "nav-item";
    }

  };
  const isReportOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case "/reports":
      case "/reports/filters":
      case "/reports/meeting":
      case "/reports/attendance":
        return "nav-item open";
      default:
        return "nav-item";
    }
  };

  const isDocumentsOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case "/documents":
      case "/documents/create":
        return "nav-item open";
      default:
        return "nav-item";
    }
  };

  const isReinsurranceOpen = (path) => {
    resetAllRouteSelection(true, path);
    switch (path) {
      case "/reinsurance":
      case "/reinsurance/master":
      case "/reinsurance/client":
      case "/reinsurance/import":
      case "/reinsurance/edit":
      case "/reinsurance/reinsurance-entries":
      case "/reinsurance/add":
      case "/reinsurance/view":
        return "nav-item has-sub open";
      default:
        return "nav-item has-sub";
    }
  };

  const isFeedbackOpen = (path) => {
    resetAllRouteSelection(false, path);
    switch (path) {
      case "/feedback":
        return "nav-item open";
      default:
        return "nav-item";
    }
  };

  const initialRender = () => {
    $(document).ready(function () {
      $("a.main-link").on("click", function (event) {
        event.preventDefault();
        if ($(this).hasClass("feedback")) {
          setShowFeedback(true);
        } else {
          props.history.push($(this).attr("href"));
        }

        setTimeout(() => {
          $(this).parent().addClass("open");
          $(".mobile-menu").trigger("click");
          $("body").removeClass("menu-open");
          $(".sidenav-overlay").removeClass("d-block");
          $(".mobile-menu").find("a").removeClass("is-active");
        }, 250);
      });
    });
  };
  useEffect(initialRender, []);

  return (
    <>
      <div
        className="main-menu menu-fixed menu-light menu-accordion"
        data-scroll-to-active="true"
      >
        <div className="main-menu-content">
          <ul
            className="navigation navigation-main"
            id="main-menu-navigation"
            data-menu="menu-navigation"
          >
            <li className={isDashboardOpen(location.pathname)}>
              <Link className="main-link" to={`${match.url}dashboard`}>
                <RiDashboardLine size={16} className="mr-1" />
                <span className="menu-title" data-i18n="Dashboard">
                  Dashboard
                </span>
              </Link>
            </li>

            <li className={isHomeOpen(location.pathname)}>
              <Link
                className="main-link"
                to={`${match.url}dashboard/activity-feeds`}
              >
                <FiHome size={16} className="mr-1" />
                <span className="menu-title" data-i18n="Home">
                  Home
                </span>
              </Link>
            </li>
            {verifyPermission("MY_PROFILE_DETAILS") ? (
              <li className={isProfileOpen(location.pathname)}>
                <Link className="main-link" to={`${match.url}profile`}>
                  <FiUser size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="My Profile">
                    My Profile
                  </span>
                </Link>
              </li>
            ) : (
              <> </>
            )}

            {verifyPermission("VIEW_ALL_CLIENTS") ? (
              <li className={isClientOpen(location.pathname)}>
                <Link to={`${match.url}client`} className="main-link">
                  <RiUserStarLine size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="Client">
                    Client
                  </span>
                </Link>
              </li>
            ) : (
              <> </>
            )}
            {verifyPermission("VIEW_ALL_LEADS") ? (
              <li className={isLeadOpen(location.pathname)}>
                <Link to={`${match.url}leads`} className="main-link">
                  <RiThumbUpLine size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="Lead">
                    Leads
                  </span>
                </Link>
              </li>
            ) : (
              <></>
            )}
            {verifyPermission("SHOW_TRAVEL_CALENDAR") ? (
              <li className={isTravelCalendarOpen(location.pathname)}>
                <Link to={`${match.url}travel-calendar`} className="main-link">
                  <FaRegCalendarAlt size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="Lead">
                    Travel Calendar
                  </span>
                </Link>
              </li>
            ) :
              <></>}
            {verifyPermission("ALL_MEETING_MENU") ||
              verifyPermission("VIEW_ALL_CUSTOMER_MEETING") ||
              verifyPermission("VIEW_ALL_INSURANCE_MEETING") ||
              verifyPermission("VIEW_ALL_INTERNAL_MEETING") ||
              verifyPermission("VIEW_ALL_SERVEYOR_MEETING") ||
              verifyPermission("VIEW_ALL_TPA_MEETING") ||
              verifyPermission("VIEW_ALL_RI_MEETING") ? (
              <li className={isMeetingsOpen(location.pathname)}>
                <Link to="#">
                  <FaRegHandshake size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="Meetings">
                    Meetings
                  </span>
                </Link>
                <ul className="menu-content">
                  {verifyPermission("VIEW_ALL_CUSTOMER_MEETING") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}meetings/customer-meetings`}
                        data-i18n="Customer Meetings"
                      >
                        <span
                          className="menu-title"
                          data-i18n="Customer Meetings"
                        >
                          Customer Meetings
                        </span>
                      </Link>
                    </li>
                  ) : (
                    <> </>
                  )}
                  {verifyPermission("VIEW_ALL_INSURANCE_MEETING") ? (
                    <li>
                      <Link
                        className="menu-item  main-link main-sidebar"
                        to={`${match.url}meetings/insurance-company-meetings`}
                        data-i18n="Insurance Company Meetings"
                      >
                        Insurance Company
                        <br />
                        Meetings
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("VIEW_ALL_INTERNAL_MEETING") ? (
                    <li>
                      <Link
                        className="menu-item  main-link main-sidebar"
                        to={`${match.url}meetings/internal-meetings`}
                        data-i18n="Internal Meetings"
                      >
                        Internal Meetings
                      </Link>
                    </li>
                  ) : (
                    <> </>
                  )}
                  {verifyPermission("VIEW_ALL_SERVEYOR_MEETING") ? (
                    <li>
                      <Link
                        className="menu-item  main-link main-sidebar"
                        to={`${match.url}meetings/serveyor-meetings`}
                        data-i18n="Serveyor Meetings"
                      >
                        Surveyor Meetings
                      </Link>
                    </li>
                  ) : (
                    <> </>
                  )}
                  {verifyPermission("VIEW_ALL_TPA_MEETING") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}meetings/tpa-meetings`}
                        data-i18n="TPA Meetings"
                      >
                        TPA Meetings
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("VIEW_ALL_RI_MEETING") ? (
                    <li>
                      <Link
                        className="menu-item  main-link main-sidebar"
                        to={`${match.url}meetings/ri-meetings`}
                        data-i18n="RI Meetings"
                      >
                        RI Meetings
                      </Link>
                    </li>
                  ) : (
                    <> </>
                  )}
                  {verifyPermission("MEETING_TRAINING_MENU") ? (
                    <li>
                      <Link
                        className="menu-item  main-link main-sidebar"
                        to={`${match.url}meetings/training`}
                        data-i18n="Hall of IVY"
                      >
                        Hall of IVY
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("ALL_MEETING_MENU") ? (
                    <li>
                      <Link
                        className="menu-item  main-link main-sidebar"
                        to={`${match.url}meetings`}
                        data-i18n="All Meetings"
                      >
                        All Meetings
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </li>
            ) : (
              <></>
            )}
            {verifyPermission("CUSTOMER_SERVICES_MENU") ? (
              <li className={isCustomerServiceOpen(location.pathname)}>
                <Link to="#">
                  <RiCustomerService2Fill size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="Customer Service">
                    Customer Service
                  </span>
                </Link>
                <ul className="menu-content">
                  {verifyPermission("BIRTHDAY_REMINDER") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}customer-service/birthday-reminder`}
                        data-i18n="Birthday Reminder"
                      >
                        <span className="menu-title" data-i18n="Birthday Reminder">
                          Birthday Reminder
                        </span>
                      </Link>
                    </li>
                  ) : (<></>)}
                  {verifyPermission('BIRTHDAY_REMINDER_LOGS') ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}customer-service/birthday-reminder/logs`}
                        data-i18n="Birthday Reminder Logs"
                      >
                        <span className="menu-title" data-i18n="Birthday Reminder Logs">
                          Birthday Reminder Logs
                        </span>
                      </Link>
                    </li>
                  ) : (<></>)}

                </ul>
              </li>
            ) : (<></>)}
            {verifyPermission("PROPOSAL_DASHBOARD_MENU") ? (
              <li className={isProposalOpen(location.pathname)}>
                <Link to={`${match.url}proposal`} className="main-link">
                  <FaWpforms size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="Lead">
                    Opportunity Dashboard
                  </span>
                </Link>
              </li>
            ) : (
              <></>
            )}

            {verifyPermission("POLICY_DASHBOARD_MENU") ? (
              <li className={isPolicyDashboardOpen(location.pathname)}>
                <Link to={`${match.url}policy`} className="main-link">
                  <AiOutlineFileProtect size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="Lead">
                    Renewal Dashboard
                  </span>
                </Link>
              </li>
            ) : (
              <></>
            )}
            {verifyPermission("CLAIM_DASHBOARD") ? (
              <li className={isClaimsOpen(location.pathname)}>
                <Link to="#">
                  <FiClipboard size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="Claims">
                    Claims
                  </span>
                </Link>
                <ul className="menu-content">
                  {verifyPermission("CLAIM_DASHBOARD") ? (
                    <li>
                      <Link
                        className="menu-item main-link"
                        to={`${match.url}claims`}
                        data-i18n="Claim Dashboard"
                      >
                        Claim Dashboard
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("REGISTER_NEW_CLAIM") ? (
                    <li>
                      <Link
                        className="menu-item main-link"
                        to={`${match.url}claims/pinc/policy/list`}
                        data-i18n="Register New Claim"
                      >
                        Register New Claim
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("VIEW_ALL_CLAIM") ? (
                    <li>
                      <Link
                        className="menu-item main-link"
                        to={`${match.url}claims/list`}
                        data-i18n="View All Claims"
                      >
                        View All Claims
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("DOWNLOAD_CLAIM_MIS") ? (
                    <li>
                      <Link
                        className="menu-item main-link"
                        to={`${match.url}claims/download/report`}
                        data-i18n="Download Claim MIS"
                      >
                        Download Claim MIS
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </li>
            ) : (
              <></>
            )}
            {verifyPermission("SHOW_TEAM") ? (
              <li className={isTeamOpen(location.pathname)}>
                <Link className="main-link" to={`${match.url}teams`}>
                  <HiOutlineUserGroup size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="Team">
                    Team
                  </span>
                </Link>
              </li>
            ) : (
              <></>
            )}
            {verifyPermission("HRMS_MENU") ? (
              <li className={isHRMSOpen(location.pathname)}>
                <Link to="#">
                  <FiMonitor size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="HRMS">
                    HRMS
                  </span>
                </Link>
                <ul className="menu-content">
                  {verifyPermission("IT_SUPPORT") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}hrms/it-support/list`}
                        data-i18n="IT Support"
                      >
                        IT Support
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("LEAVE_REQUEST") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}hrms/request`}
                        data-i18n="Leave Requests"
                      >
                        Leaves Requests
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("REGULARIZE_REQUEST") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}hrms/regularize`}
                        data-i18n="Regularize Request"
                      >
                        Regularize Request
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("VIEW_ALL_EMPLOYEE") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}hrms`}
                        data-i18n="Employee List"
                      >
                        Employee List
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("CREATE_EMPLOYEE") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}hrms/registration`}
                        data-i18n="Employee List"
                      >
                        Add New Employee
                      </Link>
                    </li>
                  ) : (
                    <> </>
                  )}
                  {verifyPermission("HOLIDAY_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}holidays`}
                        data-i18n="Holidays"
                      >
                        Holidays
                      </Link>
                    </li>
                  ) : (
                    <> </>
                  )}
                  {verifyPermission("ASSET_REQUEST_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}hrms/asset-request`}
                        data-i18n="Asset Request"
                      >
                        Asset Request
                      </Link>
                    </li>
                  ) : (
                    <> </>
                  )}
                  {verifyPermission("TRAVEL_REQUEST_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}hrms/travel-request`}
                        data-i18n="Travel Request"
                      >
                        Travel Request
                      </Link>
                    </li>
                  ) : (
                    <> </>
                  )}
                  {verifyPermission("NEW_REIMBURSEMENT") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}hrms/reimbursement_request`}
                        data-i18n="Reimbursement"
                      >
                        Reimbursement Request
                      </Link>
                    </li>
                  ) : (
                    <> </>
                  )}
                  {verifyPermission("IT_DECLARATION_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}hrms/declarations`}
                        data-i18n="Declarations"
                      >
                        Declarations
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("IT_DECLARATION_POOL_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}hrms/declaration-pool/list`}
                        data-i18n="DECLARATION POOL"
                      >
                        Declaration Pool
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("IT_SUBMISSION_POOL_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}hrms/submission-pool/list`}
                        data-i18n="SUBMISSION POOL"
                      >
                        Submission Pool
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </li>
            ) : (
              <></>
            )}
            {verifyPermission("APPROVAL_DETAILS") ? (
              <li className={isApprovalsOpen(location.pathname)}>
                <Link to="#">
                  <BsBookmarkCheck size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="Approvals">
                    Approvals
                  </span>
                </Link>
                <ul className="menu-content">
                  {verifyPermission("ASSET_APPROVAL") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}approvals/asset-approvals`}
                        data-i18n="Asset Approvals"
                      >
                        Asset Approvals
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("TRAVEL_APPROVAL") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}approvals/travel-approvals`}
                        data-i18n="Travel Approvals"
                      >
                        Travel Approvals
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("LEAVE_APPROVAL") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}approvals/leave-approvals`}
                        data-i18n="Leave Approvals"
                      >
                        Leave Approvals
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("REGULARIZE_APPROVAL") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}approvals/regularize-approvals`}
                        data-i18n="Regularize Approvals"
                      >
                        Regularize Approvals
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("REIMBURSEMENT_APPROVAL") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}approvals/reimbursement_approvals`}
                        data-i18n="Reimbursement"
                      >
                        Reimbursement Approvals
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("REIMBURSEMENT_POOL") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}approvals/reimbursement_pool`}
                        data-i18n="Reimbursement"
                      >
                        Reimbursement Pool
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </li>
            ) : (
              <></>
            )}
            {verifyPermission("RISK_MANAGEMENT_MENU") ? (
              <li className={isRiskManagementOpen(location.pathname)}>
                <Link to="#">
                  <BiMessageSquareError size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="RiskManagement">
                    Risk Management
                  </span>
                </Link>
                <ul className="menu-content">
                  {verifyPermission("RISK_MANAGEMNT_REQUEST") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}risk_management/new_request`}
                        data-i18n="Risk Requests"
                      >
                        Risk Requests
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("RISK_MANAGEMNT_APPORVAL") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}risk_management/request_approvals`}
                        data-i18n="Request Approvals"
                      >
                        Request Approvals
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("RISK_MANAGEMNT_POOL") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}risk_management/risk_pool`}
                        data-i18n="Risk Assignment List"
                      >
                        Risk Assignment List
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("RISK_MANAGEMENT_REPORT") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}risk_management/report`}
                        data-i18n="Risk Reports"
                      >
                        Risk Reports
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </li>
            ) : (
              <></>
            )}
            {verifyPermission("WELLNESS_MANAGEMENT_MENU") ? (
              <li className={isWellnessManagementOpen(location.pathname)}>
                <Link to="#">
                  <RiHealthBookLine size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="WellnessManagement">
                    Wellness Management
                  </span>
                </Link>
                <ul className="menu-content">
                  {verifyPermission("WELLNESS_MANAGEMNT_REQUEST") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}wellness_management/new_request`}
                        data-i18n="Wellness Requests"
                      >
                        Wellness Requests
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("WELLNESS_MANAGEMNT_APPORVAL") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}wellness_management/request_approvals`}
                        data-i18n="Request Approvals"
                      >
                        Request Approvals
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("WELLNESS_MANAGEMNT_POOL") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}wellness_management/wellness_pool`}
                        data-i18n="Wellness Pool"
                      >
                        Wellness Pool
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("WELLNESS_MANAGEMENT_REPORT") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}wellness_management/report`}
                        data-i18n="Wellness Report"
                      >
                        Wellness Reports
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </li>
            ) : (
              <></>
            )}
            {/* {
                            verifyPermission("OPPORTUNITY_POOL_MENU") ?
                                <li className={isProposalPool(location.pathname)}>
                                    <Link to={`${match.url}proposal_pool`} className="main-link">
                                        <RiDashboardLine size={16} className="mr-1" />
                                        <span className="menu-title" data-i18n="Lead">
                                            Opportunity Pool
                                        </span>
                                    </Link>
                                </li>
                                :
                                <></>
                        } */}
            {verifyPermission("OPPORTUNITY_POOL_MENU") ? (
              <li className={isProposalPool(location.pathname)}>
                <Link to="#">
                  <RiDashboardLine size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="Opportunity Pool">
                    Opportunity Pool
                  </span>
                </Link>
                <ul className="menu-content">
                  {/* {
                                            verifyPermission("RISK_MANAGEMNT_REQUEST") ? */}
                  <li>
                    <Link
                      className="menu-item main-link main-sidebar"
                      to={`${match.url}proposal_pool`}
                      data-i18n="Opportunity Pool"
                    >
                      Opportunity Pool
                    </Link>
                  </li>
                  {/* :
                                                <></>
                                        } */}
                  {verifyPermission("OPPORTUNITY_SETTINGS") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}proposal_pool/settings`}
                        data-i18n="Opportunity Settings"
                      >
                        Opportunity Settings
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </li>
            ) : (
              <></>
            )}

            {verifyPermission("PLACEMENT_MENU") ? (
              <li className={isPlacementOpen(location.pathname)}>
                <Link to="#">
                  <BsLayoutTextSidebarReverse size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="Placement">
                    Placement
                  </span>
                </Link>
                <ul className="menu-content">
                  {verifyPermission("GENERAL_PLACEMENT_VIEW_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}placement/general-view`}
                        data-i18n="General Preference"
                      >
                        General Preference
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("SPECIFIC_PLACEMENT_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}placement/specific`}
                        data-i18n="Specific Preference"
                      >
                        Specific Preference
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("GENERAL_PLACEMENT_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}placement/general`}
                        data-i18n="Add Preference"
                      >
                        Add Preference
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("ADMIN_RIGHTS") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}master/admin_right`}
                        data-i18n="Placement Admin Rights"
                      >
                        Placement Admin Rights
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("MASTER_PLACEMENT") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}master/placement/general`}
                        data-i18n="General Placement"
                      >
                        General Placement Rights
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("MASTER_PLACEMENT_SPECIFIC_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}master/placement/specific`}
                        data-i18n="Specific Placement"
                      >
                        Specific Placement Rights
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </li>
            ) : (
              <></>
            )}
            {verifyPermission("VIEW_ALL_PERMISSIONS") ? (
              <li className={isPermissionOpen(location.pathname)}>
                <Link to="#">
                  <FiUserCheck size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="HRMS">
                    Settings
                  </span>
                </Link>
                <ul className="menu-content">
                  {
                    // verifyPermission("VIEW_ALL_PERMISSIONS") === false ?
                    verifyPermission("VIEW_ALL_PERMISSIONS") ? (
                      <li>
                        <Link
                          className="menu-item main-link main-sidebar"
                          to={`${match.url}settings/permission`}
                          data-i18n="Permissions"
                        >
                          Permissions
                        </Link>
                      </li>
                    ) : (
                      <> </>
                    )
                  }
                  {verifyPermission("VIEW_ALL_ROLES") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/roles`}
                        data-i18n="Roles"
                      >
                        Roles
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("VIEW_ALL_SHIFT_TIMING") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/shift-timing`}
                        data-i18n="Shift Timing"
                      >
                        Shift Timing
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("VIEW_ALL_CRON_DASHBOARD") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/cron-dashboard`}
                        data-i18n="Roles"
                      >
                        CRON Dashboard
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("VIEW_ALL_BRANCH_CITIES") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/branch-cities`}
                        data-i18n="Branch Cities"
                      >
                        Branch Cities
                      </Link>
                    </li>
                  ) : (
                    <> </>
                  )}
                  {verifyPermission("VIEW_ALL_BRANCH_ADDRESSES") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/branch-addresses`}
                        data-i18n="Branch Addresses"
                      >
                        Branch Addresses
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("VIEW_ALL_LEAD_SOURCES") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/lead-sources`}
                        data-i18n="Lead Sources"
                      >
                        Lead Sources
                      </Link>
                    </li>
                  ) : (
                    <> </>
                  )}
                  {verifyPermission("CONTENT_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/content`}
                        data-i18n="Content"
                      >
                        Content
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("TEAM_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/team-master`}
                        data-i18n="Team Master"
                      >
                        Team Master
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("REIMBURSEMENT_TYPE") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/nature_of_expenses`}
                        data-i18n="Nature of Expenses"
                      >
                        Reimbursement Type
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("TRANSPORT") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/transport`}
                        data-i18n="Transport"
                      >
                        Transport
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("REIMBURSEMENT_LIMIT") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/reimbursement-limit`}
                        data-i18n="Shift Timing"
                      >
                        Reimbursement Limit
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("APP_SETTING") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/app-settings`}
                        data-i18n="App Setting"
                      >
                        App Setting
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("RM_TRANSFER") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/rm-transfer`}
                        data-i18n="RM Transfer"
                      >
                        RM Transfer
                      </Link>
                      <ul className="menu-content">
                        <li>
                          <Link
                            className="menu-item main-link main-sidebar"
                            to={`${match.url}settings/rm-transfer/dashboard`}
                            data-i18n="Permissions"
                          >
                            Dashboard of RM Transfer
                          </Link>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("OPPORTUNITY_QUESTION") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/opportunity-questions`}
                        data-i18n="OPPORTUNITY QUESTION"
                      >
                        Opportunity Questions
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("PROBABILITY_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/probability`}
                        data-i18n="PROBABLITY"
                      >
                        Probability
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("BULK_USER_LOGOUT_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/logout`}
                        data-i18n="Bulk User Logout"
                      >
                        Bulk User Logout
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("HRMS_SETTING_TRAINING_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/hrms-settings`}
                        data-i18n="Training Settings"
                      >
                        Training Settings
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("IT_DECLARATION_SETTING_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/declaration`}
                        data-i18n="Declaration"
                      >
                        Declaration
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("TRAVEL_CALENDAR_SETTING") ? (

                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}settings/travel/calendar`}
                        data-i18n="Travel Calendar Setting"
                      >
                        Travel Calendar Setting
                      </Link>
                    </li>
                  ) : <></>}

                </ul>
              </li>
            ) : (
              <></>
            )}
            {verifyPermission("MASTER_DETAILS_MENU") ? (
              <li className={isMasterDetailsOpen(location.pathname)}>
                <Link to="#">
                  <RiListSettingsLine size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="Master Details">
                    Master Details
                  </span>
                </Link>
                <ul className="menu-content">
                  {verifyPermission("MASTER_DEPARTMENT") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}master/department`}
                        data-i18n="Department"
                      >
                        Department
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("MASTER_POLICY") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}master/policy`}
                        data-i18n="Policy"
                      >
                        Policy
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("MASTER_INSURER") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}master/insurer`}
                        data-i18n="Insurer"
                      >
                        Insurer
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("TRAINING_TYPE_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}master/training-type`}
                        data-i18n="Type of Training"
                      >
                        Type of Training
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </li>
            ) : (
              <></>
            )}
            {verifyPermission("TASK_MENU") ? (
              <li className={isTaskDetailsOpen(location.pathname)}>
                <Link to="#">
                  <BsCardChecklist size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="Task Details">
                    Task Details
                  </span>
                </Link>
                <ul className="menu-content">
                  {verifyPermission("CREATE_TASK") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}tasks/create-task`}
                        data-i18n="Create Task"
                      >
                        Create Task
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("TASK_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}tasks`}
                        data-i18n="Task"
                      >
                        Task
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("TASK_POOL_MENU") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}tasks/task-pool`}
                        data-i18n="Task Pool"
                      >
                        Task Pool
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}

                  {
                    verifyPermission("INVENTORY") ?
                      <li>
                        <Link
                          className="menu-item main-link main-sidebar"
                          to={`${match.url}tasks/inventory`}
                          data-i18n="Inventory">
                          Inventory
                        </Link>
                      </li>
                      :
                      <></>
                  }

                </ul>
              </li>
            ) : (
              <></>
            )}
            {verifyPermission("CALENDER_MENU") ? (
              <li className={isCalendarOpen(location.pathname)}>
                <Link
                  to={`${match.url}holidays/calendar`}
                  className="main-link"
                >
                  <FiCalendar size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="Calendar">
                    Calendar
                  </span>
                </Link>
              </li>
            ) : (
              <></>
            )}
            {verifyPermission("VIEW_ALL_RETENTION") ? (
              <li className={isRetentionOpen(location.pathname)}>
                <Link to={`${match.url}retention`} className="main-link">
                  <FiRefreshCcw size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="Retention">
                    Retention
                  </span>
                </Link>
              </li>
            ) : (
              <></>
            )}
            {verifyPermission("RI_MAIN") ? (
              <li className={isRIOpen()}>
                <Link
                  to={
                    ENVIRONMENT === "development"
                      ? `//ri.staypinc.com/loginRi/${updatedToken}`
                      : `//ri.pinc.co.in/loginRi/${updatedToken}`
                  } target="_blank"
                  rel={'external'}
                  onClick={(event) => { event.preventDefault(); window.open(this.makeHref(`//ri.staypinc.com/loginRi?token=${updatedToken}`)) }}>
                  <FiRefreshCcw size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="RI">
                    RI
                  </span>
                </Link>
              </li>
            ) : (
              <></>
            )}
            {verifyPermission("REPORT_MENU") ? (
              <li className={isReportOpen(location.pathname)}>
                <Link className="main-link" to={`${match.url}reports`}>
                  <FiPieChart size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="Reports">
                    Reports
                  </span>
                </Link>
              </li>
            ) : (
              <></>
            )}
            <li className={isDocumentsOpen(location.pathname)}>
              <Link className="main-link" to={`${match.url}documents`}>
                <IoDocumentTextOutline size={16} className="mr-1" />
                <span className="menu-title" data-i18n="Documents">
                  Documents
                </span>
              </Link>
            </li>
            {verifyPermission("REINSURANCE_DETAILS") ? (
              <li className={isReinsurranceOpen(location.pathname)}>
                <Link to="#">
                  <GoNote size={16} className="mr-1" />
                  <span className="menu-title" data-i18n="Reinsurance">
                    Reinsurance
                  </span>
                </Link>
                <ul className="menu-content">
                  {verifyPermission("REINSURANCE_CLIENT") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}reinsurance`}
                        data-i18n="Client"
                      >
                        Client
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("REINSURANCE_MASTER_IMPORT_EXPORT") ? (
                    <li>
                      <Link
                        className="menu-item main-link main-sidebar"
                        to={`${match.url}reinsurance/master`}
                        data-i18n="Master Import Export"
                      >
                        Master Import Export
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {verifyPermission("REINSURANCE_ENQUIRY_MENU") ? (
                    <li>
                      <Link
                        className="menu-item  main-link main-sidebar"
                        to={`${match.url}reinsurance/reinsurance-entries`}
                        data-i18n="Reinsurance entry"
                      >
                        Enquiries
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </li>
            ) : (
              <></>
            )}
            <li
              className={isFeedbackOpen(location.pathname)}
              id="feedback_link"
            >
              <a className="main-link feedback" href="#">
                <RiFeedbackLine size={16} className="mr-1" />
                <span className="menu-title" data-i18n="Feedback">
                  Feedback
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <AddFeedback
        show={showFeedback}
        handleClose={() => {
          setShowFeedback(false);
        }}
      />
    </>
  );
};

export default Sidebar;
