import React, { useEffect, useState, useCallback } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { DateBox } from 'devextreme-react/date-box';
import { Controller, useForm } from "react-hook-form";
import dateFormat from "dateformat";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { FiUser } from 'react-icons/fi';
import $ from 'jquery';
import { ERROR, UNAUTHORIZED, CLAIM_FILE_ERROR_MESSAGE, PRIMARY_COLOR } from '../../../../../../constants';
import { toast } from 'react-toastify';
import { clearData, getData, KEY_TOAST_MESSAGE, KEY_USER, setData } from '../../../../../../utils/preferences';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import { checkFileExtension, downloadFile } from '../../../../../../utils';
import { actionEditWellnessPool, ACTION_WELLNESS_POOL_EDIT_SUCCESS, ACTION_EDIT_WELLNESS_POOL_TYPE_SUCCESS, actionWellnessManagementWellnessType, ACTION_WELLNESS_DOCUMENT_DELETE_SUCCESS, actionWellnessDocumentDelete } from './action';
import { actionViewWellnessPool, ACTION_VIEW_WELLNESS_POOL_SUCCESS } from "./action";
import { actionEditWellnessPoolClientType, ACTION_EDIT_WELLNESS_POOL_CLIENT_TYPE_SUCCESS } from "./action";
import { actionEditWellnessPoolAssignEngineer, ACTION_EDIT_WELLNESS_POOL_ASSIGN_ENGINEER_SUCCESS } from "./action";
import _ from 'lodash';
import DocumentIframe from '../../../../../../component/popups/document_iframe';
import { useDropzone } from 'react-dropzone'

// Assume these are defined elsewhere in your application
const CLAIM_FILE_SIZE = 5 * 1024 * 1024 // 5MB example
const validExt = ['.doc', '.docx', '.xlsx', '.xls', '.pdf', '.zip', '.txt', '.jpg', '.jpeg', '.png']

const WellnessPoolEdit = (props) => {
    const { register, handleSubmit, watch, control, formState: { errors }, setValue } = useForm({
        defaultValues: {

        }
    });
    const [clientType, setClientType] = useState([]);
    const [wellnessPool, setWellnessPool] = useState(null);
    const [uploadDocument, setUploadDocument] = useState(null);
    const [assignEngineer, setAssignEngineer] = useState([]);
    const [wellnessStatus, setWellnessStatus] = useState(null);
    const [wellnessDocument, setWellnessDocument] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [selectType, setSelectType] = useState(null)
    const [showExecutedBy, setShowExecutedBy] = useState(null);
    const [selectedExecutedBy, setSelectedExecutedBy] = useState("");
    const [wellnessType, setWellnessType] = useState([]);
    const [extension, setExtension] = useState(null)
    // const [documentDetails, setDocumentDetails] = useState(null)

    const [files, setFiles] = useState([]);
    const [maxFileCount, setMaxFileCount] = useState(5)

    const watchRMStatus = watch('rm_status')

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        var userDetail = getData(KEY_USER);
        if (userDetail != null) {
            var userObj = JSON.parse(userDetail)
            setDepartments(userObj.departments)
        }
        props.actionViewWellnessPool({ id: props.location.state.id });
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (watchRMStatus !== undefined && watchRMStatus !== null) {
            setWellnessStatus(watchRMStatus)
        }
    }, [watchRMStatus])

    useEffect(() => {
        if (wellnessPool != null) {
            setValue('created_at', wellnessPool?.created_at != null ? moment(wellnessPool?.created_at).format("DD/MM/YYYY") : '');
            setValue('address', wellnessPool?.address != null ? wellnessPool?.address : '');
            setValue('activity_date', wellnessPool?.activity_date != null ? wellnessPool?.activity_date : '');
            setValue('actual_activity_date', wellnessPool?.actual_activity_date != null ? wellnessPool?.actual_activity_date : '');
            setValue('remarks', wellnessPool?.remarks != null ? wellnessPool?.remarks : '');
            setValue('client_name', wellnessPool?.client_name != null ? wellnessPool?.client_name : '');
            setValue('rm_status', wellnessPool?.rm_status != null ? wellnessPool?.rm_status : 'update');
            setValue('wellness_type_detail', wellnessPool?.wellness_type_detail != null ? wellnessPool?.wellness_type_detail : '');
            setValue('wellness_executed_by', wellnessPool?.wellness_executed_by != null ? wellnessPool?.wellness_executed_by : '');
            if (wellnessPool?.wellness_executed_by != null) {
                setSelectedExecutedBy(wellnessPool?.wellness_executed_by);
                setShowExecutedBy(true);
            }

            if (wellnessPool?.activity_date != null) {
                const check = new Date(wellnessPool?.activity_date) < new Date().setHours(0, 0, 0, 0);
                setShowExecutedBy(check);
            }

            // setWellnessStatus(wellnessPool?.rm_status != null ? wellnessPool?.rm_status : '')
            setValue('rm_status', wellnessPool?.rm_status != null ? wellnessPool?.rm_status : '');

            props.actionEditWellnessPoolClientType();
            props.actionEditWellnessPoolAssignEngineer();
            props.actionWellnessManagementWellnessType();
        }
    }, [wellnessPool])

    const onWellnessTypeUpdate = () => {
        const { WellnessType } = props;
        var WellnessTypeResponse = Object.assign({}, WellnessType);
        if (WellnessTypeResponse.result === true) {
            setWellnessType(WellnessTypeResponse.response);
        } else {
            switch (WellnessTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setWellnessType([]);
                    break;
                default:
            }
        }
    }
    useEffect(onWellnessTypeUpdate, [props.WellnessType]);

    useEffect(() => {
        if (wellnessType != null && wellnessType.length > 0) {
            if (wellnessPool != null && wellnessPool.wellness_type_id != null) {
                setValue('wellness_type_id', wellnessPool?.wellness_type_id != null ? wellnessPool?.wellness_type_id : null);
            }
        }
    }, [wellnessType])

    const onViewWellnessManagmentPoolUpdate = () => {
        const { ViewWellnessManagmentPool } = props;
        var ViewWellnessManagmentPoolResponse = Object.assign({}, ViewWellnessManagmentPool);
        if (ViewWellnessManagmentPoolResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setWellnessPool(ViewWellnessManagmentPoolResponse.response);
            setWellnessDocument(ViewWellnessManagmentPoolResponse.response.wellness_request_document);

            let docCount = ViewWellnessManagmentPoolResponse?.response?.wellness_request_document?.length || 0
            setMaxFileCount(5 - docCount);

        } else {
            $("#progress-loader").addClass("hidden");
            switch (ViewWellnessManagmentPoolResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onViewWellnessManagmentPoolUpdate, [props.ViewWellnessManagmentPool]);


    useEffect(() => {
        if (clientType != null && clientType.length > 0) {
            if (wellnessPool != null) {
                setValue('client_type', wellnessPool.client_type);
            }
        }
    }, [clientType])

    const onwellnessPoolClientTypeUpdate = () => {
        const { WellnessPoolClientType } = props;
        var WellnessPoolClientTypeResponse = Object.assign({}, WellnessPoolClientType);
        if (WellnessPoolClientTypeResponse.result === true) {
            setClientType(WellnessPoolClientTypeResponse.response);
        } else {
            switch (WellnessPoolClientTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onwellnessPoolClientTypeUpdate, [props.WellnessPoolClientType]);

    useEffect(() => {
        if (assignEngineer != null && assignEngineer.length > 0 && wellnessPool !== null) {
            if (wellnessPool != null && wellnessPool.assign_rm_id !== null) {
                setValue('assign_rm', wellnessPool.assign_rm_id);
            } else {
                setValue('assign_rm', 0);
            }
        }
    }, [assignEngineer, wellnessPool])

    const onWellnessPoolAssignEngineerUpdate = () => {
        const { WellnessPoolAssignEngineer } = props;
        var WellnessPoolAssignEngineerResponse = Object.assign({}, WellnessPoolAssignEngineer);
        if (WellnessPoolAssignEngineerResponse.result === true) {
            setAssignEngineer(WellnessPoolAssignEngineerResponse.response);
        } else {
            switch (WellnessPoolAssignEngineerResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }

    useEffect(onWellnessPoolAssignEngineerUpdate, [props.WellnessPoolAssignEngineer]);

    const onEditWellnessManagmentPoolUpdate = () => {
        const { EditWellnessManagmentPool } = props;
        var EditWellnessManagmentPoolResponse = Object.assign({}, EditWellnessManagmentPool);
        $("#edit_wellness_pool").prop("disabled", false);
        if (EditWellnessManagmentPoolResponse.result === true) {
            $("#wellness-pool-edit-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Wellness Updated Successfully");
            if (props?.location?.state?.type === 'WELLNESS_ASSIGNEE_NOTIFICATION' || props?.location?.state?.type === 'WELLNESS_REQUEST_ASSIGN_BY_WELLNESS_USER') {
                props.history.push({
                    pathname: '/notification'
                })
            } else {
                props.history.goBack();
            }
        }
        else {
            $("#wellness-pool-edit-loader").addClass("hidden");
            switch (EditWellnessManagmentPoolResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    toast.error(EditWellnessManagmentPoolResponse.message);
                default:
            }
        }
    }
    useEffect(onEditWellnessManagmentPoolUpdate, [props.EditWellnessManagmentPool]);


    const onWellnessDocumentDeletUpdate = () => {
        const { wellnessDocumentDelete } = props;
        const wellnessDocumentDeleteResponse = Object.assign({}, wellnessDocumentDelete);
        if (wellnessDocumentDeleteResponse.result === true) {
            props.actionViewWellnessPool({ id: props.location.state.id });
        } else {
            switch (wellnessDocumentDeleteResponse.status) {
                case UNAUTHORIZED:
                    Swal.fire({
                        title: 'Session',
                        text: 'Your session has been expired. Please Login to continue'
                    }).then(success => {
                        clearData()
                        setTimeout(() => {
                            window.location.replace('/login')
                        }, 200)
                        props.auth.logout();
                    })

                    break;
                default:
            }
        }
    }
    useEffect(onWellnessDocumentDeletUpdate, [props.wellnessDocumentDelete])

    const onSubmit = (data) => {

        if (files?.length === 0 && wellnessDocument?.length === 0 && wellnessStatus === 'close') {
            toast.error("Document is required");
            return;
        }

        // if (files != null && files.length > 0 && wellnessPool?.wellness_request_document?.length >= 5) {
        //     toast.error("Maximum 5 documents can be uploaded");
        //     return;
        // }
        // if (files != null && wellnessPool?.wellness_request_document?.length + files?.length > 5) {
        //     toast.error(`${wellnessPool?.wellness_request_document?.length} documents uploaded already, only ${5 - wellnessPool?.wellness_request_document?.length} more documents can be uploaded`);
        //     return;
        // }
        $("#wellness-pool-edit-loader").removeClass("hidden");
        $("#edit_wellness_pool").prop("disabled", true);
        data.id = wellnessPool?.id;
        data.client_id = wellnessPool?.client_id;
        data.client_name = wellnessPool?.client_name;
        data.rm_status = data.rm_status != null ? data.rm_status : null;
        var sendFiles = [];
        if (files != null && files.length > 0) {
            $('#error-doc-not-found').addClass('d-none')
            files.forEach((file, indexFile) => {
                sendFiles.push({ 'name': `file[${indexFile}]`, 'value': file });
            });
        } else if (wellnessPool?.wellness_request_document?.length === 0) {
            if (data.rm_status === 'close') {
                $('#error-doc-not-found').removeClass('d-none')
                $("#wellness-pool-edit-loader").addClass("hidden");
                $("#edit_wellness_pool").prop("disabled", false);
                return
            }
        }
        delete data.file;
        if (data.rm_status === null || data.rm_status === "undefined" || data.rm_status === undefined) {
            delete data.rm_status;
        }
        if (data.wellness_executed_by === null || data.wellness_executed_by === "" || data.wellness_executed_by === undefined) {
            delete data.wellness_executed_by;
        }
        if (data.created_at === null || data.created_at === undefined) {
            delete data.created_at;
        }
        if (data.assign_rm == null || data.assign_rm == 0) { delete data.assign_rm; }
        props.actionEditWellnessPool(data, sendFiles);
    }


    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length + files.length > maxFileCount) {
            Swal.fire({
                title: 'Upload Error',
                text: 'Maximum 5 files are allowed.',
                icon: 'error'
            })
            return
        }

        const newFiles = acceptedFiles.filter(file => {
            const ext = file.name.split('.').pop().toLowerCase()
            if (!validExt.includes(`.${ext}`)) {
                Swal.fire({
                    title: 'Upload Error',
                    text: 'You can only upload Word, TXT, Excel, PDF, JPEG, JPG, PNG & ZIP files',
                    icon: 'error'
                })
                return false
            }
            if (file.size >= CLAIM_FILE_SIZE) {
                Swal.fire({
                    title: 'Error',
                    icon: 'error',
                    html: CLAIM_FILE_ERROR_MESSAGE,
                    confirmButtonColor: PRIMARY_COLOR
                })
                return false
            }
            return true
        })

        setFiles(prevFiles => {
            const updatedFiles = [...prevFiles, ...newFiles]
            return updatedFiles
        })

    }, [files, maxFileCount])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/pdf': ['.pdf'],
            'application/zip': ['.zip'],
            'text/plain': ['.txt'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png']
        },
        maxFiles: maxFileCount
    })

    const deleteFile = (index) => {
        setFiles(prevFiles => {
            const updatedFiles = prevFiles.filter((_, i) => i !== index)
            return updatedFiles
        })
    }

    return (
        <>
            <ScreenTitle title="Wellness Pool Edit" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Created Date</label>
                                                    <input
                                                        id="created_at"
                                                        name="created_at"
                                                        className="form-control"
                                                        type="text"
                                                        disabled={true}
                                                        {...register("created_at")}
                                                    />
                                                    {errors.created_at && <ErrorLabel message={errors.created_at?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Type</label>
                                                    <Controller
                                                        name="client_type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_type"
                                                                name="client_type"
                                                                dataSource={clientType}
                                                                allowFiltering={false}
                                                                enabled={false}
                                                                placeholder="Select Client Type"
                                                                value={field.value}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />

                                                    {errors.client_type && <ErrorLabel message={errors.client_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Name</label>
                                                    <input
                                                        id="client_name"
                                                        name="client_name"
                                                        className="form-control"
                                                        type="text"
                                                        disabled={true}
                                                        {...register("client_name")}
                                                    />
                                                    {errors.client_name && <ErrorLabel message={errors.client_name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="wellness_type_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="wellness_type_id"
                                                                name="wellness_type_id"
                                                                fields={{ text: "name", value: "id" }}
                                                                dataSource={wellnessType}
                                                                allowFiltering={false}
                                                                placeholder="Select Type"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        setSelectType(event.itemData.name)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Type is required'
                                                        }}
                                                    />
                                                    {errors.wellness_type_id && <ErrorLabel message={errors.wellness_type_id?.message} />}
                                                </div>
                                            </div>
                                            {
                                                selectType === 'Others' ?
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">Description&nbsp;<span className="danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                id="wellness_type_detail"
                                                                name="wellness_type_detail"
                                                                type="text"
                                                                placeholder="Enter description"
                                                                {...register('wellness_type_detail', {
                                                                    required: selectType === "Others" ? "Description is required" : false
                                                                })}
                                                            />
                                                            {errors.wellness_type_detail?.type && <ErrorLabel message={errors.wellness_type_detail?.message} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                    </>
                                            }
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Location Address&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        type="text"
                                                        maxLength={250}
                                                        placeholder="Enter Location Address"
                                                        disabled={['cancel', 'close'].includes(wellnessPool?.rm_status) ? true : false}
                                                        {...register('address', {
                                                            required: 'Location Address is required',
                                                            maxLength:
                                                            {
                                                                value: 250,
                                                                message: "Please enter 250 charcter only"
                                                            }
                                                        })}
                                                    />
                                                    {errors.address?.type === 'required' && <ErrorLabel message={errors.address?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Date of Activity&nbsp;<span className={`danger ${wellnessStatus === 'close' ? '' : 'hidden'}`}>*</span></label>
                                                    <Controller
                                                        name="activity_date"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="activity_date"
                                                                name="activity_date"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                disabled={['cancel', 'close'].includes(wellnessPool?.rm_status) ? true : false}
                                                                value={field.value}
                                                                onValueChange={(value) => {
                                                                    const check = new Date(value) < new Date().setHours(0, 0, 0, 0);
                                                                    if (!check) {
                                                                        setSelectedExecutedBy(null);
                                                                        setValue("wellness_executed_by", null);
                                                                        setValue("assign_rm", null);
                                                                    }
                                                                    setShowExecutedBy(check);
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: wellnessStatus === 'close' ? 'Please Select Date of Activity' : false
                                                        }}
                                                    />
                                                    {errors.activity_date && <ErrorLabel message={errors.activity_date?.message} />}
                                                </div>
                                            </div>
                                            {showExecutedBy && <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Wellness Executed By&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="wellness_executed_by"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="wellness_executed_by"
                                                                name="wellness_executed_by"
                                                                dataSource={["Internal Team", "Direct Vendor / TPA"]}
                                                                allowFiltering={false}
                                                                placeholder="Select Wellness Executed By"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        if (event.itemData.value === "Direct Vendor / TPA") {
                                                                            setValue("assign_rm", null);
                                                                            setValue("rm_status", "close");
                                                                        }
                                                                        setSelectedExecutedBy(event.itemData.value)
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Wellness Executed By is required'
                                                        }}
                                                    />
                                                    {errors.wellness_executed_by && <ErrorLabel message={errors.wellness_executed_by?.message} />}
                                                </div>
                                            </div>
                                            }
                                            {(departments != null && departments.length > 0 && departments.includes('Wellness') && showExecutedBy === false) || (selectedExecutedBy === "Internal Team") ?
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="label-control">
                                                            {showExecutedBy === false && departments != null && departments.length > 0 && departments.includes('Wellness') ? 'Assigned RM' : 'Assigned RM'}
                                                        </label>
                                                        <Controller
                                                            name="assign_rm"
                                                            control={control}
                                                            render={({ field }) =>
                                                                <DropDownListComponent
                                                                    {...field}
                                                                    id="assign_rm"
                                                                    name="assign_rm"
                                                                    enabled={!['cancel', 'close'].includes(wellnessPool?.rm_status) ? true : false}
                                                                    dataSource={assignEngineer}
                                                                    allowFiltering={false}
                                                                    placeholder="Select Assigned RM"
                                                                    value={field.value}
                                                                    fields={{ text: 'full_name', value: 'user_id' }}
                                                                    change={(event) => {
                                                                        if (event.itemData != null) {
                                                                            field.onChange(event.itemData.user_id)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                        {errors.assign_rm && <ErrorLabel message={errors.assign_rm?.message} />}
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            }

                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">RM Status</label>
                                                    <Controller
                                                        name="rm_status"

                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="rm_status"
                                                                name="rm_status"
                                                                enabled={!['cancel', 'close'].includes(wellnessPool?.rm_status) ? true : false}
                                                                dataSource={[
                                                                    {
                                                                        text: 'Update',
                                                                        value: 'update'
                                                                    },
                                                                    {
                                                                        text: 'Cancel',
                                                                        value: 'cancel'
                                                                    },
                                                                    {
                                                                        text: 'Close',
                                                                        value: 'close'
                                                                    }
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value != null ? field.value : 'update'}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value);
                                                                        setWellnessStatus(event.itemData.value);
                                                                    }
                                                                }}
                                                            />
                                                        }

                                                    />

                                                    {errors.rm_status && <ErrorLabel message={errors.rm_status?.message} />}
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="file-upload">
                                                        Document
                                                        <span className={`danger ${wellnessStatus === 'close' ? '' : 'hidden'}`}>*</span>
                                                    </label>
                                                    <div
                                                        {...getRootProps()}
                                                        className={`dropzone ${isDragActive ? 'active' : ''}`}
                                                        style={{
                                                            border: '2px dashed #cccccc',
                                                            borderRadius: '4px',
                                                            padding: '20px',
                                                            textAlign: 'center',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <input {...getInputProps()} id="file-upload" />
                                                        {isDragActive ? (
                                                            <p>Drop the files here ...</p>
                                                        ) : (
                                                            <p>Drag 'n' drop some files here, or click to select files</p>
                                                        )}
                                                    </div>

                                                    {files.length > 0 && (
                                                        <div className="mt-1">
                                                            <strong>Selected files:</strong>
                                                            <ul className="list-unstyled">
                                                                {files.map((file, index) => (
                                                                    <li key={index} className="d-flex justify-content-between align-items-center">
                                                                        <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                            {file.name}
                                                                        </span>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger btn-sm"
                                                                            onClick={() => deleteFile(index)}>
                                                                            Delete
                                                                        </button>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    )}


                                                    {wellnessDocument !== null && wellnessDocument.length > 0 && (
                                                        <div className="mt-1">
                                                            <strong>Uploaded files:</strong>
                                                            <ul className="list-unstyled">
                                                                {/* Iterate over the array in pairs */}
                                                                {wellnessDocument.map((file, index) => {
                                                                    if (index % 2 === 0) {
                                                                        return (
                                                                            <li key={index} className="row mb-half">
                                                                                {/* First file */}
                                                                                <div className="col-6 d-flex align-items-center">
                                                                                    <div>
                                                                                        <a className="primary"
                                                                                            onClick={() => {
                                                                                                const extension = file.wellness_request_document?.split('.').pop().toLowerCase();
                                                                                                if (['pdf', 'png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
                                                                                                    setSelectedDocumentIndex(file.wellness_request_document);
                                                                                                    setShowDocumentDetails(true);
                                                                                                    setExtension(extension);
                                                                                                } else {
                                                                                                    downloadFile(file.wellness_request_document);
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            Document {index + 1}
                                                                                        </a>
                                                                                        {/* <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                                            Document {index + 1}
                                                                                        </span> */}
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-danger btn-sm ml-1"
                                                                                            onClick={() => {
                                                                                                Swal.fire({
                                                                                                    title: 'Delete Document',
                                                                                                    text: 'Are you sure, you want to delete document?',
                                                                                                    showCancelButton: true,
                                                                                                    cancelButtonText: 'No',
                                                                                                    showConfirmButton: true,
                                                                                                    confirmButtonText: 'Yes',
                                                                                                }).then(result => {
                                                                                                    if (result.isConfirmed) {
                                                                                                        props.actionWellnessDocumentDelete({
                                                                                                            wellness_id: file?.wellness_request_id,
                                                                                                            wellness_dms_id: file?.document_dms_id
                                                                                                        })
                                                                                                    }
                                                                                                })
                                                                                            }}>
                                                                                            Delete
                                                                                        </button>
                                                                                    </div>
                                                                                </div>

                                                                                {/* Second file, if exists */}
                                                                                {wellnessDocument[index + 1] && (
                                                                                    <div className="col-6 d-flex align-items-center">
                                                                                        <div>
                                                                                            <a className="primary"
                                                                                                onClick={() => {
                                                                                                    const extension = file.wellness_request_document?.split('.').pop().toLowerCase();
                                                                                                    if (['pdf', 'png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
                                                                                                        setSelectedDocumentIndex(file.wellness_request_document);
                                                                                                        setShowDocumentDetails(true);
                                                                                                        setExtension(extension);
                                                                                                    } else {
                                                                                                        downloadFile(file.wellness_request_document);
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                Document {index + 2}
                                                                                            </a>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-danger btn-sm ml-2"
                                                                                                onClick={() => {
                                                                                                    Swal.fire({
                                                                                                        title: 'Delete Document',
                                                                                                        text: 'Are you sure, you want to delete document?',
                                                                                                        showCancelButton: true,
                                                                                                        cancelButtonText: 'No',
                                                                                                        showConfirmButton: true,
                                                                                                        confirmButtonText: 'Yes',
                                                                                                    }).then(result => {
                                                                                                        if (result.isConfirmed) {
                                                                                                            props.actionWellnessDocumentDelete({
                                                                                                                wellness_id: file?.wellness_request_id,
                                                                                                                wellness_dms_id: file?.document_dms_id
                                                                                                            })
                                                                                                        }
                                                                                                    })
                                                                                                }}>
                                                                                                Delete
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </li>
                                                                        );
                                                                    }
                                                                    return null;
                                                                })}
                                                            </ul>
                                                        </div>
                                                    )}



                                                    {files?.length === 0 && wellnessDocument?.length === 0 && wellnessStatus === 'close' && (
                                                        <small className="error-msg text-danger">Document is required</small>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Approved By
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Actual Date of Activity&nbsp;<span className={`danger ${wellnessStatus === 'close' ? '' : 'hidden'}`}>*</span></label>
                                                    <Controller
                                                        name="actual_activity_date"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="actual_activity_date"
                                                                name="actual_activity_date"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                disabled={['cancel', 'close'].includes(wellnessPool?.rm_status) ? true : false}
                                                                value={field.value}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: wellnessStatus === 'close' ? 'Please Select Actual Date of Activity' : false
                                                        }}
                                                    />
                                                    {errors.actual_activity_date && <ErrorLabel message={errors.actual_activity_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Remark&nbsp;<span className={`danger ${wellnessStatus === 'close' ? '' : 'hidden'}`}>*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="remarks"
                                                        name="remarks"
                                                        type="text"
                                                        placeholder="Enter Remark"
                                                        disabled={['cancel', 'close'].includes(wellnessPool?.rm_status) ? true : false}
                                                        {...register('remarks', {
                                                            required: wellnessStatus === 'close' ? 'Please Enter Remark' : false
                                                        })}
                                                    />
                                                    {errors.remarks?.type && <ErrorLabel message={errors.remarks?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="edit_wellness_pool"
                                            className={`btn btn-primary mr-1 shadow round text-uppercase ${(!['cancel', 'close'].includes(wellnessPool?.rm_status) ? '' : 'hidden')}`}
                                            type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="wellness-pool-edit-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (props?.location?.state?.type === 'WELLNESS_ASSIGNEE_NOTIFICATION' || props?.location?.state?.type === 'WELLNESS_REQUEST_ASSIGN_BY_WELLNESS_USER') {
                                                    props.history.push({
                                                        pathname: '/notification'
                                                    })
                                                } else {
                                                    props.history.goBack();
                                                }
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                // detailsOfDocument={documentDetails}
                extension={extension}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />
        </>
    )
}
const mapStateToProps = (state) => {
    var ViewWellnessManagmentPool = null;
    var WellnessPoolClientType = null;
    var EditWellnessManagmentPool = null;
    var WellnessPoolAssignEngineer = null;
    var WellnessType = null;
    var wellnessDocumentDelete = null

    if (
        state.EditWellnessManagementPoolReducer != null &&
        Object.keys(state.EditWellnessManagementPoolReducer).length > 0 &&
        state.EditWellnessManagementPoolReducer.constructor === Object
    ) {
        switch (state.EditWellnessManagementPoolReducer.tag) {
            case ACTION_VIEW_WELLNESS_POOL_SUCCESS:
                ViewWellnessManagmentPool = Object.assign({}, state.EditWellnessManagementPoolReducer.data);
                delete state.EditWellnessManagementPoolReducer;
                return { ViewWellnessManagmentPool };
            case ACTION_EDIT_WELLNESS_POOL_CLIENT_TYPE_SUCCESS:
                WellnessPoolClientType = Object.assign({}, state.EditWellnessManagementPoolReducer.data);
                delete state.EditWellnessManagementPoolReducer;
                return { WellnessPoolClientType };
            case ACTION_WELLNESS_POOL_EDIT_SUCCESS:
                EditWellnessManagmentPool = Object.assign({}, state.EditWellnessManagementPoolReducer.data);
                delete state.EditWellnessManagementPoolReducer;
                return { EditWellnessManagmentPool };
            case ACTION_EDIT_WELLNESS_POOL_ASSIGN_ENGINEER_SUCCESS:
                WellnessPoolAssignEngineer = Object.assign({}, state.EditWellnessManagementPoolReducer.data);
                delete state.EditWellnessManagementPoolReducer;
                return { WellnessPoolAssignEngineer };
            case ACTION_EDIT_WELLNESS_POOL_TYPE_SUCCESS:
                WellnessType = Object.assign({}, state.EditWellnessManagementPoolReducer.data);
                delete state.EditWellnessManagementPoolReducer;
                return { WellnessType };
            case ACTION_WELLNESS_DOCUMENT_DELETE_SUCCESS:
                wellnessDocumentDelete = Object.assign({}, state.EditWellnessManagementPoolReducer.data);
                delete state.EditWellnessManagementPoolReducer;
                return { wellnessDocumentDelete }
            default:
        }
    }
    return {
        ViewWellnessManagmentPool,
        WellnessPoolClientType,
        EditWellnessManagmentPool,
        WellnessPoolAssignEngineer,
        WellnessType,
        wellnessDocumentDelete
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionViewWellnessPool,
        actionEditWellnessPoolClientType,
        actionEditWellnessPool,
        actionEditWellnessPoolAssignEngineer,
        actionWellnessManagementWellnessType,
        actionWellnessDocumentDelete
    }, dispatch)
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(WellnessPoolEdit))