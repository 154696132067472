import { React, useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { UNAUTHORIZED } from "../../../../../constants";
import { useForm, Controller } from "react-hook-form";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import ArrayStore from 'devextreme/data/array_store';
import { TagBox } from 'devextreme-react/tag-box';
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import $ from "jquery";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withAuth } from "react-auth-guard";
import { actionPolicyList, actionDepartmentPolicyType, actionProposalRM, ACTION_POLICY_VIEW_LIST_SUCCESS, ACTION_MASTER_ASSIGN_RM_SUCCESS, ACTION_CLAIMS_POLICY_TYPE_SUCCESS } from "./action"
import { FiEye } from 'react-icons/fi';
import PolicyDetails from "./component/policy-details/index"
import Policy_filter from '../../../../../component/popups/policy_filter';
import { verifyPermission } from '../../../../../utils';
import moment from 'moment';

const PolicyView = (props) => {
    const { control, setValue } = useForm();
    const [policyDetails, setPolicyDetails] = useState(null);
    const [policyTypeValue, setPolicyTypeValue] = useState([])
    const [policiesSeries, setPoliciesSeries] = useState([]);
    const [showPolicyDetailsView, setShowPolicyDetailsView] = useState(false);
    const [policyListSelectedData, setPolicyListSelectedData] = useState(null);
    const [proposalRMType, setProposalRMType] = useState([]);
    const [proposalRm, setProposalRM] = useState(null);
    const [departmentType, setDepartmentType] = useState(null);
    const [showPolicyFilter, setShowPolicyFilter] = useState(false)

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionPolicyList({
            "rm_id": props.location.state?.rmNameId,
            "department_id": props.location.state?.policyType,
            "days_count": props.location.state?.daysCount,
            "title": props.location.state?.title,
            "insurance_company": props.location.state?.insuranceCompanyValue,
            "policy_type": props.location.state?.policyValue,
            "min_sum_insured": props.location.state?.min,
            "max_sum_insured": props.location.state?.max,
            "min_gross_premium": props.location.state?.minGross,
            "max_gross_premium": props.location.state?.maxGross,
        });
        props.actionDepartmentPolicyType({});
        props.actionProposalRM({ user_id: props.auth.getUser().user_id });
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (props != null) {
            setValue('department_id', props.location.state?.policyType);
            setValue('rm_id', props.location.state?.rmNameId);
        }
    }, [])


    const columns = [
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "policy_type", caption: "Policy type", visible: true },
        { dataField: "department", caption: "Department", visible: true },
        { dataField: "customer_name", caption: "Customer Name", visible: true },
        { dataField: "insurance_company", caption: "Insurance Company", visible: true },
        { dataField: "brokerage_amount", caption: "Brokerage amt.", visible: true, type: String },
        { dataField: "broaker_name", caption: "RM Name", visible: true },
        { dataField: "gross_premium", caption: "Gross Premium", visible: true },
        { dataField: "net_premium", caption: "Net Premium", visible: true },
        { dataField: "policy_expiry_date", caption: "Expiry Date", visible: false },
        { dataField: "policy_expiry_date_formated", caption: "Expiry Date", visible: true },
        { dataField: "policy_rm_reporting_person_name", caption: "Reporting To", visible: false },
        { dataField: "company_group_name", caption: "Customer Group Name", visible: false },
        { dataField: "sum_insured", caption: "Sum Insured", visible: false },
        { dataField: "retention_name", caption: "Retention RM", visible: false },
        { dataField: "endorsement_brokerage_amount", caption: "Endorsement Brokerage", visible: false },
        { dataField: "endorsement_net_premium", caption: "Endorsement Net Premium", visible: false },
        { dataField: "endorsement_gross_premium", caption: "Endorsement Gross premium", visible: false },
        { dataField: "endorsement_count", caption: "Endorsement Count", visible: false },
    ];

    const renderExpiryDate = (cell) => {
        return <div>{cell?.data?.policy_expiry_date_formated != null ? moment(cell?.data?.policy_expiry_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            <Button
                                render={(params) => (
                                    <button
                                        className="btn btn-icon btn-sm"
                                        onClick={() => {
                                            setShowPolicyDetailsView(true);
                                            setPolicyListSelectedData(params.data)
                                        }}
                                    >
                                        <FiEye size={16} className="primary" />
                                    </button>
                                )}
                            />
                        }
                    </Column>
                );
            }
            else if (objColumn.dataField === "policy_expiry_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderExpiryDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                );
            }

        }

        );
        return arrColumns;
    };

    const onPolicyListUpdate = () => {
        const { PolicyList } = props;
        var PolicyListResponse = Object.assign({}, PolicyList);
        $("#policy-view").prop("disabled", false);
        if (PolicyListResponse.result === true) {
            $("#policy-view-loader").addClass("hidden");
            setPolicyDetails(PolicyListResponse.response);
            var policiesSeriesValues = [];
            PolicyListResponse.response.policy_details?.policy_type_series.forEach(element => {
                policiesSeriesValues.push(element);
            });
            if (policiesSeriesValues.length > 0) {
                setPoliciesSeries(policiesSeriesValues)
            }
            $("#progress-loader").addClass("hidden");
        } else {
            $("#policy-view-loader").addClass("hidden");
            switch (PolicyListResponse.status) {
                case UNAUTHORIZED:

                default:
            }
        }
    }
    useEffect(onPolicyListUpdate, [props.PolicyList]);

    const onMasterProposalRMUpdate = () => {
        const { ProposalRM } = props;
        var ProposalRMResponse = Object.assign({}, ProposalRM);
        $("#policy-reset").prop("disabled", false);
        if (ProposalRMResponse.result === true) {
            $("#policy-reset-loader").addClass("hidden");
            var arrAssignRM = Object.assign([], ProposalRMResponse.response);
            setProposalRM(arrAssignRM);
            props.actionDepartmentPolicyType();
        } else {
            $("#policy-reset-loader").addClass("hidden");
            switch (ProposalRMResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onMasterProposalRMUpdate, [props.ProposalRM]);

    const onDepartmentTypeUpdate = () => {
        const { DepartmentType } = props;
        if (DepartmentType != null) {
            var ProposalPolicyTypeResponse = Object.assign({}, DepartmentType);
            $("#policy-reset").prop("disabled", false);
            if (ProposalPolicyTypeResponse.result === true) {
                $("#policy-reset-loader").addClass("hidden");
                setDepartmentType(ProposalPolicyTypeResponse.response);
            } else {
                $("#policy-reset-loader").addClass("hidden");
                switch (ProposalPolicyTypeResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout()
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onDepartmentTypeUpdate, [props.DepartmentType]);

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0">Renewal Details</h5>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                    <div className="flex-column">
                        <div className="d-flex flex-row w-100 justify-content-start justify-content-lg-end">
                            <button
                                className="btn btn-secondary btn-sm mr-1 mb-1 mb-md-0 mb-lg-0 shadow round"
                                onClick={() => {
                                    props.history.goBack();
                                }}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="card h-100">
                    <div className="card-body">
                        <form noValidate autoComplete="Off" >
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <h4 className=" mb-0">Filters</h4>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 col-md-4 col-lg-4 col-xl-3">
                                        <div className="form-group">
                                            <label className="label-control">RM Name&nbsp;</label>
                                            <Controller
                                                name="rm_id"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: proposalRm,
                                                                key: 'id'
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                ('initial')
                                                            },
                                                            onContentReady: () => {
                                                                ('content ready')
                                                            },
                                                            onShowing: () => {
                                                                ('showing')
                                                            },
                                                            onShown: () => {
                                                                ('shown')
                                                            }
                                                        }}
                                                        deferRendering={true}
                                                        displayExpr="name"
                                                        valueExpr="id"
                                                        placeholder="Select RM Name"
                                                        searchEnabled={true}
                                                        searchMode="contains"
                                                        maxDisplayedTags={2}
                                                        showMultiTagOnly={false}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        value={field.value}
                                                        onValueChanged={({ value }) => {
                                                            if (value != null || value !== undefined) {
                                                                setProposalRMType(value);
                                                                field.onChange(value);
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 col-xl-3">
                                        <div className="form-group">
                                            <label className="label-control">Type of Department&nbsp;</label>
                                            <Controller
                                                name="department_id"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        id="department_id"
                                                        name="department_id"
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: departmentType,
                                                                key: 'id'
                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                ('initial')
                                                            },
                                                            onContentReady: () => {
                                                                ('content ready')
                                                            },
                                                            onShowing: () => {
                                                                ('showing')
                                                            },
                                                            onShown: () => {
                                                                ('shown')
                                                            }
                                                        }}
                                                        deferRendering={true}
                                                        displayExpr="name"
                                                        valueExpr="id"
                                                        placeholder="Select Type of Department"
                                                        searchEnabled={true}
                                                        searchMode="contains"
                                                        maxDisplayedTags={2}
                                                        showMultiTagOnly={false}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        value={field.value}
                                                        onValueChanged={({ value }) => {
                                                            if (value != null || value !== undefined) {
                                                                setPolicyTypeValue(value);
                                                                field.onChange(value);
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                            />

                                        </div>
                                    </div>
                                    <div className="col-4 col-md-2 col-lg-2 col-xl-1">
                                        <div className="form-actions mt-1 border-0 float-none">
                                            <button
                                                id="policy-view"
                                                type='submit'
                                                className="btn btn-primary mr-8  btn-md w-80 shadow round"
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    $("#policy-view-loader").removeClass("hidden");
                                                    $("#policy-view").prop("disabled", true);
                                                    $("#progress-loader").removeClass("hidden");
                                                    props.actionPolicyList({
                                                        "rm_id": proposalRMType,
                                                        "department_id": policyTypeValue,
                                                        "days_count": props.location.state.daysCount,
                                                        "title": props.location.state.title
                                                    })
                                                }}
                                            >
                                                <div className="d-flex align-items-center">
                                                    &nbsp;Apply
                                                    <span id="policy-view-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                </div>
                                            </button>

                                        </div>
                                    </div>
                                    <div className="col-4 col-md-2 col-lg-2 col-xl-1">
                                        <div className="form-actions mt-1 border-0 float-none">
                                            <button
                                                id="policy-reset"
                                                type='button'
                                                className="btn btn-primary  btn-md w-80 shadow round"
                                                onClick={(event) => {
                                                    $("#policy-reset-loader").removeClass("hidden");
                                                    $("#policy-reset").prop("disabled", true);
                                                    $("#progress-loader").removeClass("hidden");
                                                    setPolicyTypeValue(null);
                                                    setProposalRMType(null)
                                                    setValue('department_id', null);
                                                    setValue('rm_id', null);
                                                    props.actionPolicyList({
                                                        "days_count": props.location.state.daysCount,
                                                        "title": props.location.state.title
                                                    })
                                                    props.actionProposalRM();
                                                    props.actionDepartmentPolicyType();

                                                }
                                                }
                                            >
                                                <div className="d-flex align-items-center">
                                                    &nbsp;Reset
                                                    <span id="policy-reset-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                </div>
                                            </button>

                                        </div>
                                    </div>
                                    <div className="col-4 col-md-2 col-lg-2 col-xl-1">
                                        <div className="form-actions mt-1 border-0 float-none">
                                            <button
                                                id="policy-filter"
                                                type='button'
                                                className="btn btn-primary  btn-md w-80 shadow round"
                                                onClick={() => {
                                                    setShowPolicyFilter(true)
                                                }}
                                            >
                                                <div className="d-flex align-items-center">
                                                    &nbsp;Filter
                                                    <span id="policy-reset-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {
                    Number(policyDetails?.policy_details?.policy_count) > 0 ?
                        <>
                            <div className='row'>
                                <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6">
                                    <div className="card h-100 shadow mb-0">
                                        <div className="card-header border-bottom px-1 py-1">
                                            <div className="d-flex flex-row justify-content-between ">
                                                <div className="flex-column">
                                                    <h4 className="font-medium-1 text-black text-bold-600 mb-0 text-md-center ">
                                                        {policyDetails?.policy_details?.title}

                                                    </h4>
                                                </div>
                                                <div className="font-weight-bold mb-0 h2  d-flex flex-column-reverse">
                                                    {policyDetails?.policy_details?.policy_count}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-xl-2 ml-xl-1'> </div>
                                            <div className="col-12 col-lg-6 justify-content-around statistics-chart  mt-2 mb-1 mb-1">
                                                <Chart
                                                    type="donut"
                                                    width={330}
                                                    options={{
                                                        dataLabels: {
                                                            enabled: true,
                                                            style: {
                                                                fontSize: "10px",
                                                                fontWeight: 400,
                                                            },
                                                            textAnchor: "middle",
                                                        },
                                                        plotOptions: {
                                                            pie: {
                                                                expandOnClick: true,
                                                                customScale: 1,
                                                                donut: {
                                                                    size: "55%",
                                                                },
                                                            },
                                                        },
                                                        legend: {
                                                            fontSize: "10px",
                                                            fontWeight: 600,
                                                            position: "right",
                                                            horizontalAlign: 'right',
                                                            markers: {
                                                                width: 5,
                                                                height: 5,
                                                                radius: 5,
                                                            },
                                                            itemMargin: {
                                                                vertical: 0,
                                                            },
                                                        },
                                                        labels: (policyDetails?.policy_details?.policy_type_label)
                                                    }}
                                                    series={policiesSeries}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 col-xl-6 mt-2 mt-md-0">
                                    <div className="card dashboard shadow h-100 mb-0">
                                        <div className="border-bottom px-1">
                                            <div className='row'>
                                                <div className="col-6 col-md-6 col-lg-6 py-1">
                                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                                        <div className="flex-column">
                                                            <h4 className="font-medium-1 text-black text-md-center text-bold-600 mb-0">
                                                                Total Without Endorsement
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 py-1">
                                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                                        <div className="flex-column">
                                                            <h4 className="font-medium-1 text-black text-md-center text-bold-600 mb-0">
                                                                Total With Endorsement
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-6 col-md-6 col-lg-6 py-1">
                                                <div className="col-12 col-md-12 col-lg-12 py-1">
                                                    <h4 className='text-bold-600  text-md-center font-medium-1'>Inception Brokerage</h4>
                                                    <h2 className='text-bold-600 text-md-center px-1'><small className='text-bold-600 font-medium-2'><span>&#8377;&nbsp;</span></small>{policyDetails?.policy_details?.total_policy_brokerage?.toFixed(2)}<small class="text-bold-600 font-medium-1 ml-half">{policyDetails?.policy_details?.total_policy_brokerage_unit}</small></h2>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12 py-1">
                                                    <h4 className='text-bold-600 text-md-center font-medium-1'>Inception Net Premium</h4>
                                                    <h2 className='text-bold-600 text-md-center px-1'><small className='text-bold-600 font-medium-2'><span>&#8377;&nbsp;</span></small>{policyDetails?.policy_details?.total_net_policy_premium?.toFixed(2)}<small class="text-bold-600 font-medium-1 ml-half">{policyDetails?.policy_details?.total_net_policy_premium_unit}</small></h2>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12 py-1">
                                                    <h4 className='text-bold-600 text-md-center font-medium-1'>Inception Gross Premium</h4>
                                                    <h2 className='text-bold-600 text-md-center px-1'><small className='text-bold-600 font-medium-2'><span>&#8377;&nbsp;</span></small>{policyDetails?.policy_details?.total_policy_premium?.toFixed(2)}<small class="text-bold-600 font-medium-1 ml-half">{policyDetails?.policy_details?.total_policy_premium_unit}</small></h2>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 py-1">
                                                <div className="col-12 col-md-12 col-lg-12 py-1">
                                                    <h4 className='text-bold-600  text-md-center font-medium-1'>Expected Brokerage</h4>
                                                    <h2 className='text-bold-600 text-md-center px-1'><small className='text-bold-600 font-medium-2'><span>&#8377;&nbsp;</span></small>{policyDetails?.policy_details?.total_brokerage?.toFixed(2)}<small class="text-bold-600 font-medium-1 ml-half">{policyDetails?.policy_details?.total_brokerage_unit}</small></h2>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12 py-1">
                                                    <h4 className='text-bold-600 text-md-center font-medium-1'>Expected Net Premium</h4>
                                                    <h2 className='text-bold-600 text-md-center px-1'><small className='text-bold-600 font-medium-2'><span>&#8377;&nbsp;</span></small>{policyDetails?.policy_details?.total_net_premium?.toFixed(2)}<small class="text-bold-600 font-medium-1 ml-half">{policyDetails?.policy_details?.total_net_premium_unit}</small></h2>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12 py-1">
                                                    <h4 className='text-bold-600 text-md-center font-medium-1'>Expected Gross Premium</h4>
                                                    <h2 className='text-bold-600 text-md-center px-1'><small className='text-bold-600 font-medium-2'><span>&#8377;&nbsp;</span></small>{policyDetails?.policy_details?.total_premium?.toFixed(2)}<small class="text-bold-600 font-medium-1 ml-half">{policyDetails?.policy_details?.total_premium_unit}</small></h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            </div >
                        </>
                        :
                        <p className="text-md-center font-medium-3 text-bold-600 px-1 mt-5 pt-1 mx-auto">{policyDetails?.policy_details?.policy_count === undefined ? '' : "No policy details found"}</p>
                }
                {
                    policyDetails?.policy_details?.policy_list != null && policyDetails?.policy_details?.policy_list.length != 0 ?
                        <div className="card my-2">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <h4 className="content-header-title text-capitalize text-bold-800 text-black mb-0 pb-0">
                                            {policyDetails?.policy_details?.title + " "} {policyDetails?.policy_details?.policy_count != 0 ? `(${policyDetails?.policy_details?.policy_count})` : ""}
                                        </h4>
                                    </div>
                                    <div className="col-12 col-lg-12 col-md-12">
                                        <DataGrid
                                            dataSource={policyDetails?.policy_details?.policy_list}
                                            showBorders={true}
                                            columnAutoWidth={true}
                                            export={{
                                                fileName: "Renewal Details",
                                                enabled: verifyPermission("POLICY_DASHBOARD_EXPORT") ? true : false,
                                                allowExportSelectedData: false,
                                                excelFilterEnabled: true,
                                            }}
                                            onExporting={(event) => {
                                                event.component.beginUpdate();
                                                event.component.columnOption("policy_rm_reporting_person_name", "visible", true)
                                                event.component.columnOption("company_group_name", "visible", true)
                                                event.component.columnOption("retention_name", "visible", true)
                                                event.component.columnOption("sum_insured", "visible", true)
                                                event.component.columnOption("endorsement_brokerage_amount", "visible", true)
                                                event.component.columnOption("endorsement_net_premium", "visible", true)
                                                event.component.columnOption("endorsement_gross_premium", "visible", true)
                                                event.component.columnOption("endorsement_count", "visible", true)
                                                event.component.columnOption("policy_expiry_date_formated", "visible", false)
                                                event.component.columnOption("policy_expiry_date", "visible", true)

                                            }}
                                            onExported={(event) => {
                                                event.component.columnOption("policy_rm_reporting_person_name", "visible", false)
                                                event.component.columnOption("company_group_name", "visible", false)
                                                event.component.columnOption("retention_name", "visible", false)
                                                event.component.columnOption("sum_insured", "visible", false)
                                                event.component.columnOption("endorsement_brokerage_amount", "visible", false)
                                                event.component.columnOption("endorsement_net_premium", "visible", false)
                                                event.component.columnOption("endorsement_gross_premium", "visible", false)
                                                event.component.columnOption("endorsement_count", "visible", false)
                                                event.component.columnOption("policy_expiry_date_formated", "visible", true)
                                                event.component.columnOption("policy_expiry_date", "visible", false)
                                                event.component.endUpdate();
                                            }}

                                            onToolbarPreparing={(e) => {
                                                e.toolbarOptions.items.unshift({
                                                    location: 'before'

                                                })
                                            }}
                                        >
                                            <SearchPanel visible={true} />
                                            <FilterRow visible={true} />
                                            <HeaderFilter visible={true} />
                                            {renderColumns()}
                                            <Pager
                                                visible={true}
                                                showPageSizeSelector={false}
                                                showNavigationButtons={true}
                                                allowedPageSizes={[4, 6, 10]}
                                            />
                                            <Paging
                                                defaultPageIndex={0}
                                                defaultPageSize={10}
                                                enabled={true}
                                            />
                                        </DataGrid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }
                <PolicyDetails
                    show={showPolicyDetailsView}
                    policyDetails={policyDetails}
                    handleClose={() => {
                        setShowPolicyDetailsView(false);
                    }}
                    policyListSelectedData={policyListSelectedData}
                />
                <Policy_filter
                    {...props}
                    show={showPolicyFilter}
                    department={policyTypeValue !== null ? policyTypeValue : props.location.state.policyType}
                    type='view'
                    rm_id={proposalRMType !== null ? proposalRMType : props.location?.state?.rmNameId}
                    days_count={props.location.state?.daysCount}
                    title={props.location.state?.title}
                    handleClose={() => {
                        setShowPolicyFilter(false)
                    }}
                />
            </section >
        </>
    )
}

const mapStateToProps = (state) => {
    var PolicyList = null;
    var ProposalRM = null;
    var DepartmentType = null;

    if (
        state.PolicyViewReducer != null &&
        Object.keys(state.PolicyViewReducer).length > 0 &&
        state.PolicyViewReducer.constructor === Object
    ) {
        switch (state.PolicyViewReducer.tag) {
            case ACTION_POLICY_VIEW_LIST_SUCCESS:
                PolicyList = Object.assign({}, state.PolicyViewReducer.data);
                delete state.PolicyViewReducer;
                return { PolicyList };
            case ACTION_MASTER_ASSIGN_RM_SUCCESS:
                ProposalRM = Object.assign({}, state.PolicyViewReducer.data);
                delete state.PolicyViewReducer;
                return { ProposalRM };
            case ACTION_CLAIMS_POLICY_TYPE_SUCCESS:
                DepartmentType = Object.assign({}, state.PolicyViewReducer.data);
                delete state.PolicyViewReducer;
                return { DepartmentType };
            default:
        }
    }
    return {
        PolicyList,
        ProposalRM,
        DepartmentType,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionPolicyList, actionDepartmentPolicyType, actionProposalRM,
        },
        dispatch
    );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(PolicyView))



