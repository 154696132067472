import React, { useEffect, useState } from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from 'react-icons/fi';
import { RiLayoutGridFill } from 'react-icons/ri';
import { BsTable } from 'react-icons/bs';
import Swal from 'sweetalert2';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UNAUTHORIZED, ERROR } from '../../../../../../constants';
import { withAuth } from 'react-auth-guard';
import $ from "jquery";
import moment from "moment";
import { verifyPermission } from '../../../../../../utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { ACTION_INVENTORY_LIST_SUCCESS, actionInventoryList } from './action';
import _ from 'lodash';


const InventoryList = (props) => {

    const { match } = props;
    const [inventoryList, setInventoryList] = useState([]);
    const [selectedTemplate, setselectedTemplate] = useState('LIST')

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");

        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }

        props.actionInventoryList({});
    }
    useEffect(initialRender, []);



    const onInventoryListUpdate = () => {
        const { InventoryList } = props
        if (InventoryList != null) {
            var InventoryListResponse = Object.assign({}, InventoryList);
            if (InventoryListResponse.result === true) {

                const transformedData = InventoryListResponse.response.map(item => {
                    const profile = item.inventory_history[0]?.profile;
                    if (profile) {
                        // Add profile properties to the root level
                        item.branch_city =  item?.status === 'Discarded' || item?.status === 'Unassigned'  ?  "" : profile?.branch_city || '';
                        item.department_name =  item?.status === 'Discarded' || item?.status === 'Unassigned'  ?  "" : profile?.department_name || '';
                        item.full_name =  item?.status === 'Discarded' || item?.status === 'Unassigned'  ?  "" : profile?.full_name || '';
                        item.pinc_employee_id =  item?.status === 'Discarded' || item?.status === 'Unassigned'  ?  "" : profile?.pinc_employee_id || '';
                    }
                    return item;
                });

                $("#progress-loader").addClass("hidden");
                setInventoryList(transformedData);
            } else {
                switch (InventoryListResponse.status) {
                    case UNAUTHORIZED:
                        Swal.fire({
                            title: 'Error',
                            text: 'Sorry, Your token expired',
                            icon: 'error'
                        }).then(success => {
                            props.auth.logout()
                        })
                        break;
                    case ERROR:
                        $("#progress-loader").addClass("hidden");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onInventoryListUpdate, [props.InventoryList])

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "full_name", caption: "Employee Name", dataType: "string", visible: true },
        { dataField: "pinc_employee_id", caption: "Employee ID", visible: true },
        { dataField: "department_name", caption: "Department", dataType: "string", visible: true },
        { dataField: "branch_city", caption: "Branch", dataType: "string", visible: true },
        { dataField: "asset_type", caption: "Assest Type", dataType: "string", visible: true }, //mapped
        { dataField: "brand", caption: "Brand", dataType: "string", visible: true }, // mapped
        { dataField: "model", caption: "Model Name", dataType: "string", visible: true }, //mapped
        { dataField: "serial_no", caption: "Serial Number", dataType: "string", visible: true }, //mapped
        { dataField: "date_of_purchase", caption: "Date of Purchase", dataType: "string", visible: true }, //mapped
        { dataField: "warranty_expiry_date", caption: "Warranty Status", dataType: "string", visible: true },
        { dataField: "status", caption: "Status", dataType: "string", visible: true },
    ]);

    // const renderStatusCell = (cell) => {
    //     console.log(cell);
    //     switch (cell.data.status_name) {
    //         case 'Open':
    //             return <div className={"badge badge-success round px-1 cursor-pointer"}>{cell.data.status_name}</div>;
    //         case 'In Progress':
    //         case 'To Review':
    //             return <div className={"badge badge-info round px-1 cursor-pointer"}>{cell.data.status_name}</div>;
    //         case 'Completed':
    //             return <div className={"badge badge-warning round px-1 cursor-pointer"}>{cell.data.status_name}</div>;
    //         case 'Cancel':
    //             return <div className={"badge badge-danger round px-1 cursor-pointer"}>{cell.data.status_name}</div>;
    //         default:
    //             return <div className={"badge badge-info round px-1 cursor-pointer"}>{cell.data.status_name}</div>;
    //     }
    // }

    const renderTypeCell = (cell) => {
        console.log(cell);
        return <div>{_.capitalize(cell.data.type)}</div>;
    }

    const renderPurchaseDate = (cell) => {
        return <div>{cell?.data?.date_of_purchase != null ? moment(cell?.data?.date_of_purchase).format('DD/MM/YYYY') : ''}</div>
    }

    const renderWarrantyDate = (cell) => {
        return <div>{cell?.data?.warranty_expiry_date != null ? moment(cell?.data?.warranty_expiry_date).format('DD/MM/YYYY') : ''}</div>
    }

    const getFullNames = (inventory) => {
        // return inventory?.profile[0].full_name;
        if (Array.isArray(inventory) && inventory.length > 0) {
            // Return the full_name of the most recent profile in inventory_history
            return inventory[0]?.profile?.full_name || '';
        }
        // Return an empty string if inventory is empty or undefined
        return '';
    };

    const getEmployeeIds = (inventory) => {
        if (Array.isArray(inventory) && inventory.length > 0) {
            // Return the full_name of the most recent profile in inventory_history
            return inventory[0]?.profile?.pinc_employee_id || '';
        }
        // Return an empty string if inventory is empty or undefined
        return '';
        // return inventory?.map(item => item?.profile?.pinc_employee_id).join(" ");
    };

    const getDepartment = (inventory) => {
        if (Array.isArray(inventory) && inventory.length > 0) {
            // Return the full_name of the most recent profile in inventory_history
            return inventory[0]?.profile?.department_name || '';
        }
        // Return an empty string if inventory is empty or undefined
        return '';
        // return inventory?.map(item => item?.profile?.department_name).join(" ");
    };

    const getBranch = (inventory) => {
        if (Array.isArray(inventory) && inventory.length > 0) {
            // Return the full_name of the most recent profile in inventory_history
            return inventory[0]?.profile?.branch_city || '';
        }
        // Return an empty string if inventory is empty or undefined
        return '';
        // return inventory?.map(item => item?.profile?.branch_city).join(" ");
    };

    const getBrand = (inventory) => {
        return inventory?.map(item => item?.profile?.brand).join(" ");
    };

    const getSerialNo = (inventory) => {
        return inventory?.map(item => item?.profile?.serial_no).join(" ");
    };



    const renderFullName = (cell) => {
        return <div>{getFullNames(cell?.data?.inventory_history || [])}</div>
    }

    const renderEmployeeId = (cell) => {
        return <div>{getEmployeeIds(cell?.data?.inventory_history || [])}</div>
    }

    const renderDepartment = (cell) => {
        return <div>{getDepartment(cell?.data?.inventory_history || [])}</div>
    }

    const renderBranch = (cell) => {
        return <div>{getBranch(cell?.data?.inventory_history || [])}</div>
    }

    const renderBrand = (cell) => {
        return <div>{cell?.data?.brand || "N/A"}</div>;
    };

    const renderSerialNo = (cell) => {
        return <div>{cell?.data?.serial_no || "N/A"}</div>;
    };


    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        {
                            verifyPermission("CREATE_TASK") ?
                                <li class="nav-item">
                                    <button
                                        className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                        onClick={() => {
                                            props.history.push(`${match.url}/add`);
                                        }}
                                    >
                                        Create Inventory
                                    </button>
                                </li>
                                :
                                <></>
                        }
                    </ul>
                </div>
            </>
        )

    }
    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                        props.history.push({ pathname: `${match.url}/view`, state: { detail: params.data } })
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                        :
                        <></>

                        <Button
                            render={(params) => (
                                <button
                                    className={`btn btn-icon btn-sm`}
                                    onClick={() => {
                                        props.history.push({ pathname: `${match.url}/edit`, state: { detail: params.data } })
                                    }}
                                >
                                    <FiEdit size={16} className="primary" />
                                </button>
                            )}
                        />

                    </Column>
                );
            } else {

                if (objColumn.dataField === "date_of_purchase") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderPurchaseDate}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else if (objColumn.dataField === "warranty_expiry_date") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderWarrantyDate}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else if (objColumn.dataField === "brand") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderBrand}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else if (objColumn.dataField === "serial_no") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderSerialNo}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }

            }
        });
        return arrColumns;
    };

    const renderListUI = () => {
        return (
            <DataGrid
                dataSource={inventoryList}
                columnAutoWidth={true}
                showBorders={true}
                export={{
                    fileName: "Inventory Details",
                    enabled: true,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
                onExporting={(event) => {
                    event.component.beginUpdate();
                    event.component.columnOption("created_date_formated", "visible", false)
                    event.component.columnOption("created_date", "visible", true)
                    event.component.columnOption("delivery_date_formated", "visible", false)
                    event.component.columnOption("delivery_date", "visible", true)
                }}
                onExported={(event) => {
                    event.component.columnOption("created_date_formated", "visible", true)
                    event.component.columnOption("created_date", "visible", false)
                    event.component.columnOption("delivery_date_formated", "visible", true)
                    event.component.columnOption("delivery_date", "visible", false)
                    event.component.endUpdate();
                }}
                onToolbarPreparing={(e) => {
                    e.toolbarOptions.items.unshift({
                        location: 'before',
                        // template: 'totalGroupCount',

                    })
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderColumns()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />
            </DataGrid>
        )
    }
    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0">Inventory List {inventoryList.length === 0 ? '' : `(${inventoryList.length})`}</h5>
                </div>
                {renderHeaderButtonUI()}
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            {
                                selectedTemplate === "LIST" ?
                                    renderListUI()
                                    : <></>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    var InventoryList = null;
    if (
        state.InventoryListReducer != null &&
        Object.keys(state.InventoryListReducer).length > 0 &&
        state.InventoryListReducer.constructor === Object
    ) {
        switch (state.InventoryListReducer.tag) {
            case ACTION_INVENTORY_LIST_SUCCESS:
                InventoryList = Object.assign({}, state.InventoryListReducer.data);
                delete state.InventoryListReducer;
                return { InventoryList };
            default:
        }
    }
    return {
        InventoryList
    };
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionInventoryList }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(InventoryList))