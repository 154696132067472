import React, { useEffect, useState } from 'react';
import { withAuth } from 'react-auth-guard';
import { DateBox } from 'devextreme-react/date-box';
import dateFormat from 'dateformat';
import $ from 'jquery';
import { useForm, Controller } from "react-hook-form";
import ScreenTitle from '../../../../../../../component/screen_title';
import ErrorLabel from '../../../../../../../component/form/error_label';
import { actionLossSiteVisit, ACTION_ADD_LOSS_SITE_VISIT_SUCCESS } from './action';
import { actionLossSiteVisitPolicyType, ACTION_ADD_LOSS_SITE_VISIT_NAME_OF_INSURER_SUCCESS } from './action';
import { actionLossSiteVisitNameOfInsurer, ACTION_ADD_LOSS_SITE_VISIT_POLICY_TYPE_SUCCESS } from './action';
import { actionAddClaimsView, ACTION_ADD_CLAIMS_VIEW_SUCCESS } from './action';
import Swal from 'sweetalert2';
import { ERROR, UNAUTHORIZED } from '../../../../../../../constants';
import { KEY_TOAST_MESSAGE, setData } from '../../../../../../../utils/preferences';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';

const AddLossSiteVisit = (props) => {

   const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();

   const [lossSiteVisitPolicyType, setLossSiteVisitPolicyType] = useState([]);
   const [lossSiteVisitNameOfInsurer, setLossSiteVisitNameOfInsurer] = useState([]);
   const [addClaimView, setAddClaimView] = useState(null);

   const initialRender = () => {
      props.actionAddClaimsView({ id: props.location.state.claim_id });
   }
   useEffect(initialRender, []);


   const onLossSiteVisitUpdate = () => {
      const { LossSiteVisit } = props;
      if (LossSiteVisit != null) {
         var LossSiteVisitResponse = Object.assign({}, LossSiteVisit);
         $("#loss-site-visit-request").prop("disabled", false);
         if (LossSiteVisitResponse.result === true) {
            $("#loss-site-visit-loader").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Loss Site Visit Updated Successfully");
            props.history.push('/claims/list');
         } else {
            $("#loss-site-visit-loader").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            switch (LossSiteVisitResponse.status) {
               case ERROR:
                  Swal.fire({
                     title: 'Error',
                     text: LossSiteVisitResponse.message,
                     icon: 'error'
                  }).then(success => {

                  })
                  break;
               case UNAUTHORIZED:
                  props.auth.logout();
                  break;
               default:
            }
         }
      }
   }
   useEffect(onLossSiteVisitUpdate, [props.LossSiteVisit]);


   const onLossSiteVisitNameOfInsurerUpdate = () => {
      const { LossSiteVisitNameOfInsurer } = props;
      if (LossSiteVisitNameOfInsurer != null) {
         var LossSiteVisitNameOfInsurerResponse = Object.assign({}, LossSiteVisitNameOfInsurer);
         if (LossSiteVisitNameOfInsurerResponse.result === true) {
            setLossSiteVisitNameOfInsurer(LossSiteVisitNameOfInsurerResponse.response);
         } else {
            switch (LossSiteVisitNameOfInsurerResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
                  break;
               default:
            }
         }
      }
   }
   useEffect(onLossSiteVisitNameOfInsurerUpdate, [props.LossSiteVisitNameOfInsurer]);

   useEffect(() => {
      if (addClaimView != null && addClaimView.policy.insurer_name != null) {
         if (lossSiteVisitNameOfInsurer != null && lossSiteVisitNameOfInsurer.length > 0) {
            setValue('insurer_name', addClaimView.policy.insurer_name);
         }
      }
   }, [lossSiteVisitNameOfInsurer])

   const onLossSiteVisitPolicyTypeUpdate = () => {
      const { LossSiteVisitPolicyType } = props;
      if (LossSiteVisitPolicyType != null) {
         var LossSiteVisitPolicyTypeResponse = Object.assign({}, LossSiteVisitPolicyType);
         if (LossSiteVisitPolicyTypeResponse.result === true) {
            setLossSiteVisitPolicyType(LossSiteVisitPolicyTypeResponse.response);
         } else {
            switch (LossSiteVisitPolicyTypeResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
                  break;
               default:
            }
         }
      }
   }
   useEffect(onLossSiteVisitPolicyTypeUpdate, [props.LossSiteVisitPolicyType]);

   useEffect(() => {
      if (addClaimView != null && addClaimView.policy.policy_type != null) {
         if (lossSiteVisitPolicyType != null && lossSiteVisitPolicyType.length > 0) {
            setValue('policy_type', addClaimView.policy.policy_type);
         }
      }
   }, [lossSiteVisitPolicyType])

   const onAddClaimViewUpdate = () => {
      const { AddClaimView } = props;
      if (AddClaimView != null) {
         var AddClaimViewResponse = Object.assign({}, AddClaimView);
         if (AddClaimViewResponse.result === true) {
            setAddClaimView(AddClaimViewResponse.response);
            setValue('insured_name', AddClaimViewResponse.response.insured_name);
            setValue('claim_handler_name', AddClaimViewResponse.response.claim_handler_name);
         } else {
            switch (AddClaimViewResponse.status) {
               case UNAUTHORIZED:
                  props.auth.logout();
                  break;
                  case ERROR:
                     toast.error(AddClaimViewResponse.message);
               default:
            }
         }
      }
   }
   useEffect(onAddClaimViewUpdate, [props.AddClaimView]);


   useEffect(() => {
      if (addClaimView != null) {
         props.actionLossSiteVisitPolicyType();
         props.actionLossSiteVisitNameOfInsurer();
      }
   }, [addClaimView])

   const onSubmit = (data) => {
      data.user_id = props.auth.getUser().user_id;
      data.pinc_claim_no = addClaimView.pinc_claim_no;
      data.employee_name = addClaimView.claim_handler_name;
      $("#loss-site-visit-loader").removeClass("hidden");
      $("#loss-site-visit-request").prop("disabled", true);
      props.actionLossSiteVisit(data);
   };

   return (
      <>
         <div >
            <div className="row d-flex  content-header">
               <div className="col-12 col-md-10 col-lg-10 content-header-left">
                  <ScreenTitle title="Register Loss Site Visit" />

               </div>
               <div className="col-12 col-md-2 col-lg-2 content-header-right">
                  <button
                     className="btn btn-primary btn-md px-1 font-large shadow round"
                     onClick={() => {
                        props.history.push(`/claims/register/loss-site-visit`);
                     }}
                  >
                     Download&nbsp;Data
                  </button>
               </div>
            </div>
            <div class="card">
               <div class="card-body">
                  <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                     <div class="form-body">
                        <div class="row">
                           <div class="col-12 col-md-12 col-lg-12">
                              <h4 class="d-flex align-items-center form-section mb-2 black">
                                 <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>&nbsp;Loss Site Visit Details
                              </h4>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Employee/Loss Site Visitor Name</label>
                                 <input name="full_name"
                                    id="full_name"
                                    className='form-control pl-1'
                                    type="text"
                                    disabled={true}
                                    placeholder="Enter Employee/Loss Site Visitor Name"
                                    value={addClaimView != null ? addClaimView.claim_handler_name : ''}
                                    readonly="readonly"
                                    {...register('full_name')} />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">PINC Claim Ref. No.&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="pinc_claim_ref_no"
                                    id="pinc_claim_ref_no"
                                    className='form-control pl-1'
                                    type="text"
                                    placeholder="Search PINC Claim Ref. No."
                                    readonly="readonly"
                                    value={(props.location.state != null && props.location.state.pinc_claim_ref_no != null) ? props.location.state.pinc_claim_ref_no : '-'}
                                    {...register('pinc_claim_ref_no', { required: 'PINC Claim Ref. No. is required' })}
                                 />
                                 {errors.pinc_claim_ref_no && <ErrorLabel message={errors.pinc_claim_ref_no?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Name of Insurer&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="insurer_name"
                                    id="insurer_name"
                                    className='form-control pl-1'
                                    type="text"
                                    placeholder="Enter Name of Insurer"
                                    readonly="readonly"
                                    {...register('insurer_name', { required: 'Name of Insurer is required' })}
                                 />
                                 {/* <Controller
                                    name="insurer_name"
                                    className='form-control pl-1'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={lossSiteVisitNameOfInsurer}
                                          fields={{ text: 'name', value: 'name' }}
                                          enabled={false}
                                          allowFiltering={false}
                                          placeholder="Select Name of Insurer"
                                          value={field.value}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.name)

                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Name of Insurer is required'
                                    }}
                                 /> */}
                                 {errors.insurer_name && <ErrorLabel message={errors.insurer_name?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Name of Insured&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="insured_name"
                                    id="insured_name"
                                    className='form-control pl-1'
                                    type="text"
                                    placeholder="Enter Name of Insured"
                                    readonly="readonly"
                                    {...register('insured_name', { required: 'Name of Insured is required' })}
                                 />
                                 {errors.insured_name && <ErrorLabel message={errors.insured_name?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Policy Type&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="policy_type"
                                    id="policy_type"
                                    className='form-control pl-1'
                                    type="text"
                                    placeholder="Select Policy Type"
                                    readonly="readonly"
                                    {...register('policy_type', { required: 'Policy Type is required' })}
                                 />
                                 {/* <Controller
                                    name="policy_type"
                                    className='form-control pl-1'
                                    control={control}
                                    render={({ field }) =>
                                       <DropDownListComponent
                                          {...field}
                                          dataSource={lossSiteVisitPolicyType}
                                          enabled={false}
                                          allowFiltering={true}
                                          placeholder="Select Policy Type"
                                          value={field.value}
                                          fields={{ text: 'name', value: 'name' }}
                                          change={(event) => {
                                             if (event.itemData != null) {
                                                field.onChange(event.itemData.name)

                                             }
                                          }}
                                       />
                                    }
                                    rules={{
                                       required: 'Policy Type is required'
                                    }}
                                 /> */}
                                 {errors.policy_type && <ErrorLabel message={errors.policy_type?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Loss Location&nbsp;<span className="danger">*</span></label>
                                 <input name="loss_location"
                                    id="loss_location"
                                    className='form-control'
                                    type="text"
                                    placeholder="Enter Loss Location"
                                    {...register('loss_location', { required: 'Loss Location is required' })}
                                 />
                                 {errors.loss_location && <ErrorLabel message={errors.loss_location?.message} />}
                              </div>
                           </div>

                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Estimated Loss Amount&nbsp;<span className="danger">*</span></label>
                                 <input name="estimated_loss_amount"
                                    id="estimated_loss_amount"
                                    className='form-control'
                                    type="number"
                                    placeholder="Enter Estimated Loss Amount"
                                    {...register('estimated_loss_amount', { required: 'Estimated Loss Amount is required' })}
                                 />
                                 {errors.estimated_loss_amount && <ErrorLabel message={errors.estimated_loss_amount?.message} />}
                              </div>
                           </div>
                           {/* <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Name of Concerned Person at Loss Location&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="concerned_person_name"
                                    id="concerned_person_name"
                                    className='form-control pl-1'
                                    type="text"
                                    placeholder="Enter Name of Concerned Person at Loss Location"
                                    {...register('concerned_person_name', { required: 'Name of Concerned Person at Loss Location is required' })}
                                 />
                                 {errors.concerned_person_name && <ErrorLabel message={errors.concerned_person_name?.message} />}
                              </div>
                           </div> */}
                           {/* <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Contact Detail of Concerned Person&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="concerned_person_contact"
                                    id="concerned_person_contact"
                                    className='form-control pl-1'
                                    type="text"
                                    placeholder="Enter Contact Detail of Concerned Person"
                                    {...register('concerned_person_contact', { required: 'Contact Detail of Concerned Person is required' })}
                                 />
                                 {errors.concerned_person_contact && <ErrorLabel message={errors.concerned_person_contact?.message} />}
                              </div>
                           </div> */}
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Date of Loss</label>
                                 <Controller
                                    name="date_of_loss"
                                    className='form-control'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DateBox
                                          defaultValue={new Date()}
                                          calendarOptions={{
                                             showTodayButton: true,
                                          }}
                                          stylingMode="underlined"
                                          className="form-control"
                                          pickerType="calendar"
                                          type="date"
                                          acceptCustomValue={false}
                                          displayFormat="dd/MM/yyyy"
                                          placeholder='DD/MM/YYYY'
                                          value={field.value}
                                          onValueChange={(value) => {
                                             field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                          }}
                                       />
                                    }
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Cause of loss</label>
                                 <input name="cause_of_loss"
                                    id="cause_of_loss"
                                    className='form-control'
                                    type="text"
                                    placeholder="Enter Cause of loss"
                                    {...register('cause_of_loss')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Date of Loss Site Visit</label>
                                 <Controller
                                    name="date_of_loss_site_visit"
                                    className='form-control'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) =>
                                       <DateBox
                                          defaultValue={new Date()}
                                          calendarOptions={{
                                             showTodayButton: true,
                                          }}
                                          stylingMode="underlined"
                                          className="form-control"
                                          pickerType="calendar"
                                          type="date"
                                          displayFormat="dd/MM/yyyy"
                                          value={field.value}
                                          placeholder='DD/MM/YYYY'
                                          acceptCustomValue={false}
                                          onValueChange={(value) => {
                                             field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                          }}
                                       />
                                    }
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Location Where Survey is Done</label>
                                 <textarea
                                    name="survey_location"
                                    id="survey_location"
                                    className='form-control'
                                    type="text"
                                    placeholder="Enter Location Where Survey is Done"
                                    {...register('survey_location')} />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Surveyor Name & No./Workshop Details</label>
                                 <textarea
                                    name="surveyor_contact_details"
                                    id="surveyor_contact_details"
                                    className='form-control'
                                    type="text"
                                    placeholder="Enter Surveyor Name & No./Workshop Details"
                                    {...register('surveyor_contact_details')} />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Loss Description&nbsp;<span className="danger">*</span></label>
                                 <textarea name="loss_description"
                                    id="loss_description"
                                    className='form-control'
                                    type="text"
                                    placeholder="Enter Loss Description"
                                    {...register('loss_description', { required: 'Loss Description is required' })}
                                 />
                                 {errors.loss_description && <ErrorLabel message={errors.loss_description?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Loss Site Contact Person Name&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="loss_site_contact_person_name"
                                    id="loss_site_contact_person_name"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Loss Site Contact Person Name"
                                    {...register('loss_site_contact_person_name', { required: 'Loss Site Contact Person Name is required' })}
                                 />
                                 {errors.loss_site_contact_person_name && <ErrorLabel message={errors.loss_site_contact_person_name?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Loss Site Contact Person Mobile Number&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="loss_site_contact_person_mobile"
                                    id="loss_site_contact_person_mobile"
                                    className="form-control"
                                    maxLength={10}
                                    placeholder="Enter Loss Site Contact Person Mobile Number"
                                    onKeyPress={(event) => {
                                       if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();

                                       }
                                    }}
                                    {...register('loss_site_contact_person_mobile',
                                       {
                                          required: 'Loss Site Contact Person Mobile Number is required',
                                          pattern: {
                                             value: /^[6789]\d{9}$/,
                                             message: 'Please enter 10 digit valid Mobile Number'
                                          }
                                       })}
                                 />
                                 {errors.loss_site_contact_person_mobile && <ErrorLabel message={errors.loss_site_contact_person_mobile?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Loss Site Contact Person Email ID&nbsp;<span className="danger">*</span></label>
                                 <input
                                    name="loss_site_contact_person_email"
                                    id="loss_site_contact_person_email"
                                    className="form-control"
                                    placeholder="Enter Loss Site Contact Person Email ID"
                                    {...register('loss_site_contact_person_email', {
                                       required: 'Loss Site Contact Person Email ID is required',
                                       pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                          message: 'Please enter valid Email Id'
                                       },
                                    })}
                                 />
                                 {errors.loss_site_contact_person_email && <ErrorLabel message={errors.loss_site_contact_person_email?.message} />}
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Alternate Contact Person Name</label>
                                 <input
                                    name="alternate_contact_person_name"
                                    id="alternate_contact_person_name"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Alternate Contact Person Name"
                                    {...register('alternate_contact_person_name')}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Alternate Contact Person Mobile Number</label>
                                 <input
                                    name="alternate_contact_person_mobile"
                                    id="alternate_contact_person_mobile"
                                    className="form-control"
                                    maxLength={10}
                                    placeholder="Enter Alternate Contact Person Mobile Number"
                                    onKeyPress={(event) => {
                                       if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                       }
                                    }}
                                    {...register('alternate_contact_person_mobile', {
                                       pattern: {
                                          value: /^[6789]\d{9}$/,
                                          message: 'Please enter 10 digit valid Mobile Number'
                                       }
                                    }
                                    )}
                                 />
                              </div>
                           </div>
                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Alternate Contact Person Email ID</label>
                                 <input
                                    name="alternate_contact_person_email"
                                    id="alternate_contact_person_email"
                                    className="form-control"
                                    placeholder="Enter Alternate Contact Person Email ID"
                                    {...register('alternate_contact_person_email', {
                                       pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                          message: 'Please enter valid Email Id'
                                       },
                                    })
                                    }
                                 />
                              </div>
                           </div>


                           <div class="col-12 col-md-4 col-lg-4">
                              <div class="form-group">
                                 <label class="label-control">Remarks</label>
                                 <textarea name="remarks"
                                    id="remarks"
                                    className='form-control'
                                    type="text"
                                    placeholder="Enter Remarks"
                                    {...register('remarks')} />
                              </div>
                           </div>
                        </div>
                     </div>
                     <button class="btn btn-outline-secondary mr-1 shadow round text-uppercase" type='button'
                        onClick={() => { props.history.goBack() }}> Back
                     </button>
                     <button id="loss-site-visit-request" class="btn btn-primary mr-1 shadow round text-uppercase" type="submit">
                        <div class="d-flex align-items-center">Submit
                           <span id="loss-site-visit-loader" class="spinner-border spinner-border-sm ml-half hidden"></span>
                        </div>
                     </button>
                  </form>
               </div>
            </div>
         </div>
      </>
   )
}


const mapStateToProps = (state) => {
   var LossSiteVisit = null;
   var LossSiteVisitNameOfInsurer = null;
   var LossSiteVisitPolicyType = null;
   var AddClaimView = null;

   if (
      state.AddLossSiteVisitReducer != null &&
      Object.keys(state.AddLossSiteVisitReducer).length > 0 &&
      state.AddLossSiteVisitReducer.constructor === Object
   ) {
      switch (state.AddLossSiteVisitReducer.tag) {
         case ACTION_ADD_LOSS_SITE_VISIT_SUCCESS:
            LossSiteVisit = Object.assign({}, state.AddLossSiteVisitReducer.data);
            delete state.AddLossSiteVisitReducer;
            return { LossSiteVisit };
         case ACTION_ADD_LOSS_SITE_VISIT_NAME_OF_INSURER_SUCCESS:
            LossSiteVisitNameOfInsurer = Object.assign({}, state.AddLossSiteVisitReducer.data);
            delete state.AddLossSiteVisitReducer;
            return { LossSiteVisitNameOfInsurer };
         case ACTION_ADD_LOSS_SITE_VISIT_POLICY_TYPE_SUCCESS:
            LossSiteVisitPolicyType = Object.assign({}, state.AddLossSiteVisitReducer.data);
            delete state.AddLossSiteVisitReducer;
            return { LossSiteVisitPolicyType };
         case ACTION_ADD_CLAIMS_VIEW_SUCCESS:
            AddClaimView = Object.assign({}, state.AddLossSiteVisitReducer.data);
            delete state.AddLossSiteVisitReducer;
            return { AddClaimView };
         default:
      }
   }
   return {
      LossSiteVisit,
      LossSiteVisitNameOfInsurer,
      LossSiteVisitPolicyType,
      AddClaimView,
   }
}

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators({ actionLossSiteVisit, actionLossSiteVisitNameOfInsurer, actionLossSiteVisitPolicyType, actionAddClaimsView }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(AddLossSiteVisit))