import React, { useEffect, useState } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from "react-icons/fi";
import $ from "jquery";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withAuth } from "react-auth-guard";
import { UNAUTHORIZED } from "../../../../../../constants";
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { toast } from "react-toastify";
import moment from "moment";
import { ACTION_ADD_RISK_LIST_SUCCESS, actionAddRiskList } from "./action";
import { verifyPermission } from "../../../../../../utils";

const RiskManagementList = (props) => {
    const { match } = props;
    const [riskManagement, setRiskManagement] = useState([])

    const initialRender = () => {

        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionAddRiskList({ "user_type": "self" })
    }
    useEffect(initialRender, []);

    const onAddRiskListUpdate = () => {
        const { AddRiskList } = props;
        if (AddRiskList != null) {
            setRiskManagement([]);
            var AddRiskListResponse = Object.assign({}, AddRiskList);
            if (AddRiskListResponse.result === true) {
                setRiskManagement(AddRiskListResponse.response);
                $("#progress-loader").addClass("hidden");
            } else {
                $("#progress-loader").addClass("hidden");
                switch (AddRiskListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onAddRiskListUpdate, [props.AddRiskList])


    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "id", caption: "ID", visible: true },
        { dataField: "created_date", caption: "Created Date", visible: false },
        { dataField: "created_date_formated", caption: "Created Date", visible: true },
        { dataField: "client_name", caption: "Client Name", visible: true },
        { dataField: "address", caption: "Location Address", visible: true },
        { dataField: "occupancy", caption: "Occupancy", visible: true },
        { dataField: "sum_insured", caption: "Sum Insured", visible: true },
        { dataField: "rm_name", caption: "RM Name", visible: true },
        { dataField: "rm_city_name", caption: "RM Location", visible: true },
        { dataField: "inspection_type", caption: "Type of Inspection", visible: true },
        { dataField: "description", caption: "Description", visible: true },
        { dataField: "inspection_date", caption: "Date of Inspection", visible: false },
        { dataField: "inspection_date_formated", caption: "Date of Inspection", visible: true },
        { dataField: "approved_by", caption: "Approved By", visible: true },
        { dataField: "actual_date_inspection", caption: "Actual Date of Inspection", visible: false },
        { dataField: "actual_date_inspection_formated", caption: "Actual Date of Inspection", visible: true },
        { dataField: "assign_engineer", caption: "Assigned Engineer", visible: true },
        { dataField: "remarks", caption: "Remark", visible: true },
        { dataField: "raised_by", caption: "Created By", visible: true },
        { dataField: "financial_year", caption: "Financial Year", visible: true },
        { dataField: "status_name", caption: "Status", visible: true },
        { dataField: "engineer_status_name", caption: "Engineer Status", visible: true },
    ]);

    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-right col-12 col-md-12 col-lg-12">
                    <ul class="nav nav-pills float-right">
                        <li class="nav-item">
                            <button
                                className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                onClick={() => {
                                    props.history.push('/risk_management/new_request/add');
                                }}
                            >
                                Raise New Request
                            </button>
                        </li>
                    </ul>
                </div>
            </>
        )
    }

    const renderApprovedBy = (cell) => {
        return (
            <div>
                <p>
                    {cell.data.status != 'pending' ? cell.data.approved_by : ''}
                </p>
            </div>
        )
    }

    const renderEngineerStatus = (cell) => {
        return <div>{cell.data.engineer_status_name != null && cell.data.engineer_status_name != "Not Defined" ? cell.data.engineer_status_name : ''}</div>;
    }

    const renderCreateDate = (cell) => {
        return <div>{cell?.data?.created_date_formated != null ? moment(cell?.data?.created_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderInspectionDate = (cell) => {
        return <div>{cell?.data?.inspection_date_formated != null ? moment(cell?.data?.inspection_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderActualInspectionDate = (cell) => {
        return <div>{cell?.data?.actual_date_inspection_formated != null ? moment(cell?.data?.actual_date_inspection_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="action" type="buttons">
                        {
                            verifyPermission("RISK_MANAGEMNT_REQUEST_VIEW") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm float-left"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/view`, state: { id: params.data.id } })
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            verifyPermission("RISK_MANAGEMNT_REQUEST_EDIT") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className={`btn btn-icon btn-sm ${['pending', 'update'].includes(params.data.status) ? '' : 'hidden'} `}
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/edit`, state: { id: params.data.id } })
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                    </Column>
                );
            } else {
                if (objColumn.dataField === "approved_by") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderApprovedBy}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else if (objColumn.dataField === "engineer_status_name") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderEngineerStatus}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else if (objColumn.dataField === "created_date_formated") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderCreateDate}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else if (objColumn.dataField === "inspection_date_formated") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderInspectionDate}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else if (objColumn.dataField === "actual_date_inspection_formated") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderActualInspectionDate}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }
            }
        });
        return arrColumns;
    };

    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Risk Management Request {riskManagement?.length === 0 ? '' : `(${riskManagement?.length})`}
                        </h5>
                    </div>
                    {
                        verifyPermission("RISK_MANAGEMNT_REQUEST_ADD") ?
                            renderHeaderButtonUI()
                            :
                            <></>
                    }
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={riskManagement}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    columnHidingEnabled={false}
                                    export={{
                                        fileName: "Risk Management",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                    onExporting={(event) => {
                                        event.component.beginUpdate();
                                        event.component.columnOption("created_date_formated", "visible", false)
                                        event.component.columnOption("created_date", "visible", true)
                                        event.component.columnOption("inspection_date_formated", "visible", false)
                                        event.component.columnOption("inspection_date", "visible", true)
                                        event.component.columnOption("actual_date_inspection_formated", "visible", false)
                                        event.component.columnOption("actual_date_inspection", "visible", true)
                                    }}
                                    onExported={(event) => {
                                        event.component.columnOption("created_date_formated", "visible", true)
                                        event.component.columnOption("created_date", "visible", false)
                                        event.component.columnOption("inspection_date_formated", "visible", true)
                                        event.component.columnOption("inspection_date", "visible", false)
                                        event.component.columnOption("actual_date_inspection_formated", "visible", true)
                                        event.component.columnOption("actual_date_inspection", "visible", false)
                                        event.component.endUpdate();
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {

    var AddRiskList = null;

    if (
        state.AddRiskListReducer != null &&
        Object.keys(state.AddRiskListReducer).length > 0 &&
        state.AddRiskListReducer.constructor === Object
    ) {
        switch (state.AddRiskListReducer.tag) {
            case ACTION_ADD_RISK_LIST_SUCCESS:
                AddRiskList = Object.assign({}, state.AddRiskListReducer.data);
                delete state.AddRiskListReducer;
                return { AddRiskList };
            default:
        }
    }
    return {
        AddRiskList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionAddRiskList
        },
        dispatch
    );
};
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RiskManagementList))